<div mat-dialog-title>
  <h3>{{ 'template.editor.testData.edit' | translate }}</h3>
  <div class="px-4 py-2 flex flex-grow flex-row items-center justify-between">
    <ws-form-toggle
      [formControl]="advancedViewFormControl"
      class="absolute top-[1.5em] right-[1.5em] text-darkgrey"
      label="{{ 'template.editor.advancedView' | translate }}"
    ></ws-form-toggle>
  </div>
</div>

<mat-dialog-content class="ws-email-test-data-dialog__content-wrapper">
  @if (advancedViewFormControl.value) {
    <div>
      <ws-form-textarea [formControl]="testDataAdvancedFormControl" class="w-full px-6" [rows]="20"></ws-form-textarea>
    </div>
  } @else {
    <div class="ws-email-test-data-dialog__title-wrapper grid grid-cols-2 items-center px-6">
      <span
        ><h4>{{ 'template.editor.testData.key' | translate }}</h4></span
      ><span
        ><h4>{{ 'template.editor.testData.value' | translate }}</h4></span
      >
    </div>
    <div class="px-6">
      <div class="py-4">
        <form [formGroup]="testDataFormGroup" class="divide-y">
          @for (entry of placeholders; track entry.identifier) {
            <div class="ws-email-test-data-dialog__variable-wrapper grid grid-cols-1 xl:grid-cols-2 xl:gap-4">
              @if (entry.isCustom) {
                <ws-form-input [formControlName]="entry.identifier + '__key'" />
              } @else {
                <div>
                  <mat-label class="text-primary">{{ entry.label | localized }}</mat-label>
                  <div class="text-sm">
                    {{ '\$\{' + entry.identifier + '\}' }}
                  </div>
                </div>
              }
              <div class="grid grid-cols-[1fr__auto]">
                <ws-form-input [formControlName]="entry.identifier" />
                <ws-button
                  class="flex items-center text-accent"
                  [flat]="true"
                  buttonClass="h-full"
                  (click)="deletePlaceholderVariable(entry.identifier)"
                >
                  <ws-icon class="delete-icon">delete</ws-icon>
                </ws-button>
              </div>
            </div>
          }
        </form>

        <div class="mt-5 flex items-center justify-between">
          <span>{{ 'template.editor.testData.addVariable' | translate }}</span>
          <ws-button (click)="openAddVariableDialog()" class="add-new-button" [flat]="true">
            <ws-icon class="add-new-icon">add</ws-icon>
            <span class="hidden xl:block">{{ 'addNew' | translate }}</span>
          </ws-button>
        </div>
      </div>
    </div>
  }
</mat-dialog-content>

<div class="flex flex-wrap justify-end">
  <mat-dialog-actions>
    <div class="px-4 py-3">
      <ws-button (click)="close()" [flat]="true">
        {{ 'template.close' | translate }}
      </ws-button>

      <ws-button (click)="save()" class="bg-accent text-white" [flat]="true">
        {{ 'save' | translate }}
      </ws-button>
    </div>
  </mat-dialog-actions>
</div>
