import {
  Component,
  Input,
  OnInit,
  Inject,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  inject
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { FieldComponent } from '../field/field.component'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { WS_DATE_FORMATS, WsDateAdapter } from '../../../adapters/date-adapter'
import { WsDate } from '../../../types/calendar/ws-date'
import { MatDatepicker } from '@angular/material/datepicker'
import { DatePipe } from '@angular/common'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'

@Component({
  selector: 'ws-form-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss', '../field/field.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: WsDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: WS_DATE_FORMATS
    },
    DatePipe
  ]
})
export class DatepickerComponent extends FieldComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() icon = 'calendar_month'
  @Input() native = this.platformService.isMobile
  @Input() hideHint = false
  @Input() openPickerOnFocus = true
  @Input() placeholder = ''
  @Input() min: Date | null = null
  @Input() max: Date | null = null
  @Input() locale?: string
  @Output() public valueChange = new EventEmitter<string>()

  // we use british english for the format of the date when english is selected as language (not american english format with leading month)
  get languageWithRegion() {
    let language = navigator.language
    if (this.translate.currentLang) {
      language = this.translate.currentLang === 'en' ? 'en-GB' : this.translate.currentLang
    }
    return language
  }

  get today() {
    const dateToday = new Date()
    return dateToday.toLocaleDateString(this.languageWithRegion)
  }
  date = new WsDate(new Date(), true)

  private datePipe: DatePipe = inject(DatePipe)
  constructor(
    public override ngControl: NgControl,
    public _adapter: DateAdapter<WsDateAdapter>,
    @Inject(MAT_DATE_LOCALE) public _locale: string,
    @Inject('PlatformService') public override platformService: AbstractPlatformService
  ) {
    super(ngControl, platformService)
  }

  ngOnInit() {
    this.setLocalization()
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit()
    if (this.native && this.ngControl && this.formControl.value) {
      this.formControl.setValue(this.date.format('iso', false))
    }
  }

  setLocalization() {
    if (this.locale) {
      this._locale = this.locale
      this._adapter.setLocale(this._locale)
      return
    }
    if (this.translate.currentLang) {
      this._locale = this.languageWithRegion
      this._adapter.setLocale(this._locale)
    }
  }

  public override value: string = ''

  public override onChange(newValue: string) {}

  public onDateChange(event: Date) {
    this.date = new WsDate(event, false)
    this.value = event ? this.date.format('iso', false) : ''
    this.emitDateChange()
  }

  /**
   * Function to always emit date change in correct format
   */
  emitDateChange() {
    if (this.formControl.invalid || !this.value) {
      this.onFieldChange('')
      return
    }

    const dateToEmit = this.date.format('iso', false)
    this.onFieldChange(dateToEmit)
  }

  public override onFieldChange(input: string) {
    super.onFieldChange(input)
    this.valueChange.emit(input)
  }

  public override registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn
  }

  @ViewChild('dateInput', { read: ElementRef }) dateInputElement?: ElementRef<HTMLInputElement>
  @ViewChild('picker') datePicker?: MatDatepicker<Date>

  /**
   * Returns the date format depending on the current language
   */
  dateFormatByLocale(locale: string) {
    switch (locale) {
      case 'de':
        return 'd.M.Y'
      case 'en':
        return 'M/d/Y'
      default:
        // returns undefined to use the default date format of the datePipe
        return undefined
    }
  }

  public override writeValue(value: string | Date): void {
    if (typeof value === 'string') {
      // adds time to date string if it is missing to ensure correct timezone conversion
      if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        value += 'T00:00:00.000'
      }

      if (!value) {
        this.value = ''
        return
      }

      value = new Date(value)

      const valueIsValidDate = !value ? true : !isNaN(value.getTime())

      setTimeout(() => {
        if (this.datePicker && typeof value !== 'string' && this.dateInputElement && valueIsValidDate) {
          // updates the selected value displayed in the datepicker itself
          this.datePicker.select(value)
          // updates the value displayed in the input field depending on the currently selected language
          const formattedDate =
            this.datePipe.transform(value, this.dateFormatByLocale(this.translate.currentLang)) || ''

          this.dateInputElement.nativeElement.value = formattedDate
        }
      })
    }

    const valueIsValidDate = !value ? true : !isNaN(value.getTime())

    if (valueIsValidDate) {
      this.date = new WsDate(value)
    }

    this.value = value ? this.date.format('iso', false) : ''
  }

  handleInputFocus() {
    if (this.openPickerOnFocus && !this.native) {
      this.datePicker?.open()
    }
  }

  protected override getValidationErrorMessage(): string {
    if (!this.formControl.errors) {
      return ''
    }
    let translationKey = 'ws.forms.validation'
    let translationData: { [key: string]: string } = {
      label: this.label,
      matDatepickerMin: this.min?.toLocaleDateString() || '',
      matDatepickerMax: this.max?.toLocaleDateString() || ''
    }

    for (const error in this.formControl.errors) {
      translationKey += `.${error}`
      translationData = { ...translationData, ...this.formControl.errors[error] }
    }

    if (translationKey.includes('matDatepickerParse') && !translationData['text']) {
      this.formControl.reset()
      return ''
    }

    if (translationKey.includes('matDatepickerParse')) {
      translationKey = 'ws.forms.validation.matDatepickerParse'
    }

    return this.translate.instant(translationKey, translationData)
  }
}
