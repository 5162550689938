import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, Optional, Output, Self } from '@angular/core'
import { FieldComponent } from '../field/field.component'
import { WsTime } from '../../../types/calendar/ws-time'
import { ControlValueAccessor, FormArray, FormControl, NgControl, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'

@Component({
  selector: 'ws-form-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrl: './timepicker.component.scss'
})
export class TimepickerComponent extends FieldComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() public icon = 'schedule'
  @Output() public iconClick = new EventEmitter<string>()

  public override value: string = ''

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: string) {}

  public override onFieldChange(input: string) {
    this.value = input
    super.onFieldChange(input)
    this.valueChange.emit(input)
  }

  public override registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: string | Date): void {
    if (typeof value !== 'string' && value instanceof Date) {
      value = new WsTime(value).format().slice(0, 5)
      if (this.native) {
        setTimeout(() => {
          if (typeof value === 'string') this.value = value
        })
      }
    }

    this.value = value
    if (value) {
      this.time = new WsTime(value)
      this.setTimeToSelectedValues()
    }
  }

  @Input() showSeconds = false
  @Input() showMilliseconds = false
  @Input() native = true

  time = new WsTime('00:00')
  @Output() public valueChange = new EventEmitter<string>()

  options = {
    hours: this.createSelectOptions(24),
    minutes: this.createSelectOptions(60),
    seconds: this.createSelectOptions(60),
    milliseconds: this.createSelectOptions(1000)
  }

  selectedHoursFormControl = new FormControl('')
  selectedMinutesFormControl = new FormControl('')
  selectedSecondsFormControl = new FormControl('')
  selectedMillisecondsFormControl = new FormControl('')

  selectedFormArray = new FormArray([
    this.selectedHoursFormControl,
    this.selectedMinutesFormControl,
    this.selectedSecondsFormControl,
    this.selectedMillisecondsFormControl
  ])

  constructor(
    @Self()
    @Optional()
    public override ngControl: NgControl,
    @Inject('PlatformService') public override platformService: AbstractPlatformService
  ) {
    super(ngControl, platformService)

    if (ngControl) {
      this.formControlValueChangesSubscription = ngControl.control?.valueChanges.subscribe((value: any) => {
        this.checkFormControlValidators()
      })
    }
  }

  checkFormControlValidators() {
    if (this.formControl.hasValidator(Validators.required)) {
      this.selectedHoursFormControl.setValidators([Validators.required])
      this.selectedMinutesFormControl.setValidators([Validators.required])
      this.selectedSecondsFormControl.setValidators([Validators.required])
      this.selectedMillisecondsFormControl.setValidators([Validators.required])
      this.selectedHoursFormControl.updateValueAndValidity()
      this.selectedMinutesFormControl.updateValueAndValidity()
      this.selectedSecondsFormControl.updateValueAndValidity()
      this.selectedMillisecondsFormControl.updateValueAndValidity()
    } else {
      this.selectedHoursFormControl.clearValidators()
      this.selectedHoursFormControl.setErrors(null)
      this.selectedMinutesFormControl.clearValidators()
      this.selectedMinutesFormControl.setErrors(null)
      this.selectedSecondsFormControl.clearValidators()
      this.selectedSecondsFormControl.setErrors(null)
      this.selectedMillisecondsFormControl.clearValidators()
      this.selectedMillisecondsFormControl.setErrors(null)
    }
    if (this.formControl.errors !== null && this.formControl.touched) {
      this.selectedFormArray.markAllAsTouched()
    }
  }

  handleIndividualFieldBlur() {
    this.formControl.markAsTouched()
    this.handleInputBlur()
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit()
    this.selectedFormArrayValueChangesSubscription = this.selectedFormArray.valueChanges.subscribe(
      (value: (string | null)[]) => {
        this.setSelectedValuesToTime()
        if (value.some((formControlValue) => formControlValue !== null && formControlValue !== '')) {
          this.emitTimeChange()
        }
      }
    )

    if (this.native && this.value) {
      this.formControl.setValue(this.time.format())
    }

    this.checkFormControlValidators()
  }

  formControlValueChangesSubscription?: Subscription
  selectedFormArrayValueChangesSubscription?: Subscription

  override ngOnDestroy() {
    super.ngOnDestroy()
    this.formControlValueChangesSubscription?.unsubscribe()
    this.selectedFormArrayValueChangesSubscription?.unsubscribe()
  }

  /*
   * Function to set time from selected values when using multiple selects in timepicker
   */
  setSelectedValuesToTime() {
    let newTimeString = `${this.selectedHoursFormControl.value || '00'}:${
      this.selectedMinutesFormControl.value || '00'
    }`

    // updates selected minutes to 00 if hours are selected but minutes are not
    if (this.selectedHoursFormControl.value && !this.selectedMinutesFormControl.value) {
      this.selectedMinutesFormControl.setValue('00')
    }
    // updates selected hours to 00 if minutes are selected but hours are not
    if (!this.selectedHoursFormControl.value && this.selectedMinutesFormControl.value) {
      this.selectedHoursFormControl.setValue('00')
    }

    if (this.selectedSecondsFormControl.value) {
      newTimeString += `:${this.selectedSecondsFormControl.value}`
      if (!this.selectedMinutesFormControl.value) this.selectedMinutesFormControl.setValue('00')
      if (!this.selectedHoursFormControl.value) this.selectedHoursFormControl.setValue('00')
    } else if (this.selectedMillisecondsFormControl.value) {
      newTimeString += ':00'
      this.selectedSecondsFormControl.setValue('00')
    }

    if (this.selectedMillisecondsFormControl.value) {
      newTimeString += `.${this.selectedMillisecondsFormControl.value}`
      if (!this.selectedSecondsFormControl.value) this.selectedSecondsFormControl.setValue('00')
      if (!this.selectedMinutesFormControl.value) this.selectedMinutesFormControl.setValue('00')
      if (!this.selectedHoursFormControl.value) this.selectedHoursFormControl.setValue('00')
    }

    this.time = new WsTime(newTimeString)
  }

  /*
   * Function to set selected values from time when using multiple selects in timepicker
   */
  private setTimeToSelectedValues() {
    const newValuesFormArray = [
      this.time.hours().toString().padStart(2, '0'),
      this.time.minutes().toString().padStart(2, '0')
    ]

    newValuesFormArray.push(this.time.seconds() ? this.time.seconds().toString().padStart(2, '0') : '')
    newValuesFormArray.push(this.time.milliseconds() ? this.time.milliseconds().toString().padStart(3, '0') : '')

    // this has to be set at once to avoid multiple valueChanges events and therefore overwriting of values
    this.selectedFormArray.setValue(newValuesFormArray)
  }

  /*
   * Function to always emit time change in correct format
   */
  emitTimeChange() {
    this.onFieldChange(this.time.format())
  }

  /*
   * Function to clear selected time and emit empty time string
   */
  clearSelectedTime() {
    this.selectedFormArray.setValue(['', '', '', ''])
    this.clear.emit()

    this.onFieldChange('')
  }

  /*
   * Helper function to create select options for different time values
   */
  createSelectOptions(optionsAmount: number) {
    const padMax = optionsAmount > 100 ? 3 : 2

    const numbersArray = Array(optionsAmount).keys()
    const stringsArray = Array.from(numbersArray).map((hour) => hour.toString().padStart(padMax, '0'))

    return stringsArray.map((optionString) => {
      return {
        value: optionString,
        label: optionString
      }
    })
  }

  /*
   * Native timepicker handling
   */
  handleTimeInputChange(event: Event) {
    this.time = new WsTime((event.target as HTMLInputElement).value)
    this.onFieldChange((event.target as HTMLInputElement).value === '' ? '' : this.time.format())
  }

  nativeTimeInputStep() {
    // milliseconds is not supported by native time input inside angular material input > therefore only seconds is enabled for now
    // const stepMilliseconds = 0.001
    const stepSeconds = 1
    // if (this.showMilliseconds) return stepMilliseconds
    if (this.showSeconds) return stepSeconds
    return 0
  }

  timeHasChanged(value: string) {
    this.time = new WsTime(value)
    this.onFieldChange(value)
    this.valueChange.emit(value === '' ? '' : this.time.format())
  }
}
