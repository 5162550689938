import { Border } from './border'

export class AdvancedBorders {
  top: Border = new Border('', '', '')
  right: Border = new Border('', '', '')
  bottom: Border = new Border('', '', '')
  left: Border = new Border('', '', '')

  constructor(top: string, right: string, bottom: string, left: string) {
    this.top = this.getAdvancedBorder(top)
    this.right = this.getAdvancedBorder(right)
    this.bottom = this.getAdvancedBorder(bottom)
    this.left = this.getAdvancedBorder(left)
  }

  private getAdvancedBorder(advancedBorderString: string) {
    const border = advancedBorderString.split(' ')

    if (border?.length === 3) {
      const width = border[0].replace('px', '')
      const style = border[1]
      const color = border[2]
      return new Border(width, color, style)
    }
    return new Border('', '', '')
  }

  static anyBorderHasAllValues(advancedBorders: AdvancedBorders): boolean {
    return (
      advancedBorders.top.hasAllValues() ||
      advancedBorders.right.hasAllValues() ||
      advancedBorders.bottom.hasAllValues() ||
      advancedBorders.left.hasAllValues()
    )
  }

  static getAnySideWithAllValues(advancedBorders: AdvancedBorders): string {
    if (advancedBorders.top.hasAllValues()) return 'top'
    if (advancedBorders.right.hasAllValues()) return 'right'
    if (advancedBorders.bottom.hasAllValues()) return 'bottom'
    if (advancedBorders.left.hasAllValues()) return 'left'
    return ''
  }

  static getFormValues(advancedBorders: AdvancedBorders) {
    const formValues: Record<string, string> = {}
    const directions = ['top', 'right', 'bottom', 'left']
    directions.forEach((direction) => {
      formValues[`border-width-${direction}`] = advancedBorders[direction as keyof AdvancedBorders].width
      formValues[`border-color-${direction}`] = advancedBorders[direction as keyof AdvancedBorders].color
      formValues[`border-style-${direction}`] = advancedBorders[direction as keyof AdvancedBorders].style
    })

    return formValues
  }
}
