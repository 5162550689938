@if (ngControl) {
  <div class="mat-mdc-form-field w-full">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <mat-slider discrete [min]="min" [max]="max" [step]="step">
      <input matSliderThumb [formControl]="formControl" (valueChange)="onFieldChange($event)" [value]="value" />
    </mat-slider>
    @if (hasValidators) {
      <div class="mat-form-field-subscript-wrapper">
        @if (hasErrors) {
          <mat-error>
            {{ validationErrorMessage }}
          </mat-error>
        }
      </div>
    }
  </div>
}
