import { BrickComposite } from './brickComposite'
import defaultStyles from './sectionStyles.json'
import { StyleAttributes, TestData } from './brick.types'

export class Section extends BrickComposite {
  constructor(styles: StyleAttributes = {}, name = '') {
    super('Section', '', styles, 'mj-section', name)
    this.addDefaultStyles(defaultStyles)
  }

  override getMjml(withoutHiddenBricks = false): string {
    if (this.isHidden() && withoutHiddenBricks) return ''

    const children = withoutHiddenBricks ? this.getVisibleChildren() : this.children

    const styles = Object.keys(this.styles)
      .map((style) => ` ${style}="${this.styles[style]}"`)
      .join('')

    let childrenMjml = children.map((child) => child.getMjml()).join('\n')
    if (this.styles['grouped-columns']) {
      //add group tag to show in one row when mobile
      childrenMjml = `<mj-group>\n${childrenMjml}\n</mj-group>`
    }

    return `<${this.mjmlTag}${styles}>\n${childrenMjml}\n</${this.mjmlTag}>`
  }

  override getStyles(testData: TestData = {}): any {
    const fullWidth = this.isFullWidth()

    const background =
      this.styles['background-url'] !== undefined
        ? {
            background: this.getBackground(testData),
            'background-repeat': this.styles['background-repeat'],
            'background-size': this.styles['background-size']
          }
        : {
            background: this.replaceTestData(this.styles['background-color']?.toString() || '', testData),
            'background-color': this.replaceTestData(this.styles['background-color']?.toString() || '', testData)
          }

    return {
      tableFullWidth: {
        ...(fullWidth ? background : {}),
        width: '100%',
        'border-radius': this.styles['border-radius']
      },
      table: {
        ...(fullWidth ? {} : background),
        width: '100%',
        'border-radius': this.styles['border-radius']
      },
      td: {
        border: this.styles['border'],
        'border-bottom': this.styles['border-bottom'],
        'border-left': this.styles['border-left'],
        'border-right': this.styles['border-right'],
        'border-top': this.styles['border-top'],
        direction: this.styles['direction'],
        'font-size': '0',
        padding: this.getPadding(),
        'text-align': this.styles['text-align']
      },
      div: {
        ...(fullWidth ? {} : background),
        margin: '0px auto',
        'border-radius': this.styles['border-radius']
        // 'max-width': containerWidth, --> handled in html
      },
      innerDiv: {
        'line-height': '0',
        'font-size': '0'
      },
      cssClass: {
        'css-class': this.styles['css-class']
      }
    }
  }
}
