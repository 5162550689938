@if (showSidebar) {
  <div class="ws-sidebar__backdrop" @fade (click)="handleBackdropClick()"></div>
}

<div
  class="ws-sidebar__wrapper p-4 flex flex-col"
  [class.ws-sidebar--hidden]="!showSidebar"
  [class.ws-sidebar--as-bottom-bar]="displayAsBottomBar"
>
  @if (sidebarTitle) {
    <div class="sidebar_title m-4 md:m-8 text-center">
      <h4>{{ sidebarTitle | translate }}</h4>
    </div>
  }
  @if (templateRefSidebarContent) {
    <div class="ws-sidebar__content overflow-auto colored-scrollbar flex-grow md:p-4">
      <ng-template [ngTemplateOutlet]="templateRefSidebarContent"></ng-template>
    </div>
  }
  <div class="ws-sidebar__footer flex justify-center md:p-4">
    @if (templateRefSidebarFooter) {
      <ng-template [ngTemplateOutlet]="templateRefSidebarFooter"></ng-template>
    } @else {
      <ws-button buttonClass="bg-darkgrey text-white" (click)="closeSidebar()">
        {{ 'ws.sidebar.close' | translate }}
      </ws-button>
    }
  </div>
</div>
