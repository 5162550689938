import { AllowedFileType } from './allowed-file-type'

export class AllowedFileTypes {
  allowedTypes: AllowedFileType[] = []
  acceptedFileTypesString = ''

  constructor(allowedTypes: AllowedFileType[], acceptedFileTypesString: string) {
    this.allowedTypes = allowedTypes
    this.acceptedFileTypesString = acceptedFileTypesString
  }
}
