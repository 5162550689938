<div class="block-list p-4 overflow-hidden">
  <div
    id="defaultSectionDroplist"
    [cdkDropListConnectedTo]="template.id"
    [cdkDropListData]="defaultBlockSectionsMapValues"
    cdkDropListSortingDisabled
    class="droplist-for-sections"
    cdkDropList
  >
    @for (block of defaultBlocksSectionMap | keyvalue; track block.key.wsEmailBlockId; let idx = $index) {
      <ng-template #sectionPreview>
        <iframe
          scrolling="no"
          [srcdoc]="block.value.htmlWithTestData | safeHtml: { removeHref: true }"
          appIframe
        ></iframe>
      </ng-template>
      <div
        cdkDrag
        [cdkDragData]="block.value"
        (cdkDragStarted)="dragAndDropService.onDragStarted()"
        (cdkDragDropped)="onDragDropped(block.key.type)"
        class="mb-2"
      >
        <div class="flex justify-between w-full items-end">
          <div class="block-element-header text-gray-700 text-sm py-2">{{ block.value.name }}</div>
          @if (block.key.customBlock) {
            <button (click)="deleteEmailBlock(block.key, block.value)"><ws-icon>close</ws-icon></button>
          }
        </div>
        <div *cdkDragPlaceholder></div>
        <div *cdkDragPreview>
          <div class="ws-email-block-preview">
            <iframe
              appIframe
              resizeWidth="600px"
              scrolling="no"
              [srcdoc]="block.value.htmlWithTestData | safeHtml: { removeHref: true }"
            ></iframe>
          </div>
        </div>
        <div
          class="ws-email-block bg-white border border-gray-300 shadow-md hover:border-blue-500 flex flex-col justify-center items-center"
        >
          <div class="ws-email-block-preview iframe-container">
            <ng-container *ngTemplateOutlet="sectionPreview"></ng-container>
            <div
              cdkDragHandle
              class="iframe-overlay"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
            ></div>
          </div>
        </div>
      </div>
    }
  </div>

  <div
    id="columnDropList"
    [cdkDropListConnectedTo]="sectionIds"
    [cdkDropListData]="defaultBlockColumnsMapValues"
    cdkDropListSortingDisabled
    cdkDropList
  >
    @for (column of defaultBlocksColumnMap | keyvalue; track column.key.wsEmailBlockId) {
      <ng-template #columnPreview>
        <iframe
          scrolling="no"
          [srcdoc]="column.value.htmlWithTestData | safeHtml: { removeHref: true }"
          appIframe
        ></iframe>
      </ng-template>
      <div>
        <div class="flex justify-between w-full items-end">
          <div class="block-element-header text-gray-700 text-sm py-2">{{ column.value.name }}</div>
          @if (column.key.customBlock) {
            <button (click)="deleteEmailBlock(column.key, column.value)"><ws-icon>close</ws-icon></button>
          }
        </div>
        <div
          cdkDrag
          [cdkDragData]="column.value"
          (cdkDragStarted)="dragAndDropService.onDragStarted()"
          (cdkDragDropped)="onDragDropped(column.key.type)"
        >
          <div *cdkDragPlaceholder></div>
          <div *cdkDragPreview class="bg-white">
            <ng-container *ngTemplateOutlet="columnPreview"></ng-container>
          </div>
          <div
            class="ws-email-block bg-white border border-gray-300 shadow-md hover:border-blue-500 flex flex-col justify-center items-center"
          >
            <div class="ws-email-block-preview iframe-container">
              <ng-container *ngTemplateOutlet="columnPreview"></ng-container>
              <div
                cdkDragHandle
                class="iframe-overlay"
                [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <div
    id="columnContentDropList"
    [cdkDropListConnectedTo]="columnIds"
    [cdkDropListData]="defaultBlockLastChildrenMapValues"
    cdkDropListSortingDisabled
    cdkDropList
  >
    @for (brick of defaultBlocksLastChildMap | keyvalue; track brick.key.wsEmailBlockId) {
      <ng-template #lastChild>
        <iframe
          align="center"
          scrolling="no"
          [srcdoc]="
            brick.value.htmlWithTestData
              | safeHtml: { removeHref: true, centerBody: true, dividerPreview: brick.value.type === 'Divider' }
          "
          appIframe
        ></iframe>
      </ng-template>
      <div>
        <div class="flex justify-between w-full items-center">
          <div class="block-element-header text-primary text-sm py-2">{{ brick.value.name }}</div>
          @if (brick.key.customBlock) {
            <button (click)="deleteEmailBlock(brick.key, brick.value)">
              <ws-icon>close</ws-icon>
            </button>
          }
        </div>
        <div
          class="ws-email-block__cdk-drag"
          cdkDrag
          [cdkDragData]="brick.value"
          (cdkDragStarted)="dragAndDropService.onDragStarted()"
          (cdkDragDropped)="onDragDropped(brick.key.type)"
        >
          <div *cdkDragPlaceholder></div>
          <div class="my-preview" *cdkDragPreview [ngClass]="{ 'bg-white': brick.value.type === 'Text' }">
            <ng-container *ngTemplateOutlet="lastChild"></ng-container>
          </div>
          <div
            class="ws-email-block iframe-container bg-white border border-gray-300 shadow-md hover:border-blue-500 flex flex-col justify-center items-center"
          >
            <div
              cdkDragHandle
              class="iframe-overlay"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
            ></div>
            <ng-container *ngTemplateOutlet="lastChild"></ng-container>
          </div>
        </div>
      </div>
    }
  </div>
</div>
