import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { UploadedFile } from '../../data/types/uploaded-file'

@Injectable({
  providedIn: 'root'
})
export class ImageStorageService {
  private http: HttpClient = inject(HttpClient)

  constructor() {}

  uploadImage(file: File): Observable<UploadedFile> {
    const formData = new FormData()
    formData.append('file', file)
    return this.http.post<UploadedFile>('/api/storage/images/upload', formData)
  }

  getImages(): Observable<UploadedFile[]> {
    return this.http.get<UploadedFile[]>('/api/storage/images')
  }

  deleteImages(identifiers: string[]): Observable<string[]> {
    return this.http.delete<string[]>('/api/storage/images', {
      body: identifiers
    })
  }
}
