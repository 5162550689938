import { BrickComposite } from './brickComposite'
import defaultStyles from './socialStyles.json'
import { StyleAttributes, TestData } from './brick.types'

export class Social extends BrickComposite {
  constructor(styles: StyleAttributes = {}) {
    super('Social', '', styles, 'mj-social')
    this.addDefaultStyles(defaultStyles)
  }

  override getStyles(testData: TestData = {}): any {
    return {
      tableVertical: {
        margin: '0px'
      }
    }
  }
}
