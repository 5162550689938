@if (ngControl) {
  <div class="mat-mdc-form-field w-full">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <mat-button-toggle-group [value]="value" (change)="onFieldChange($event.value)">
      @for (option of options; track $index) {
        <mat-button-toggle [disabled]="option.disabled" class="flex-grow" [value]="option.value">
          {{ option.label | translate }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>
    @if (hasErrors) {
      <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align">
        <div class="mat-mdc-form-field-error-wrapper">
          <mat-error>
            {{ validationErrorMessage }}
          </mat-error>
        </div>
      </div>
    }
  </div>
}
