export interface ColumnGrid {
  type: ColumnGridType
  columns: number
  sizes: string[]
}

export const ColumnGridTypes = {
  CUSTOM: 'CUSTOM',
  FULL: 'FULL',
  HALF_HALF: 'HALF_HALF',
  THIRD_TWO_THIRDS: 'THIRD_TWO_THIRDS',
  TWO_THIRDS_THIRD: 'TWO_THIRDS_THIRD',
  THREE_THIRDS: 'THREE_THIRDS',
  FOUR_QUARTERS: 'FOUR_QUARTERS'
} as const

export type ColumnGridType = keyof typeof ColumnGridTypes

export const CUSTOM_COLUMN_GRID: ColumnGrid = { type: ColumnGridTypes.CUSTOM, columns: 0, sizes: [] }
export const FULL_COLUMN_GRID: ColumnGrid = { type: ColumnGridTypes.FULL, columns: 1, sizes: [] }
export const HALF_HALF_COLUMN_GRID: ColumnGrid = { type: ColumnGridTypes.HALF_HALF, columns: 2, sizes: ['50%', '50%'] }
export const THIRD_TWO_THIRDS_COLUMN_GRID: ColumnGrid = {
  type: ColumnGridTypes.THIRD_TWO_THIRDS,
  columns: 2,
  sizes: ['33%', '67%']
}
export const TWO_THIRDS_THIRD_COLUMN_GRID: ColumnGrid = {
  type: ColumnGridTypes.TWO_THIRDS_THIRD,
  columns: 2,
  sizes: ['67%', '33%']
}
export const THREE_THIRDS_COLUMN_GRID: ColumnGrid = {
  type: ColumnGridTypes.THREE_THIRDS,
  columns: 3,
  sizes: []
}
export const FOUR_QUARTERS_COLUMN_GRID: ColumnGrid = {
  type: ColumnGridTypes.FOUR_QUARTERS,
  columns: 4,
  sizes: []
}
