import { Component, inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'
import { LocalizedPipe, SelectOption } from '@ws-core/core-library'
import { WsEmailPlaceholderVariable } from '../../../data/types/ws-email-placeholder-variable'

interface DialogData {
  predefinedVariables: WsEmailPlaceholderVariable[]
}

@Component({
  selector: 'app-ws-email-add-variable-dialog',
  templateUrl: './ws-email-add-variable-dialog.component.html',
  styleUrls: ['./ws-email-add-variable-dialog.component.scss'] // Corrected `styleUrl` to `styleUrls`
})
export class WsEmailAddVariableDialogComponent implements OnInit {
  variable: WsEmailPlaceholderVariable = new WsEmailPlaceholderVariable()

  typeOptions: SelectOption[] = [
    { label: 'template.editor.testData.predefinedVariable', value: 'predefined' },
    { label: 'template.editor.testData.customVariable', value: 'custom' }
  ]
  placeholderOptions: SelectOption[] = []

  typeSelect = new FormControl(this.typeOptions[0].value)
  formGroup = new FormGroup({
    type: new FormControl(this.typeOptions[0].value),
    identifier: new FormControl<string>(''),
    value: new FormControl<string>(''),
    predefinedVariable: new FormControl<WsEmailPlaceholderVariable | null>(null)
  })
  customVariableFormGroup = new FormGroup({
    identifier: new FormControl<string>(''),
    value: new FormControl<string>('')
  })
  predefinedVariableFormGroup = new FormGroup({
    predefinedVariable: new FormControl<WsEmailPlaceholderVariable | null>(null),
    value: new FormControl<string>('')
  })

  readonly data = inject<DialogData>(MAT_DIALOG_DATA)
  private localizedPipe: LocalizedPipe = inject(LocalizedPipe)
  private dialogRef = inject<MatDialogRef<WsEmailAddVariableDialogComponent>>(
    MatDialogRef<WsEmailAddVariableDialogComponent>
  )

  ngOnInit() {
    this.placeholderOptions = this.data.predefinedVariables.map((variable) => {
      return { label: this.localizedPipe.transform(variable.label), value: variable }
    })
  }

  save() {
    if (this.typeSelect.value === 'custom') {
      if (!this.customVariableFormGroup.valid) {
        this.customVariableFormGroup.markAllAsTouched()
        return
      }
      this.saveCustomVariable()
    } else {
      if (!this.predefinedVariableFormGroup.valid) {
        this.predefinedVariableFormGroup.markAllAsTouched()
        return
      }
      this.savePredefinedVariable()
    }
    this.dialogRef.close(this.variable)
  }

  saveCustomVariable() {
    this.variable.identifier = this.customVariableFormGroup.get('identifier')?.value || ''
    this.variable.value = this.customVariableFormGroup.get('value')?.value || ''
    this.variable.label = {
      en: this.customVariableFormGroup.get('identifier')?.value || '',
      de: this.customVariableFormGroup.get('identifier')?.value || ''
    }
    this.variable.isCustom = true
  }

  savePredefinedVariable() {
    this.variable = this.predefinedVariableFormGroup.get('predefinedVariable')?.value || this.variable
    this.variable.value = this.predefinedVariableFormGroup.get('value')?.value || ''
  }

  close() {
    this.dialogRef.close()
  }
}
