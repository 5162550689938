<div
  class="ws-dialog fixed top-0 bottom-0 left-0 right-0 flex justify-center items-end md:items-center"
  [id]="dialogId"
>
  <div class="ws-dialog-window bg-white h-fit w-full md:w-fit flex flex-col" [class]="{ slideOutDown: closeDialog }">
    <div class="ws-dialog-top">
      @if (dialogTitleTemplateRef) {
        <ng-container [ngTemplateOutlet]="dialogTitleTemplateRef"></ng-container>
      }

      @if (!dialogTitleTemplateRef && dialogTitleText) {
        <div class="px-5 md:px-6 my-5 md:my-6">
          <div class="ws-dialog-header flex justify-start font-bold">
            {{ dialogTitleText | translate }}
          </div>
        </div>
      }
    </div>

    <div class="ws-dialog-content overflow-y-auto">
      @if (dialogContentTemplateRef) {
        <ng-container [ngTemplateOutlet]="dialogContentTemplateRef"></ng-container>
      }

      @if (!dialogContentTemplateRef && dialogContentText) {
        <div
          class="px-5 md:px-6"
          [ngClass]="{
            'mb-5 md:mb-6': !dialogActionsTemplateRef && dialogActions.length === 0,
            'mt-5 md:mt-6': !dialogTitleTemplateRef && !dialogTitleText
          }"
        >
          <div class="ws-dialog-text">{{ dialogContentText | translate }}</div>
        </div>
      }
    </div>

    <div class="ws-dialog-bottom">
      @if (dialogActionsTemplateRef) {
        <ng-container [ngTemplateOutlet]="dialogActionsTemplateRef"></ng-container>
      }

      @if (!dialogActionsTemplateRef && dialogActions.length > 0) {
        <div class="px-5 md:px-6 my-5 md:my-6">
          <div class="ws-dialog-buttons flex flex-wrap justify-center md:justify-end items-center">
            @for (action of dialogActions; track $index) {
              @if (action.buttonType === 'primary') {
                <button
                  class="ws-dialog-button ws-dialog-button--primary md:ml-5 mx-2 md:mx-0"
                  color="primary"
                  type="button"
                  mat-flat-button
                  (click)="close(action.action)"
                  [disabled]="closeDialog"
                >
                  {{ action.label | translate }}
                </button>
              } @else if (action.buttonType === 'secondary') {
                <button
                  class="ws-dialog-button ws-dialog-button--primary md:ml-5 mx-2 md:mx-0"
                  color="primary"
                  type="button"
                  mat-button
                  (click)="close(action.action)"
                  [disabled]="closeDialog"
                >
                  {{ action.label | translate }}
                </button>
              }
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>
