export class AllowedFileType {
  type: string
  exact: boolean
  fileEndings: string[]

  private typeToExtensions: { [key: string]: string[] } = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/svg+xml': ['.svg'],
    'image/webp': ['.webp'],
    'image/bmp': ['.bmp'],
    'image/tiff': ['.tiff', '.tif'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'text/plain': ['.txt'],
    'application/rtf': ['.rtf'],
    'text/html': ['.html', '.htm'],
    'application/json': ['.json'],
    'application/xml': ['.xml'],
    'application/epub+zip': ['.epub'],
    'application/x-iwork-keynote-sffkey': ['.key'],
    'application/x-iwork-pages-sffpages': ['.pages'],
    'application/x-iwork-numbers-sffnumbers': ['.numbers'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/vnd.oasis.opendocument.presentation': ['.odp'],
    'application/x-abiword': ['.abw'],
    'audio/mpeg': ['.mp3'],
    'audio/wav': ['.wav'],
    'audio/ogg': ['.ogg'],
    'audio/webm': ['.weba'],
    'audio/flac': ['.flac'],
    'audio/aac': ['.aac'],
    'audio/x-ms-wma': ['.wma'],
    'video/mp4': ['.mp4'],
    'video/webm': ['.webm'],
    'video/ogg': ['.ogv'],
    'video/x-msvideo': ['.avi'],
    'video/mpeg': ['.mpeg', '.mpg'],
    'video/x-matroska': ['.mkv'],
    'video/quicktime': ['.mov'],
    'video/x-flv': ['.flv'],
    'video/x-ms-wmv': ['.wmv']
  }

  constructor(allowed: string, exact: boolean) {
    this.exact = exact
    if (allowed.startsWith('.')) {
      this.fileEndings = [allowed]
      this.type = this.getTypeForExtension(allowed)
    } else {
      this.type = allowed
      this.fileEndings = this.getExtensionsForType(allowed)
    }
  }

  getExtensionsForType(type: string): string[] {
    return this.typeToExtensions[type] || []
  }

  getTypeForExtension(extension: string): string {
    for (const [type, extensions] of Object.entries(this.typeToExtensions)) {
      if (extensions.includes(extension)) return type
    }
    return ''
  }
}
