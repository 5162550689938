export class WsTime {
  time: number

  getCurrentDateInMs() {
    const dateToday = this.removeTimezone(new Date())
    return new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate()).getTime()
  }
  currentDateInMs: number = this.getCurrentDateInMs()

  constructor(time: Date | string | number, isSeconds = false) {
    if (time instanceof Date) {
      this.time = time.getTime()
      return
    }
    if (typeof time === 'number') {
      if (isSeconds) {
        this.time = time * 1000
        return
      }
      this.time = time
      return
    }

    this.time = this.timeStringToMilliseconds(time)
  }

  private timeZoneOffset(): number {
    const anyDate = new Date()
    return anyDate.getTimezoneOffset()
  }

  private removeTimezone(date: Date): Date {
    const offset = this.timeZoneOffset()
    return new Date(date.getTime() + offset * 60000)
  }

  private timeStringToMilliseconds(timeString: string): number {
    const parts = timeString.split(':')
    let hours = 0
    let minutes = 0
    let seconds = 0
    let milliseconds = 0

    if (parts.length >= 2) {
      hours = parseInt(parts[0])
      minutes = parseInt(parts[1])

      if (parts.length >= 3) {
        seconds = parseInt(parts[2])

        const secondsParts = parts[2].split('.')

        if (secondsParts.length === 2) {
          seconds = parseInt(secondsParts[0])
          milliseconds = parseInt(secondsParts[1])
        }
      }
    }

    // Invalid time string
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || isNaN(milliseconds)) {
      return this.currentDateInMs
    }

    const timeInMs = this.timeToMilliseconds(hours, minutes, seconds, milliseconds)
    const oneDayInMs = 86400000

    return timeInMs < oneDayInMs ? this.currentDateInMs + timeInMs : timeInMs
  }

  private timeToMilliseconds(hours: number, minutes: number, seconds: number, milliseconds: number): number {
    return hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds
  }

  private asDate(): Date {
    return new Date(this.time)
  }

  public milliseconds(): number {
    return (this.time % this.currentDateInMs) % 1000
  }

  public seconds(): number {
    return Math.floor((this.time % this.currentDateInMs) / 1000) % 60
  }

  public minutes(): number {
    return Math.floor((this.time % this.currentDateInMs) / 60000) % 60
  }

  public hours(): number {
    return Math.floor((this.time % this.currentDateInMs) / 3600000)
  }

  public addMilliseconds(milliseconds: number) {
    return new WsTime((this.time % this.currentDateInMs) + milliseconds)
  }

  public addSeconds(seconds: number) {
    return new WsTime((this.time % this.currentDateInMs) + seconds * 1000)
  }

  public addMinutes(minutes: number) {
    return new WsTime((this.time % this.currentDateInMs) + minutes * 60000)
  }

  public addHours(hours: number) {
    return new WsTime((this.time % this.currentDateInMs) + hours * 3600000)
  }

  public format() {
    const seconds = this.seconds()
    const milliseconds = this.milliseconds()

    const timeAsDate = this.asDate()

    const paddedHours = timeAsDate.getHours().toString().padStart(2, '0')
    const paddedMinutes = timeAsDate.getMinutes().toString().padStart(2, '0')

    let timeString = `${paddedHours}:${paddedMinutes}`

    if (this.seconds() !== 0 || milliseconds !== 0) {
      timeString += `:${seconds.toString().padStart(2, '0')}`
    }

    if (milliseconds !== 0) {
      timeString += `.${milliseconds.toString().padStart(3, '0')}`
    }

    return timeString
  }

  public formatSeperated() {
    let result = ''

    if (this.hours() > 0) {
      result += `${this.hours()}h`
    }
    if (this.minutes() > 0) {
      result += ` ${this.minutes()}m`
    }
    if (this.seconds() > 0) {
      result += ` ${this.seconds()}s`
    }
    if (this.milliseconds() > 0) {
      result += ` ${this.milliseconds()}ms`
    }

    return result
  }
}
