@if (ngControl) {
  <div class="mat-mdc-form-field ws-form__timepicker w-full">
    @if (native) {
      <ws-form-input
        [label]="label"
        class="ws-form__timepicker__native"
        [icon]="icon"
        (iconClick)="iconClick.emit($event)"
        (ngModelChange)="timeHasChanged($event)"
        (blur)="handleInputBlur()"
        (input)="handleTimeInputChange($event)"
        [formControl]="formControl"
        [step]="nativeTimeInputStep()"
        [clearable]="clearable"
        (clear)="clearSelectedTime()"
        type="time"
      ></ws-form-input>
    } @else {
      <div class="ws-form__timepicker__extended">
        <ws-form-select
          [label]="label"
          floatLabel="always"
          placeholder="h"
          (fieldBlur)="handleIndividualFieldBlur()"
          [filter]="!platformService.isMobile"
          class="ws-form__timepicker__hours ws-form__timepicker__no-border-right"
          [formControl]="selectedHoursFormControl"
          [options]="options.hours"
        ></ws-form-select>
        <ws-form-select
          placeholder="m"
          (fieldBlur)="handleIndividualFieldBlur()"
          [filter]="!platformService.isMobile"
          class="ws-form__timepicker__minutes ws-form__timepicker__no-border-left"
          [class.ws-form__timepicker__no-border-right]="
            showMilliseconds || selectedMillisecondsFormControl.value || showSeconds || selectedSecondsFormControl.value
          "
          [formControl]="selectedMinutesFormControl"
          [options]="options.minutes"
        ></ws-form-select>
        @if (
          showMilliseconds || selectedMillisecondsFormControl.value || showSeconds || selectedSecondsFormControl.value
        ) {
          <ws-form-select
            placeholder="s"
            (fieldBlur)="handleIndividualFieldBlur()"
            [filter]="!platformService.isMobile"
            class="ws-form__timepicker__seconds ws-form__timepicker__no-border-left"
            [class.ws-form__timepicker__no-border-right]="showMilliseconds || selectedMillisecondsFormControl.value"
            [formControl]="selectedSecondsFormControl"
            [options]="options.seconds"
          ></ws-form-select>
        }

        @if (showMilliseconds || selectedMillisecondsFormControl.value) {
          <ws-form-select
            placeholder="ms"
            (fieldBlur)="handleIndividualFieldBlur()"
            [filter]="true"
            class="ws-form__timepicker__milliseconds ws-form__timepicker__no-border-left"
            [formControl]="selectedMillisecondsFormControl"
            [options]="options.milliseconds"
          ></ws-form-select>
        }

        @if (value) {
          <ws-icon (click)="clearSelectedTime()" class="ws-form__timepicker__clear text-accent"> close </ws-icon>
        }
      </div>
      <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align">
        <div class="mat-mdc-form-field-error-wrapper">
          <mat-error>
            {{ validationErrorMessage }}
          </mat-error>
        </div>
      </div>
    }
  </div>
}

<!--<pre>value: {{ value }}</pre>-->
<!--<pre>formControl.value: {{ formControl.value }}</pre>-->
<!--<pre>time: {{ time.time }}</pre>-->
<!--<pre>touched: {{ formControl.touched }}</pre>-->
<!--<pre>dirty: {{ formControl.dirty }}</pre>-->
<!--<pre>invalid: {{ formControl.invalid }}</pre>-->
<!--<pre>errors: {{ formControl.errors | json }}</pre>-->
<!--<pre>has errors: {{ hasErrors }}</pre>-->
<!--<pre>validationErrorMessage: {{ validationErrorMessage }}</pre>-->
