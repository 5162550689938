import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'limitText'
})
export class LimitTextPipe implements PipeTransform {
  public transform(text: string, digitsLimit: number = 50): string {
    if (!text) return ''
    return text.length > digitsLimit ? text.substring(0, digitsLimit) + '...' : text
  }
}
