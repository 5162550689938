<app-ws-email-element-wrapper
  #wrapper
  [element]="element"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [dragDisabled]="true"
  [(elementIsActive)]="elementIsActive"
  [ngClass]="{ 'column-guidelines': !elementIsActive && editorStateService.showGuidelines }"
  [component]="this"
  [elementIsSelectable]="false"
>
  <div
    class="mj-outlook-group-fix"
    style="font-size: 0; text-align: left; direction: ltr; display: inline-block; width: 100%"
    [style.vertical-align]="element?.styles?.['vertical-align']"
  >
    <!-- Column With gutter -->
    @if (element?.hasGutter()) {
      <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
        <tbody>
          <tr>
            <td [ngStyle]="element?.getStyles(template.testData).gutter">
              <!------ Column ------>
              <table
                [ngStyle]="element?.getStyles(template.testData).table"
                border="0"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
                style="vertical-align: top; width: 100%"
                width="100%"
              >
                <tbody
                  [id]="element?.id || ''"
                  [cdkDropListConnectedTo]="columnIds()"
                  (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
                  [cdkDropListData]="element?.getChildren()"
                  cdkDropList
                  class="droplist-for-last-child"
                >
                  @if (element && element.getChildren().length) {
                    @for (child of element.getChildren(); track child.id) {
                      @switch (child.type) {
                        @case ('Text') {
                          <tr>
                            <td
                              [class]="child.styles['css-class']"
                              [hidden]="child?.isHidden()"
                              [ngStyle]="child?.getStyles().td"
                              style="word-break: break-word; font-size: 0"
                            >
                              <app-ws-email-text
                                [parentElementAndSiblings]="elementAndSiblings"
                                [template]="template"
                                [parent]="element"
                                [element]="child"
                              ></app-ws-email-text>
                            </td>
                          </tr>
                        }
                        @case ('Divider') {
                          <tr>
                            <td
                              [class]="child.styles['css-class']"
                              [hidden]="child?.isHidden()"
                              [ngStyle]="child?.getStyles().td"
                              style="word-break: break-word; font-size: 0"
                            >
                              <app-ws-email-divider
                                [parentElementAndSiblings]="elementAndSiblings"
                                [template]="template"
                                [parent]="element"
                                [element]="child"
                              ></app-ws-email-divider>
                            </td>
                          </tr>
                        }
                        @case ('Button') {
                          <tr>
                            <td
                              [class]="child.styles['css-class']"
                              [hidden]="child?.isHidden()"
                              [align]="child?.styles?.['align']"
                              [style.vertical-align]="child?.styles?.['vertical-align']"
                              [style.padding]="child?.getPadding()"
                              style="word-break: break-word; font-size: 0"
                            >
                              <app-ws-email-button
                                [parentElementAndSiblings]="elementAndSiblings"
                                [template]="template"
                                [parent]="element"
                                [element]="child"
                              ></app-ws-email-button>
                            </td>
                          </tr>
                        }
                        @case ('Image') {
                          <tr>
                            <td
                              [class]="element.styles['css-class']"
                              [hidden]="child?.isHidden()"
                              [align]="child?.styles?.['align']"
                              [style.vertical-align]="child?.styles?.['vertical-align']"
                              [style.padding]="child?.getPadding()"
                              style="word-break: break-word; font-size: 0"
                            >
                              <app-ws-email-image
                                [parentElementAndSiblings]="elementAndSiblings"
                                [template]="template"
                                [parent]="element"
                                [element]="child"
                              ></app-ws-email-image>
                            </td>
                          </tr>
                        }
                        @case ('Spacer') {
                          <tr [class]="child.styles['css-class']">
                            <td style="word-break: break-word; font-size: 0">
                              <app-ws-email-spacer
                                [hidden]="child?.isHidden()"
                                [parentElementAndSiblings]="elementAndSiblings"
                                [template]="template"
                                [parent]="element"
                                [element]="child"
                              ></app-ws-email-spacer>
                            </td>
                          </tr>
                        }
                        @case ('Social') {
                          <tr>
                            <td
                              [class]="child.styles['css-class']"
                              [hidden]="child?.isHidden()"
                              align="center"
                              [style.padding]="child?.getPadding()"
                              [align]="child.styles['align'] || 'center'"
                              style="font-size: 0; word-break: break-word"
                            >
                              <app-ws-email-social
                                [id]="element.id"
                                [parentElementAndSiblings]="elementAndSiblings"
                                [template]="template"
                                [parent]="element"
                                [element]="child"
                              ></app-ws-email-social>
                            </td>
                          </tr>
                        }
                      }
                    }
                  } @else {
                    <!-- empty column for drag -->
                    <tr>
                      <td
                        [ngClass]="{ 'empty-column': !elementIsActive }"
                        [id]="element?.id || ''"
                        [cdkDropListConnectedTo]="columnIds()"
                        (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
                        [cdkDropListData]="element?.getChildren()"
                        cdkDropList
                        style="font-size: 14px; height: 100px"
                        class="h-full bg-white text-center"
                      >
                        <div class="content">
                          <div class="text">{{ 'element.dropContentHint' | translate }}</div>
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              <!------ Column ------>
            </td>
          </tr>
        </tbody>
      </table>
    } @else {
      <!------ Column ------>
      <table
        [ngStyle]="element?.getStyles(template.testData).table"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="vertical-align: top; width: 100%"
        width="100%"
      >
        <tbody
          [id]="element?.id || ''"
          [cdkDropListConnectedTo]="columnIds()"
          (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
          [cdkDropListData]="element?.getChildren()"
          cdkDropList
          class="droplist-for-last-child"
        >
          @if (element && element.getChildren().length) {
            @for (child of element.getChildren(); track child.id) {
              @switch (child.type) {
                @case ('Text') {
                  <tr>
                    <td
                      [class]="child.styles['css-class']"
                      [hidden]="child?.isHidden()"
                      [ngStyle]="child?.getStyles().td"
                      style="word-break: break-word; font-size: 0"
                    >
                      <app-ws-email-text
                        [parentElementAndSiblings]="elementAndSiblings"
                        [template]="template"
                        [parent]="element"
                        [element]="child"
                      ></app-ws-email-text>
                    </td>
                  </tr>
                }
                @case ('Divider') {
                  <tr>
                    <td
                      [class]="child.styles['css-class']"
                      [hidden]="child?.isHidden()"
                      [ngStyle]="child?.getStyles().td"
                      style="word-break: break-word; font-size: 0"
                    >
                      >
                      <app-ws-email-divider
                        [parentElementAndSiblings]="elementAndSiblings"
                        [template]="template"
                        [parent]="element"
                        [element]="child"
                      ></app-ws-email-divider>
                    </td>
                  </tr>
                }
                @case ('Button') {
                  <tr>
                    <td
                      [class]="child.styles['css-class']"
                      [hidden]="child?.isHidden()"
                      [align]="child?.styles?.['align']"
                      [style.vertical-align]="child?.styles?.['vertical-align']"
                      [style.padding]="child?.getPadding()"
                      style="word-break: break-word; font-size: 0"
                    >
                      <app-ws-email-button
                        [parentElementAndSiblings]="elementAndSiblings"
                        [template]="template"
                        [parent]="element"
                        [element]="child"
                      ></app-ws-email-button>
                    </td>
                  </tr>
                }
                @case ('Image') {
                  <tr>
                    <td
                      [class]="child.styles['css-class']"
                      [hidden]="child?.isHidden()"
                      [style.background-color]="child?.styles?.['container-background-color']"
                      [align]="child?.styles?.['align']"
                      [style.vertical-align]="child?.styles?.['vertical-align']"
                      [style.padding]="child?.getPadding()"
                      style="word-break: break-word; font-size: 0"
                    >
                      <app-ws-email-image
                        [parentElementAndSiblings]="elementAndSiblings"
                        [template]="template"
                        [parent]="element"
                        [element]="child"
                      ></app-ws-email-image>
                    </td>
                  </tr>
                }
                @case ('Spacer') {
                  <tr>
                    <td
                      [class]="child.styles['css-class']"
                      [hidden]="child?.isHidden()"
                      style="word-break: break-word; font-size: 0"
                    >
                      <app-ws-email-spacer
                        [parentElementAndSiblings]="elementAndSiblings"
                        [template]="template"
                        [parent]="element"
                        [element]="child"
                      ></app-ws-email-spacer>
                    </td>
                  </tr>
                }
                @case ('Social') {
                  <tr>
                    <td
                      [hidden]="child?.isHidden()"
                      [class]="child.styles['css-class']"
                      [align]="child.styles['align'] || 'center'"
                      [style.padding]="child?.getPadding()"
                      style="font-size: 0; word-break: break-word"
                    >
                      <app-ws-email-social
                        [id]="element.id"
                        [parentElementAndSiblings]="elementAndSiblings"
                        [template]="template"
                        [parent]="element"
                        [element]="child"
                      ></app-ws-email-social>
                    </td>
                  </tr>
                }
              }
            }
          } @else {
            <!-- empty column for drag -->
            <tr>
              <td
                [ngClass]="{ 'empty-column': !elementIsActive }"
                [id]="element?.id || ''"
                [cdkDropListConnectedTo]="columnIds()"
                (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
                [cdkDropListData]="element?.getChildren()"
                cdkDropList
                style="font-size: 14px; height: 100px"
                class="h-full bg-white text-center"
              >
                <div class="content">
                  <div class="text">{{ 'element.dropContentHint' | translate }}</div>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
      <!------ Column ------>
    }
  </div>
</app-ws-email-element-wrapper>
