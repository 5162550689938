<span
  (cdkDragDropped)="onDragDropped()"
  [id]="'draggable-element__' + element.id"
  [ngClass]="{
    'active-element': elementIsActive && !showTextEditor
  }"
  class="ws-email-element block"
  (cdkDragStarted)="dragAndDropService.onDragStarted()"
  *ngIf="element"
  [cdkDragData]="element"
  [cdkDragDisabled]="!elementIsActive || dragDisabled"
  [class.cdk-visually-hidden]="element.isHidden()"
  cdkDrag
>
  <span *ngIf="elementIsActive && !showTextEditor" class="ws-email-element-label flex justify-between">
    <span class="flex justify-between items-center">
      <button class="copy-icon pr-2" (click)="duplicateElement()"><mat-icon>content_copy</mat-icon></button>
      @if (element.type !== 'SocialElement') {
        <button class="save-icon pr-2" (click)="saveElementBlock()"><mat-icon>save</mat-icon></button>
      }
      <button class="delete-icon" (click)="removeElement()"><mat-icon>delete</mat-icon></button>
    </span>
  </span>

  <span
    *ngIf="dragHandle && elementIsActive && !dragDisabled"
    cdkDragHandle
    class="drag-handle absolute bg-active rounded flex align-items-center"
  >
    <ws-icon>drag_pan</ws-icon>
  </span>
  @if (dragHandle) {
    <span *cdkDragPreview>
      <span class="drag-handle absolute bg-active rounded flex align-items-center">
        <ws-icon>drag_pan</ws-icon>
      </span>
    </span>
  }
  <div *cdkDragPlaceholder></div>
  <ng-content></ng-content>
</span>

<ng-template #simpleDialogContentTemplateRef>
  <div class="px-5">
    <ws-form-input label="{{ 'element.name' | translate }}" [(ngModel)]="blockElementName"></ws-form-input>
  </div>
</ng-template>
