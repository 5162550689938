<div class="advanced-file-upload__file-preview text-center flex flex-col justify-center items-center">
  @if (!files.length) {
    <span class="italic mt-4">{{
      (multiple ? 'ws.forms.files.noFilesSelected' : 'ws.forms.file.noFileSelected') | translate
    }}</span>
  } @else if (sortingAllowed) {
    <div
      cdkDropList
      class="advanced-file-upload__file-preview-list w-full"
      (cdkDropListDropped)="dropFileInList($event)"
    >
      @for (file of files; track $index) {
        <div
          [classList]="
            multiple
              ? 'flex flex-row w-full items-center mt-2 first:mt-4 @container'
              : 'flex flex-row w-full items-center mt-2 first:mt-0 @container'
          "
          cdkDrag
          cdkDragBoundary=".file-preview__list"
        >
          <div class="advanced-file-upload__file-preview-drag-handle flex justify-start mr-4">
            <ws-icon class="text-accent cursor-grab">drag_handle</ws-icon>
          </div>
          <ng-container *ngTemplateOutlet="preview; context: { $implicit: file, index: $index }"></ng-container>
        </div>
      }
    </div>
  } @else {
    @for (file of files; track $index) {
      <div
        [classList]="
          multiple
            ? 'flex flex-row w-full items-center mt-2 first:mt-4 @container'
            : 'flex flex-row w-full items-center mt-2 first:mt-0 @container'
        "
      >
        <ng-container *ngTemplateOutlet="preview; context: { $implicit: file, index: $index }"></ng-container>
      </div>
    }
  }
</div>

<ng-template #preview let-file let-index="index">
  <div class="flex justify-start">
    @if (file.type.includes('image')) {
      <img
        [src]="file | imageFileToObjectUrl"
        alt="image"
        class="object-contain mr-3 @md:mr-6"
        [width]="previewSize"
        [height]="previewSize"
      />
    } @else {
      <ws-icon [size]="previewSize" class="text-accent mr-3 @md:mr-6">draft</ws-icon>
    }
    <div class="flex flex-col justify-center items-start text-left">
      <span class="hyphens-auto break-words break-all text-sm">{{ file.name }}</span>
      <span class="text-xs">{{ file.size | fileSizeFormat }}</span>
    </div>
  </div>
  <ws-icon
    size="25px"
    class="text-accent cursor-pointer ml-auto relative z-1 p-2"
    (click)="emitIndexOfRemovedFile(index)"
    >close
  </ws-icon>
</ng-template>
