import { BrickComposite } from './brickComposite'
import defaultStyles from './templateStyles.json'
import fonts from './templateFonts.json'
import mjml2html from 'mjml-browser'
import { StyleAttributes, TestData } from './brick.types'

export class Template extends BrickComposite {
  wsEmailTemplateId: number | null = null
  testData: TestData = {}

  override getStyles(testData: TestData = {}) {
    return this.styles
  }

  public getStyleOfAttributes(): { [key: string]: any } {
    const attributes = { ...this.styles }
    delete attributes['background-color']
    delete attributes['link-color']

    Object.keys(attributes).forEach((key) => {
      attributes[key] = this.replaceTestData(attributes[key].toString(), this.testData)
    })

    return attributes
  }

  constructor(
    name: string,
    styles: StyleAttributes = {},
    testDataJson: TestData = {},
    wsEmailTemplateId: number | null = null
  ) {
    super('Template', '', styles, 'mjml')
    this.name = name
    this.styles = styles
    this.addDefaultStyles(defaultStyles)
    this.testData = testDataJson
    this.wsEmailTemplateId = wsEmailTemplateId
  }

  private generateMjmlStyle(): string {
    const linkColor = this.styles['link-color']?.toString() || '#0000EE'

    const generalStyles = `
    <mj-style>
      @media only screen and (max-width:480px) {
        .hide-mobile { display: none!important; }
      }
      .hidden-element { display: none!important; }
      .link-no-style { color: inherit!important; text-decoration: none!important; }
    </mj-style>
  `

    const linkStyles = `
    <mj-style inline="inline">
      img {
        object-fit: contain;
      }
      a span {
        color: ${linkColor};
        text-decoration: none;
      }
      a {
        color: ${linkColor};
        text-decoration: none;
      }
      p {
        margin: 0;
      }
    </mj-style>
  `

    return generalStyles.replace(/(\r\n|\n|\r)/gm, '') + linkStyles
  }

  generateMjmlAttributes() {
    const attributes = Object.keys(this.styles)
      .filter((attribute) => attribute !== 'background-color' && attribute !== 'link-color')
      .map((attribute) => `${attribute}="${this.styles[attribute]}"`)
      .join(' ')
    return attributes ? `<mj-attributes><mj-all ${attributes}/></mj-attributes>` : ''
  }

  getMjmlFonts() {
    return fonts
      .map((font) => {
        return `<mj-font name="${font.name}" href="${font.link}" />`
      })
      .join('')
  }

  override getMjml(withoutHiddenBricks = false): string {
    const children = withoutHiddenBricks ? this.getVisibleChildren() : this.children
    const childrenMjml = children.map((brick) => brick.getMjml(withoutHiddenBricks)).join('\n')
    const bodyStyle = this.styles['background-color'] ? ` background-color="${this.styles['background-color']}"` : ''

    return this.constructMjml(childrenMjml, bodyStyle)
  }

  private constructMjml(bricksMjml: string, bodyStyle: string): string {
    const mjmlContent = `
    <mjml>
      <mj-head>
        <mj-preview>\${preHeader}</mj-preview>
        ${this.getMjmlFonts()}
        ${this.generateMjmlStyle()}
        ${this.generateMjmlAttributes()}
      </mj-head>
      <mj-body${bodyStyle}>
        ${bricksMjml}
      </mj-body>
    </mjml>
  `
    return mjmlContent.replace(/(\r\n|\n|\r)/gm, '')
  }

  override getHtmlWithTestData(): string {
    return this.getHtml(true)
  }

  override getHtml(replaceTestData = false): string {
    //workaround for buttons (bug when using ${} format for mjml to html
    let html = mjml2html(this.convertPlaceholderToTripleParenthesesFormat(this.getMjml(true))).html.replaceAll('\n', '')

    html = this.convertTripleParenthesesToPlaceholderFormat(html)

    if (replaceTestData) {
      html = this.replaceTestData(html, this.testData)
    }
    return html
  }

  getBackgroundColor() {
    let backgroundColorValue = (this.styles['background-color'] || '').toString()
    if (backgroundColorValue.startsWith('${')) {
      backgroundColorValue = this.replaceTestData(backgroundColorValue, this.testData)
    }
    return { background: `${backgroundColorValue}` }
  }
}
