import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { OptionsFieldComponent } from '../options-field/options-field.component'
import { ControlValueAccessor } from '@angular/forms'
import { SelectComponent } from '../select/select.component'
import { SelectOption } from '../../../types/forms/select-option'

@Component({
  selector: 'ws-form-chips-select',
  templateUrl: './chips-select.component.html',
  styleUrls: ['./chips-select.component.scss']
})
export class ChipsSelectComponent extends OptionsFieldComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholder = ''
  // TODO: test if this is behaving how it should
  @Input() compareWithFunction?: (o1: any, o2: any) => boolean = (o1: any, o2: any): boolean => o1 === o2

  public override value: any[] = []

  public mappedValueOptions: SelectOption[] = []

  @ViewChild('selectElement') selectElement?: SelectComponent

  @Output() public addValue = new EventEmitter<any>()
  @Output() public removeValue = new EventEmitter<any>()

  removeFromSelection(value: any) {
    const newValue: any[] = this.value.filter((element: any): boolean => element !== value)
    this.formControl.setValue(newValue)
    this.updateValueOfFormElement()
  }

  updateValueOfFormElement() {
    this.setMappedValueOptions(this.formControl.value)
    this.onFieldChange(this.formControl.value)
    this.writeValue(this.formControl.value)
  }

  private setMappedValueOptions(values: any[]) {
    const newMappedValues: SelectOption[] = this.mapValuesToOptions(values || [], this.options)
    newMappedValues.forEach((option: SelectOption) => {
      if (!this.mappedValueOptions.includes(option)) {
        if (this.formControl) {
          this.addValue.emit(option.value)
        }
      }
    })
    this.mappedValueOptions.forEach((option: SelectOption) => {
      if (!newMappedValues.includes(option)) {
        this.removeValue.emit(option.value)
      }
    })

    this.mappedValueOptions = newMappedValues
  }

  private mapValuesToOptions(values: any[], options: SelectOption[]): SelectOption[] {
    return values.map((value: any): SelectOption => {
      const matchingGivenOption: SelectOption | undefined = options.find(
        (option: SelectOption): boolean => option.value === value
      )
      if (matchingGivenOption) return matchingGivenOption

      return {
        value: value,
        label: value.label || value
      }
    })
  }

  override writeValue(values: any[]) {
    super.writeValue(values)
    // this sorts values initially by options given, so that the chips are displayed in the same order as inside the select field
    const sortedValues: any[] = this.sortValuesByOptions(values || [])
    this.setMappedValueOptions(sortedValues)
  }

  sortValuesByOptions(values: any[]): any[] {
    const allDefaultSortedElements: any[] = this.options.map((option: SelectOption) => option.value)
    return values.sort((elementA, elementB) => {
      return allDefaultSortedElements.indexOf(elementA) - allDefaultSortedElements.indexOf(elementB)
    })
  }
}
