{
  "addNew": "Neu hinzufügen",
  "bricks": "Bausteine",
  "back": "Zurück",
  "confirm": "Bestätigen",
  "cancel": "Abbrechen",
  "delete": "löschen",
  "desktopPreview": "Vorschau Desktop",
  "drafts": "Vorlagen",
  "edit": "bearbeiten",
  "element": {
    "dropContentHint": "Inhalt hier einfügen",
    "emptySection": "Leere Zeile",
    "errorSaveElementBlock": "Baustein konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingaben.",
    "name": "Bausteinbezeichnung",
    "saveElementBlock": "Baustein speichern",
    "successSaveElementBlock": "Baustein erfolgreich gespeichert",
    "type": {
      "column": "Spalte",
      "columns": "Spalten",
      "divider": "Linie",
      "headline": "Überschrift",
      "image": "Bild",
      "section": "Zeile",
      "sections": "Zeilen",
      "spacer": "Abstand"
    }
  },
  "emailAddress": "Email Adresse",
  "emailBlock": {
    "delete": "Baustein löschen",
    "deleteEmailBlockDialog": "Sind Sie sicher, dass sie den Baustein löschen möchten?",
    "deleteError": "Baustein konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.",
    "deleteSuccess": "Baustein erfolgreich gelöscht"
  },
  "height": "Höhe",
  "layers": "Ebenen",
  "mobilePreview": "Vorschau Mobil",
  "reset": "Zurücksetzen",
  "save": "Speichern",
  "send": "Versenden",
  "template": {
    "close": "Schließen",
    "configuration": {
      "configuration": "Konfiguration",
      "element": "Element",
      "hide": "Konfiguration ausblenden",
      "open": "Konfiguration einblenden",
      "overview": "Übersicht",
      "textSettings": "Textierung"
    },
    "content": "Inhalt",
    "create": "Template erstellen",
    "datasetChoiceDialog": "Bitte wählen Sie einen Datensatz aus.",
    "delete": "Template löschen",
    "deleteTemplateDialog": "Sind Sie sicher, dass sie das Template löschen möchten?",
    "duplicate": "Template duplizieren",
    "edit": "Template bearbeiten",
    "editor": {
      "advancedView": "Erweiterte Ansicht",
      "container": "Container hinzufügen",
      "dataObject": "Datenobjekt hinzufügen",
      "earlierVersions": "Frühere Versionen",
      "helpingGrid": "Hilfslinien",
      "confirm": "Bestätigen",
      "label": "Textfeld hinzufügen",
      "newDataObject": "{ Datenobjekt }",
      "newTextField": "Neues Textfeld",
      "pageCounter": "Seitenzahl hinzufügen",
      "placeholder": "Platzhalter hinzufügen",
      "preview": "PDF Vorschau",
      "qrCode": "QR-Code hinzufügen",
      "recipient": "Empfänger",
      "saveChanges": "Änderungen speichern",
      "templateOverview": "alle Templates",
      "testData": {
        "addVariable": "Variable hinzufügen",
        "customVariable": "Benutzerdefinierte Variable",
        "edit": "Testdatensatz bearbeiten",
        "label": "Testdatensatz",
        "key": "Schlüssel",
        "predefinedVariable": "Vordefinierte Variable",
        "value": "Wert"
      },
      "image": {
        "add": "Bild hinzufügen",
        "delete": {
          "error": "Bild konnte nicht gelöscht werden.",
          "success": "Bild erfolgreich gelöscht."
        },
        "edit": {
          "flipHorizontally": "Horizontal spiegeln",
          "flipVertically": "Vertikal spiegeln",
          "rotateLeft": "Links drehen",
          "rotateRight": "Rechts drehen"
        },
        "overview": {
          "error": "Bilder konnten nicht geladen werden."
        },
        "upload": {
          "confirmOverride": "Ein Bild mit diesem Namen ist bereits vorhanden. Soll das Bild überschrieben werden? Das Template muss eventuell neu geladen werden.",
          "error": "Fehler beim Hochladen des Bildes.",
          "upload": "Neues Bild hochladen"
        },
        "useLink": "Externen Link verwenden",
        "select": "Bild auswählen"
      },
      "testDelivery": "Testversand",
      "toggleHelpingGridOff": "Hilfslinien ausblenden",
      "toggleHelpingGridOn": "Hilfslinien anzeigen"
    },
    "element": {
      "alignment": "Element Ausrichtung",
      "background": "Hintergrund",
      "backgroundColor": "Hintergrundfarbe",
      "backgroundImageUrl": "Hintergrundbild URL",
      "border": "Umrandung",
      "borderColor": "Farbe",
      "borderNone": "keine",
      "borderRadius": "Abgerundete Ecken",
      "bottom": "Unten",
      "boundary": "Element Begrenzung",
      "content": "Inhalt",
      "copy": "kopieren",
      "dashed": "Gestrichelt",
      "degrees": "Grad",
      "delete": "löschen",
      "dotted": "Gepunktet",
      "font": "Schrift",
      "fontBold": "fett",
      "fontColor": "Schriftfarbe",
      "fontFamily": "Schriftart",
      "fontItalic": "kursiv",
      "fontSize": "Schriftgröße",
      "fontStyle": "Schriftstil",
      "fontWeight": "Schriftstärke",
      "fullWidth": "Ganze Breite",
      "groupedColumns": "Auf Mobilgerät in einer Reihe anzeigen",
      "height": "Höhe",
      "hide": "ausblenden",
      "hideOnMobile": "Auf Mobilgerät ausblenden",
      "horiz": "horiz.",
      "horizontal": "horizontal",
      "icons-size": "Icons Größe",
      "imageAltText": "Alternativer Text",
      "imageUrl": "Bild URL",
      "innerPadding": "Innenabstand",
      "layer": "Ebene",
      "left": "Links",
      "mobile": "Mobil",
      "name": "Elementname",
      "opacity": "Deckkraft",
      "padding": "Abstand",
      "position": "Position",
      "positionInfo": "obere linke Ecke des Elements",
      "radius": "Rundung",
      "right": "Rechts",
      "rotation": "Rotation",
      "scaleProportionally": "proportional skalieren",
      "show": "einblenden",
      "size": "Größe",
      "solid": "Durchgezogen",
      "style": "Stil",
      "text": "Text",
      "textAlign": "Textausrichtung",
      "textAlignBottom": "unten ausrichten",
      "textAlignCenter": "mittig ausrichten",
      "textAlignJustify": "füllen",
      "textAlignLeft": "links ausrichten",
      "textAlignRight": "rechts ausrichten",
      "textAlignTop": "oben ausrichten",
      "textFormat": "Textformatierung",
      "textLineSpacing": "Zeilenabstand",
      "textTransformLowerCase": "Kleinbuchstaben",
      "textTransformNone": "normal",
      "textTransformUpperCase": "Großbuchstaben",
      "textUnderline": "unterstrichen",
      "textWordBreak": "Zeilenumbruch",
      "textWordBreakBreakAll": "alles",
      "textWordBreakBreakWord": "wortweise",
      "textWordBreakKeepAll": "keine",
      "textWordBreakNormal": "normal",
      "top": "Oben",
      "url": "URL",
      "vert": "vert.",
      "vertical": "vertikal",
      "visibility": "Sichtbarkeit",
      "width": "Breite",
      "widthUnitSelection": "Einheit von Breite wechseln",
      "zIndex": "Ebene"
    },
    "enterName": "Template Bezeichnung eingeben",
    "enterTextHint": "Ausschließlich Buchstaben und Zahlen verwenden",
    "field": {
      "add": "Feld für Vorlage hinzufügen",
      "delete": "Feld löschen",
      "deleteConfirm": "Bist du sicher, dass du das Feld \"{{key}}\" in der Sprache \"{{lang}}\" löschen willst?",
      "key": "Key für Feld",
      "value": "Text"
    },
    "fields": "Eigene Felder für Gutscheinvorlage",
    "help": "Hilfe",
    "layerOverview": "Ebenen",
    "image": {
      "edit": "Bild bearbeiten",
      "name": "Bildname",
      "noImagesFound": "Keine Bilder gefunden",
      "select": "Bild auswählen",
      "source": "Bildquelle"
    },
    "fields": "Eigene Felder für Gutscheinvorlage",
    "image": {
      "select": "Bild auswählen",
      "source": "Bildquelle",
      "noImagesFound": "Keine Bilder gefunden"
    },
    "help": "Hilfe",
    "layerOverview": "Ebenen",
    "message": {
      "createError": "Template konnte nicht erstellt werden",
      "createSuccess": "Template erfolgreich erstellt",
      "doesNotExistError": "Template existiert nicht",
      "invalidNameError": "Template konnte nicht erstellt werden. Bitte geben Sie eine gültige Bezeichnung ein.",
      "loadingError": "Templates konnten nicht geladen werden",
      "saveNoChanges": "Template weist keine Änderungen auf",
      "saveSuccess": "Template erfolgreich gespeichert",
      "saveNoSuccess": "Template konnte nicht gespeichert werden",
      "testDelivery": {
        "error": "Testversand fehlgeschlagen",
        "success": "Testversand erfolgreich"
      }
    },
    "name": "Templatebezeichnung",
    "nameExample": "z.B. Buchungsbestätigung",
    "objectSettings": "Objekt",
    "rename": "Template umbenennen",
    "save": "Template speichern",
    "saveChanges": "Änderungen speichern",
    "select": "Tempalte auswählen",
    "settings": {
      "displayFooterOnFirstPage": "Fußzeile nur auf erster Seite anzeigen",
      "displayHeaderOnFirstPage": "Kopfzeile nur auf erster Seite anzeigen",
      "displayPageCounter": "Seitenzahl anzeigen",
      "footer": "Fußzeile",
      "header": "Kopfzeile",
      "hidePageCounter": "Seitenzahl ausblenden",
      "landscape": "Querformat",
      "marginBottom": "Rand unten",
      "marginLeft": "Rand links",
      "marginRight": "Rand rechts",
      "marginTop": "Rand oben",
      "margins": "Ränder",
      "orientation": "Ausrichtung",
      "page": "Seite",
      "pageLayout": "Seitenlayout",
      "portrait": "Hochformat",
      "save": "Einstellungen speichern",
      "settings": "Einstellungen"
    },
    "subject": "Betreff"
  },
  "unsavedChanges": "Ungespeicherte Änderungen gehen verloren",
  "unsavedChangesLost": "Wenn Sie fortfahren, gehen Ihre ungespeicherten Änderungen verloren."
}
