@if (ngControl) {
  <mat-form-field class="w-full" [class.hide-subscript]="!hasValidators && !hint">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <textarea
      [cdkTextareaAutosize]="autosize"
      [rows]="rows"
      matNativeControl
      matInput
      (blur)="handleInputBlur()"
      [formControl]="formControl"
      (ngModelChange)="onFieldChange($event)"
    ></textarea>

    @if (hasErrors) {
      <mat-error>
        {{ validationErrorMessage }}
      </mat-error>
    }

    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
  </mat-form-field>
}
