@if (ngControl) {
  <div class="relative">
    <input
      [placeholder]="placeholder"
      class="ws-simple-input w-full"
      [class]="{ 'ws-simple-input--with-icon': !!icon }"
      matInput
      [type]="type"
      [formControl]="formControl"
      (ngModelChange)="onFieldChange($event)"
    />

    @if (clearable && !!this.value) {
      <mat-icon class="ws-simple-input_icon cursor-pointer" (click)="clearInputValue()">close</mat-icon>
    } @else if (speechRecognition && speechRecognitionService.speechRecognitionSupported) {
      @if (speechRecognition) {
        <mat-icon
          class="cursor-pointer ws-simple-input_icon ws-input_speech-recognition-icon"
          [ngClass]="{ recording: listening }"
          (click)="toggleSpeechRecognition()"
          (touchstart)="touchStartSpeechRecognition()"
          (touchend)="touchStopSpeechRecognition()"
          >mic
        </mat-icon>
      }
    } @else {
      <mat-icon class="ws-simple-input_icon">{{ icon }}</mat-icon>
    }

    @if (hasValidators && hasErrors) {
      <mat-error class="ws-simple-input_errors">
        {{ validationErrorMessage }}
      </mat-error>
    }
  </div>
}
