import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { WsFileUploadService } from '../ws-file-upload.service'
import { AllowedFileTypes } from '../../../types/forms/allowed-file-types'
import { AllowedFileType } from '../../../types/forms/allowed-file-type'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'

@Component({
  selector: 'ws-advanced-file-upload',
  templateUrl: './advanced-file-upload.component.html',
  styleUrl: './advanced-file-upload.component.scss'
})
/**
 * The advanced file upload component allows the user to select or drag&drop a file or multiple files and restricts the file types that can be selected.
 */
export class AdvancedFileUploadComponent implements OnInit {
  @Input() public multiple = false
  @Input() public sortingAllowed = false
  @Input() public previewSize = 50
  /**
   * Max file size to be uploaded in MB.
   */
  @Input() public maxFileSize = 8
  @Input() public fileUrl = ''
  @Input() public fileUrls: string[] = []
  @Input() public allowedTypes: string[] = ['image/*']
  @Input() public file: File | null = null
  @Input() files: File[] = []

  protected maxFileSizeBytes = 0
  userFriendlyFileTypes = ''
  allowedFileTypes: AllowedFileTypes = new AllowedFileTypes([], '')

  @Output() fileSelected = new EventEmitter<File | null>()
  @Output() filesSelected = new EventEmitter<File[]>()
  @Output() fileSelectionError = new EventEmitter<string>()

  @ViewChild('fileUploadDropZone') fileUploadDropZone?: ElementRef

  public translate: TranslateService = inject(TranslateService)
  public fileUploadService: WsFileUploadService = inject(WsFileUploadService)

  constructor(@Inject('PlatformService') public platformService: AbstractPlatformService) {}

  /**
   * Initializes the component by setting the allowed file types array and the accepted file types string.
   * If a file URL is given, the file object is created from the URL.
   */
  ngOnInit() {
    this.allowedFileTypes = this.fileUploadService.restrictFileTypes(this.allowedTypes)
    // convert the max file size from MB to bytes
    this.maxFileSizeBytes = this.maxFileSize * 1024 * 1024
    this.setUserFriendlyFileTypes()
    // if a file URL is given (either single or array), create a file object from the URL
    if (this.fileUrl) {
      this.createFile(this.fileUrl).then((file: File) => {
        this.file = file
      })
    }
    if (this.fileUrls.length) {
      this.fileUrls.forEach((url: string) => {
        this.createFile(url).then((file: File) => {
          this.files.push(file)
        })
      })
    }
  }

  /**
   * Handles the input click event and resets the value of the input field to allow the user to select the same file again.
   *
   * @param event The event containing the selected file(s)
   */
  onInputClick(event: MouseEvent) {
    if (event.target instanceof HTMLInputElement) event.target.value = ''
  }

  /**
   * Handles the file selection event and emits the file or files if they are allowed.
   *
   * @param event The event containing the selected file(s)
   */
  async onFileSelected(event: DragEvent | Event): Promise<void> {
    let files: FileList | null = null
    if ('dataTransfer' in event) {
      files = event.dataTransfer?.files ?? null
    }
    if (files === null && event.target instanceof HTMLInputElement) {
      files = event.target.files ?? null
    }
    if (!files || !files.length) {
      this.fileSelectionError.emit(this.translate.instant('ws.forms.files.noFilesSelected'))
      return
    }
    if (this.allowedFileTypes.allowedTypes.length === 0) {
      this.fileSelectionError.emit(this.translate.instant('ws.forms.file.noAllowedTypesGiven'))
      return
    }
    let fileTypeAllowed = false
    let fileSizeAllowed = false
    let fileSelectionErrorShouldBeEmitted = ''
    for (let i = 0; i < files.length; i++) {
      const file: File | null = files.item(i)
      if (!file) continue
      fileSizeAllowed = file.size <= this.maxFileSizeBytes
      fileTypeAllowed = this.allowedFileTypes.allowedTypes.some((allowedFileType: AllowedFileType) => {
        if (allowedFileType.type === '*') return true
        return allowedFileType.exact
          ? file.type === allowedFileType.type
          : file.type.split('/')[0] === allowedFileType.type.split('/')[0]
      })
      if (fileTypeAllowed && fileSizeAllowed) {
        if (!this.multiple) {
          this.file = file
        } else {
          this.files.push(file)
        }
      } else {
        fileSelectionErrorShouldBeEmitted = fileTypeAllowed
          ? this.translate.instant('ws.forms.file.sizeNotAllowed', {
              maxFileSize: this.maxFileSize
            })
          : this.translate.instant('ws.forms.file.typeNotAllowed', {
              allowedTypes: this.userFriendlyFileTypes
            })
      }
    }
    if (this.file) {
      this.emitFile(this.file)
    } else if (this.files.length) {
      this.emitFiles(this.files)
    }
    if (fileSelectionErrorShouldBeEmitted) {
      this.fileSelectionError.emit(fileSelectionErrorShouldBeEmitted)
    }
  }

  /**
   * Removes the file from the component and emits the files array.
   */
  removeFromFiles(index: number) {
    if (this.multiple) {
      this.files.splice(index, 1)
      this.emitFiles(this.files)
    } else {
      this.file = null
      this.emitFile(this.file)
    }
  }

  /**
   * Emits the file or null if the file has been removed.
   */
  emitFile(file: File | null) {
    this.fileSelected.emit(file)
  }

  /**
   * Emits the files, a single file or an empty array if all files have been removed.
   */
  emitFiles(files: File[]) {
    this.filesSelected.emit(files)
  }

  /**
   * Creates a file object from a given URL.
   */
  async createFile(url: string): Promise<File> {
    const fileEnding = url.split('.').pop() || ''
    const fileName = url.split('/').pop() || ''
    const response: Response = await fetch(url)
    const data: Blob = await response.blob()
    const metadata: { type: string } = {
      type: 'image/' + fileEnding
    }
    return new File([data], fileName, metadata)
  }

  /**
   * Sets the user-friendly file types string based on given accepted file types.
   */
  public setUserFriendlyFileTypes() {
    const userFriendlyFileTypesToJoin: string[] = []
    this.allowedFileTypes.allowedTypes.forEach((allowedFileType: AllowedFileType) => {
      if (allowedFileType.fileEndings.length) {
        userFriendlyFileTypesToJoin.push(...allowedFileType.fileEndings)
      } else {
        switch (allowedFileType.type) {
          case '*':
            userFriendlyFileTypesToJoin.push(this.translate.instant('ws.forms.file.allFiles'))
            break
          case 'image/*':
            userFriendlyFileTypesToJoin.push(this.translate.instant('ws.forms.file.allImages'))
            break
          case 'application/*':
            userFriendlyFileTypesToJoin.push(this.translate.instant('ws.forms.file.allFiles'))
            break
          case 'video/*':
            userFriendlyFileTypesToJoin.push(this.translate.instant('ws.forms.file.allVideos'))
            break
          default:
            userFriendlyFileTypesToJoin.push(allowedFileType.type)
        }
      }
    })
    this.userFriendlyFileTypes = userFriendlyFileTypesToJoin.join(', ')
  }

  /**
   * Handles the drag over event and adds the drag-over class to the drop zone for animation/styling purposes.
   */
  @HostListener('dragover', ['$event']) ondragover() {
    this.fileUploadDropZone?.nativeElement.classList.add('drag-over')
  }

  /**
   * Handles the drag leave event and removes the drag-over class from the drop zone to stop the animation.
   */
  @HostListener('dragleave', ['$event']) ondragleave() {
    this.fileUploadDropZone?.nativeElement.classList.remove('drag-over')
  }

  /**
   * Handles the drag over event and calls to handle the selected file(s).
   */
  @HostListener('drop', ['$event']) ondrop(event: DragEvent) {
    this.fileUploadDropZone?.nativeElement.classList.remove('drag-over')
    event.preventDefault()
    event.stopPropagation()
    this.onFileSelected(event)
  }
}
