import { Component, effect, inject, input, InputSignal, output } from '@angular/core'
import { Brick } from '../../../data/models/brick'
import { Section } from '../../../data/models/section'
import { Column } from '../../../data/models/column'
import { NgClass } from '@angular/common'
import { DialogComponent, WsDialogService } from '@ws-core/core-library'
import { TranslateService } from '@ngx-translate/core'
import {
  ColumnGrid,
  ColumnGridTypes,
  CUSTOM_COLUMN_GRID,
  FULL_COLUMN_GRID,
  FOUR_QUARTERS_COLUMN_GRID,
  HALF_HALF_COLUMN_GRID,
  THREE_THIRDS_COLUMN_GRID,
  THIRD_TWO_THIRDS_COLUMN_GRID,
  TWO_THIRDS_THIRD_COLUMN_GRID
} from '../../../data/types/column-grid'

@Component({
  selector: 'app-ws-email-column-grid',
  templateUrl: './ws-email-column-grid.component.html',
  styleUrl: './ws-email-column-grid.component.scss',
  imports: [NgClass],
  standalone: true
})
export class WsEmailColumnGridComponent {
  selectedRow: InputSignal<Section> = input.required<Section>()
  selectedRowChange = output<Section>()

  gridType: ColumnGrid = CUSTOM_COLUMN_GRID

  private readonly dialogService: WsDialogService = inject(WsDialogService)
  private readonly translate: TranslateService = inject(TranslateService)

  /**
   * Initializes the component.
   * effect function: is used instead of ngOnChanges
   * Sets the grid type based on the selected row.
   */
  constructor() {
    effect(() => {
      this.setGridType()
    })
  }

  setGridType() {
    this.gridType = this.getSelectedColumnGrid()
  }

  /**
   * Returns the selected column grid based selected row.
   * if no suitable column grid is found, it returns a custom column grid.
   * @returns ColumnGrid
   */
  getSelectedColumnGrid() {
    const columns: Brick[] = this.selectedRow().getChildren()
    if (columns.length <= 0) {
      CUSTOM_COLUMN_GRID.columns = 0
      CUSTOM_COLUMN_GRID.sizes = []
      return CUSTOM_COLUMN_GRID
    }
    switch (columns.length) {
      case 1:
        if (this.columnsHasAutoColumnWidth(columns) || columns[0].styles['width'] === '100%') return FULL_COLUMN_GRID
        break
      case 2:
        if (this.columnsHasAutoColumnWidth(columns)) {
          return HALF_HALF_COLUMN_GRID
        }
        if (columns[0].styles['width'] === '50%' && columns[1].styles['width'] === '50%') {
          return HALF_HALF_COLUMN_GRID
        }
        if (columns[0].styles['width'] === '67%' && columns[1].styles['width'] === '33%') {
          return TWO_THIRDS_THIRD_COLUMN_GRID
        }
        if (columns[1].styles['width'] === '67%' && columns[0].styles['width'] === '33%') {
          return THIRD_TWO_THIRDS_COLUMN_GRID
        }
        break

      case 3:
        if (this.columnsHasAutoColumnWidth(columns)) {
          return THREE_THIRDS_COLUMN_GRID
        }
        if (
          columns[0].styles['width'] === '33%' &&
          columns[1].styles['width'] === '33%' &&
          columns[2].styles['width'] === '33%'
        ) {
          return THREE_THIRDS_COLUMN_GRID
        }
        break

      case 4:
        if (this.columnsHasAutoColumnWidth(columns)) {
          return FOUR_QUARTERS_COLUMN_GRID
        }
        if (
          columns[0].styles['width'] === '25%' &&
          columns[1].styles['width'] === '25%' &&
          columns[2].styles['width'] === '25%' &&
          columns[3].styles['width'] === '25%'
        ) {
          return FOUR_QUARTERS_COLUMN_GRID
        }
        break
    }

    CUSTOM_COLUMN_GRID.sizes = columns
      .map((col) => col.styles['width']?.toString() ?? '')
      .filter((size) => Boolean(size))
    CUSTOM_COLUMN_GRID.columns = columns.length

    return CUSTOM_COLUMN_GRID
  }

  private columnsHasAutoColumnWidth(columns: Brick[]): boolean {
    return columns.some((col) => !col.styles['width'])
  }

  /**
   * Handles the column grid click event.
   * Calls the appropriate function based on the column grid type.
   */
  handleColumnGridClick(columnGrid: ColumnGrid) {
    if (columnGrid.columns >= this.gridType.columns) {
      this.handleColumnGridNoDecrease(columnGrid)
      this.updateNewColumnGrid(columnGrid)
      return
    }
    this.handleColumnGridDecrease(columnGrid)
  }

  /**
   * Updates the column grid based on the new column grid.
   * @param columnGrid
   * @private
   */
  private updateNewColumnGrid(columnGrid: ColumnGrid) {
    this.setNewColumnWidths(columnGrid)
    this.gridType = columnGrid
    this.selectedRowChange.emit(this.selectedRow())
  }

  /**
   * Handles the column grid no decrease.
   * Adds new columns to the selected row based on the new column grid.
   * @param gridType
   * @private
   */
  private handleColumnGridNoDecrease(gridType: ColumnGrid) {
    const amountOfNewColumns = gridType.columns - this.gridType.columns

    for (let i = 0; i < amountOfNewColumns; i++) {
      this.selectedRow().add(new Column())
    }
  }

  /**
   * Handles the column grid decrease.
   * Removes columns from the selected row based on the new column grid
   * if no content gets lost.
   * Otherwise, opens a dialog to confirm the removal of columns.
   * @param gridType
   * @private
   */
  private handleColumnGridDecrease(gridType: ColumnGrid) {
    const columns = this.selectedRow().getChildren()
    const amountOfColumnsToRemove = this.gridType.columns - gridType.columns

    if (this.hasChildrenInRemoveColumns(columns, amountOfColumnsToRemove)) {
      this.openConfirmRemoveColumnsDialog(amountOfColumnsToRemove, gridType)
      return
    }

    this.removeLastColumnsOfSelectedRow(amountOfColumnsToRemove, gridType)
  }

  /**
   * Sets the new column widths based on the new column grid.
   * @param newColumnGrid
   * @private
   */
  private setNewColumnWidths(newColumnGrid: ColumnGrid) {
    if (newColumnGrid.sizes.length === 0) {
      this.setColumnWidthsToAuto()
      return
    }
    this.updateColumnWidthsByGridSizes(newColumnGrid.sizes)
  }

  /**
   * Sets the column widths to auto.
   * @private
   */
  private setColumnWidthsToAuto() {
    this.selectedRow()
      .getChildren()
      .forEach((col) => {
        delete col.styles['width']
      })
  }

  /**
   * Updates the column widths based on the new column grid sizes.
   * @param newColumnGridSizes
   * @private
   */
  private updateColumnWidthsByGridSizes(newColumnGridSizes: string[]) {
    newColumnGridSizes.forEach((size, index) => {
      this.selectedRow().getChildren()[index].styles['width'] = size
    })
  }

  /**
   * Checks if the columns to remove have children.
   * @param columns
   * @param amountOfColumnsToRemove
   * @private
   */
  private hasChildrenInRemoveColumns(columns: Column[], amountOfColumnsToRemove: number): boolean {
    return columns.slice(-amountOfColumnsToRemove).some((col) => col.getChildren().length > 0)
  }

  /**
   * Removes the last columns of the selected row based on the amount of columns to remove.
   * @param amountOfColumnsToRemove
   * @param newGridType
   * @private
   */
  private removeLastColumnsOfSelectedRow(amountOfColumnsToRemove: number, newGridType: ColumnGrid) {
    this.selectedRow().setChildren(this.selectedRow().getChildren().slice(0, -amountOfColumnsToRemove))
    this.updateNewColumnGrid(newGridType)
  }

  /**
   * Opens a dialog to confirm the removal of columns.
   */
  private openConfirmRemoveColumnsDialog(amountOfColumnsToRemove: number, newGridType: ColumnGrid) {
    const translationLabel =
      amountOfColumnsToRemove == 1 ? 'element.confirmRemoveColumn' : 'element.confirmRemoveColumns'
    const dialog = this.dialogService.open({
      dialogTitleText: translationLabel + 'Title',
      dialogContentText: this.translate.instant(translationLabel, { columns: amountOfColumnsToRemove }),
      dialogActions: [
        { label: 'back', action: 'back', buttonType: 'secondary' },
        { label: 'confirm', action: 'confirm', buttonType: 'primary' }
      ]
    })

    this.subscribeToDialog(dialog, () => this.removeLastColumnsOfSelectedRow(amountOfColumnsToRemove, newGridType))
  }

  /**
   * Subscribes to the dialog after close event.
   * @param dialog
   * @param functionToExecute
   * @private
   */
  private subscribeToDialog(dialog: DialogComponent, functionToExecute: () => void) {
    dialog.afterClose$.subscribe((action: string) => {
      if (action === 'confirm') {
        functionToExecute()
      }
    })
  }

  protected readonly ColumnGridTypes = ColumnGridTypes

  protected readonly FOUR_QUARTERS_COLUMN_GRID = FOUR_QUARTERS_COLUMN_GRID
  protected readonly THREE_THIRDS_COLUMN_GRID = THREE_THIRDS_COLUMN_GRID
  protected readonly TWO_THIRDS_THIRD_COLUMN_GRID = TWO_THIRDS_THIRD_COLUMN_GRID
  protected readonly THIRD_TWO_THIRDS_COLUMN_GRID = THIRD_TWO_THIRDS_COLUMN_GRID
  protected readonly HALF_HALF_COLUMN_GRID = HALF_HALF_COLUMN_GRID
  protected readonly FULL_COLUMN_GRID = FULL_COLUMN_GRID
}
