@if (label) {
  <mat-label>{{ label }}</mat-label>
}
<div>
  @for (value of inputValues; track $index) {
    <mat-chip class="mr-2 mb-2">
      {{ value }}
      <button matChipRemove (click)="removeFromInputValues($index)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip>
  }
</div>
<ws-form-input
  [options]="options"
  [type]="type"
  [icon]="icon"
  (iconClick)="addToInputValues(inputValue)"
  #inputComponent
  ngDefaultControl
  [placeholder]="placeholder"
  [(ngModel)]="inputValue"
  (keydown.enter)="handleInputKeyDown($event)"
  (keydown.tab)="handleInputKeyDown($event)"
  (focusout)="checkForInputs()"
></ws-form-input>
@if (hasErrors) {
  <mat-error>{{ validationErrorMessage }}</mat-error>
}
