import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { EmailService } from '../services/email.service'

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private emailService: EmailService) {
    this.setApiBaseUrl()
  }

  apiBaseUrl: string = 'https://localhost:8445'

  setApiBaseUrl() {
    const hostnameSplit = location.hostname.split('.')
    hostnameSplit.splice(0, 1)
    this.apiBaseUrl = location.protocol + '//' + hostnameSplit.join('.')
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('/api')) {
      // uses temporary api key that has been set in session storage from query param
      const tempApiKey = this.emailService.getTempApiKey()
      const userId = this.emailService.getUserId()
      request = request.clone({
        setHeaders: {
          'ws-email-api-key': tempApiKey,
          'ws-email-user': userId
        }
      })
    }

    return next.handle(request)
  }
}
