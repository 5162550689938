<div class="ws-email-column-grid text-gray-500 font-invisible">
  <!-- Row 1 -->
  <div class="column-grid__row">
    <div
      class="column-grid__column-container"
      [ngClass]="{ 'column-grid__selected-column-container': gridType.type === ColumnGridTypes.FULL }"
      (click)="handleColumnGridClick(FULL_COLUMN_GRID)"
    >
      <div class="column-grid__column w-full">100%</div>
    </div>
    <div
      class="column-grid__column-container"
      [ngClass]="{ 'column-grid__selected-column-container': gridType.type === ColumnGridTypes.HALF_HALF }"
      (click)="handleColumnGridClick(HALF_HALF_COLUMN_GRID)"
    >
      <div class="column-grid__column w-1/2">50%</div>
      <div class="column-grid__column w-1/2">50%</div>
    </div>
  </div>

  <!-- Row 2 -->
  <div class="column-grid__row">
    <div
      class="column-grid__column-container"
      [ngClass]="{ 'column-grid__selected-column-container': gridType.type === ColumnGridTypes.THIRD_TWO_THIRDS }"
      (click)="handleColumnGridClick(THIRD_TWO_THIRDS_COLUMN_GRID)"
    >
      <div class="column-grid__column w-1/3">33%</div>
      <div class="column-grid__column w-2/3">67%</div>
    </div>
    <div
      class="column-grid__column-container"
      [ngClass]="{ 'column-grid__selected-column-container': gridType.type === ColumnGridTypes.TWO_THIRDS_THIRD }"
      (click)="handleColumnGridClick(TWO_THIRDS_THIRD_COLUMN_GRID)"
    >
      <div class="column-grid__column w-2/3">67%</div>
      <div class="column-grid__column w-1/3">33%</div>
    </div>
  </div>

  <!-- Row 3 -->
  <div class="column-grid__row">
    <div
      class="column-grid__column-container"
      [ngClass]="{ 'column-grid__selected-column-container': gridType.type === ColumnGridTypes.THREE_THIRDS }"
      (click)="handleColumnGridClick(THREE_THIRDS_COLUMN_GRID)"
    >
      <div class="column-grid__column w-1/3">33%</div>
      <div class="column-grid__column w-1/3">33%</div>
      <div class="column-grid__column w-1/3">33%</div>
    </div>
    <div
      class="column-grid__column-container"
      [ngClass]="{ 'column-grid__selected-column-container': gridType.type === ColumnGridTypes.FOUR_QUARTERS }"
      (click)="handleColumnGridClick(FOUR_QUARTERS_COLUMN_GRID)"
    >
      <div class="column-grid__column w-1/4">25%</div>
      <div class="column-grid__column w-1/4">25%</div>
      <div class="column-grid__column w-1/4">25%</div>
      <div class="column-grid__column w-1/4">25%</div>
    </div>
  </div>
</div>
