import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { EmailService } from '../../../services/email.service'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { WsNotificationsService } from '@ws-core/core-library'
import { Template } from '../../../data/models/template'
import { first, Subscription } from 'rxjs'
import { MjmlProcessorService } from '../../../services/util/mjml-processor.service'
import { WsEmailEditorViewComponent } from '../ws-email-editor-view/ws-email-editor-view.component'
import { WsEmailTemplate } from '../../../data/types/ws-email-template'

@Component({
  selector: 'app-ws-email-view',
  templateUrl: './ws-email-view.component.html',
  styleUrl: './ws-email-view.component.scss'
})
export class WsEmailViewComponent implements OnInit, OnDestroy {
  @ViewChild(WsEmailEditorViewComponent) wsEmailEditorViewComponent?: WsEmailEditorViewComponent | undefined
  wsEmailTemplate: WsEmailTemplate = new WsEmailTemplate()

  constructor(
    private emailService: EmailService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private wsNotificationsService: WsNotificationsService,
    private mjmlProcessorService: MjmlProcessorService
  ) {}

  ngOnInit(): void {
    this.templateSubscription = this.route.data.subscribe((data) => {
      const template = data['template']
      this.wsEmailTemplate = template
      this.template = this.mjmlProcessorService.generateTemplateFromMjml(
        template.mjml['de'],
        template.name,
        JSON.parse(template?.styles || '{}'),
        JSON.parse(template?.testData || '{}'),
        template.wsEmailTemplateId
      )
    })

    if (location.hostname !== 'localhost') {
      window.onbeforeunload = function (e) {
        const confirmationMessage =
          'It looks like you have been editing something. ' + 'If you leave before saving, your changes will be lost.'

        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
    }
  }

  ngOnDestroy(): void {
    window.onbeforeunload = null
  }

  template: Template = new Template('empty')
  savingTemplate = false
  private templateSubscription?: Subscription

  saveTemplate(wsEmailTemplate: WsEmailTemplate): void {
    this.savingTemplate = true
    this.emailService
      .updateEmailTemplate(wsEmailTemplate)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          this.handleResponse(200)
          this.wsEmailEditorViewComponent?.loadEmailTemplateHistory()
          this.savingTemplate = false
        },
        error: (err: any) => {
          this.handleResponse(500)
          this.savingTemplate = false
        }
      })
  }

  public handleResponse(status: number) {
    let messageSeverity: 'success' | 'error' = 'error'
    let messageDetail = 'template.message.'

    switch (status) {
      case 500:
        messageDetail += 'saveNoSuccess'
        break
      case 400:
        messageDetail += 'saveNoSuccess'
        break
      default:
        messageSeverity = 'success'
        messageDetail += 'saveSuccess'
    }

    this.wsNotificationsService.createNotification(this.translate.instant(messageDetail), {
      severity: messageSeverity
    })
  }
}
