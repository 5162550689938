import {
  Component,
  ElementRef,
  Inject,
  inject,
  Input,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { SelectOption } from '../../../types/forms/select-option'

import * as emojisJson from '../../../data/emoji.json'
import { WsDialogService } from '../../notifications/ws-dialog-service'
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms'
import { FieldComponent } from '../field/field.component'
import { TextEditorHistoryEntry } from '../../../types/forms/text-editor-history-entry'
import { InputComponent } from '../input/input.component'
import { DialogComponent } from '../../notifications/dialog/dialog.component'
import { CustomStyleSet } from '../../../types/forms/custom-style-set'
import { ColorPickerComponent } from '../color-picker/color-picker.component'
import { FormattingSelectOption } from '../../../types/forms/formatting-select-option'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'

/**
 * The text editor is a simple text editor which can be used to format text.
 * It is based on the contenteditable div and uses the execCommand function to format the text.
 * The text editor supports the following formatting options:
 * - bold
 * - italic
 * - underline
 * - strike through
 * - justify (left, center, right, full)
 * - headings (h1, h2, h3, h4, h5, h6)
 * - links
 * - insert emojis
 *
 * The text editor also supports a history for undo and redo.
 *
 * It will return the html string of the contenteditable div.
 *
 * @example
 * <ws-form-text-editor [formControl]="formControl"></ws-form-text-editor>
 */
@Component({
  selector: 'ws-form-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent extends FieldComponent implements OnInit {
  @Input() customStyleSets: CustomStyleSet[] = []

  customStyleSetClassesMap = new Map<string, string[]>()
  customStyleSetStylesMap = new Map<string, string[]>()

  @Input() formattingOptions: FormattingSelectOption[] = [
    { label: 'ws.forms.textEditor.formattingOption.p', value: 'P' },
    { label: 'ws.forms.textEditor.formattingOption.h1', value: 'H1' },
    { label: 'ws.forms.textEditor.formattingOption.h2', value: 'H2' },
    { label: 'ws.forms.textEditor.formattingOption.h3', value: 'H3' },
    { label: 'ws.forms.textEditor.formattingOption.h4', value: 'H4' },
    { label: 'ws.forms.textEditor.formattingOption.h5', value: 'H5' },
    { label: 'ws.forms.textEditor.formattingOption.h6', value: 'H6' }
  ]
  formattingTypeOfSelection: FormattingSelectOption = {
    label: 'ws.forms.textEditor.formattingOption.p',
    value: 'P'
  }

  @Input() showFormattingOptions = true
  @Input() showColorFormattingOptions = true
  @Input() showTextFormattingOptions = true
  @Input() showAlignmentFormattingOptions = true
  @Input() showListFormattingOptions = true
  @Input() showLinkOptions = true
  @Input() showSeparatorInsert = true
  @Input() showEmojiInsert = true

  URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/

  linkForm = new FormGroup({
    url: new FormControl('', [Validators.required, Validators.pattern(this.URL_REGEXP)]),
    blank: new FormControl(true, [])
  })

  linkDialog: DialogComponent | undefined
  activeButtonsMap = new Map<string, boolean>()
  contentHistory: TextEditorHistoryEntry[] = []
  restoredHistoryEntries: TextEditorHistoryEntry[] = []
  emojiMap = new Map<string, string[]>()
  plainHTMLMode = false
  htmlString = ''
  cursorNode: Node | null | undefined
  selectedTextColor = '#000000'

  public override value = '<p><br></p>'

  @ViewChild('wsTextEditorContent') wsTextEditorContent!: ElementRef
  @ViewChild('addLinkDialogContent') addLinkDialogContent!: TemplateRef<any>
  @ViewChild('linkDialogInput') linkDialogInput!: InputComponent
  @ViewChild('addImageDialogContent', { static: true }) addImageDialogContent!: TemplateRef<Element>
  @ViewChild('textColorPicker') textColorPicker!: ColorPickerComponent

  private dialogService: WsDialogService = inject(WsDialogService)

  constructor(
    @Self()
    @Optional()
    public override ngControl: NgControl,
    @Inject('PlatformService') public override platformService: AbstractPlatformService
  ) {
    super(ngControl, platformService)
  }
  ngOnInit() {
    this.mapEmojis()
    this.mapCustomStyleSets()

    setTimeout(() => {
      this.generateHTMLString()
      this.wsTextEditorContent.nativeElement.innerHTML = this.sanitizeHtml(this.htmlString)

      this.textColorPicker.colorPickerMenuTrigger?.menuClosed.subscribe(() => {
        this.applyTextColor()
      })
    }, 100)
  }

  /**
   * Maps the emojis from the emojis.json file to the emojiMap. The emojis are mapped by their category.
   * All emojis are stored in a json file with a key for the category and the emoji itself.
   */
  mapEmojis() {
    for (const [key, value] of Object.entries(emojisJson)) {
      if (this.emojiMap.has(value.category)) {
        this.emojiMap.get(value.category)?.push(value.emoji)
      } else {
        if (value.category) {
          this.emojiMap.set(value.category, [value.emoji])
        }
      }
    }
    this.emojiMap.delete('undefined')
  }

  /**
   * Map custom style sets.
   *
   * @returns {void} - This method does not return a value.
   */
  mapCustomStyleSets() {
    for (const customStyleSet of this.customStyleSets) {
      this.customStyleSetClassesMap.set(customStyleSet.name, customStyleSet.class)
      this.customStyleSetStylesMap.set(customStyleSet.name, customStyleSet.style)
    }
  }

  /**
   * Inserts the selected emoji into the text editor. The emoji is inserted at the current cursor position.
   * @param event {MouseEvent} The click event.
   * @param emoji {string} The emoji to insert.
   */
  insertEmoji(emoji: string) {
    this.saveVersion()

    document.execCommand('insertText', false, emoji)
  }

  /**
   * Inserts a new paragraph at the current cursor position.
   * Some browsers insert a div instead of a paragraph. This functions checks if the current cursor position is in a list.
   * Everything which is not in a list is formatted as a paragraph.
   * @param event {Event} The keydown event.
   */
  insertParagraph(event: Event) {
    event.preventDefault()
    this.saveVersion()

    // Check if the current cursor position is in a list
    if (this.isInList(this.getSelection().anchorNode)) {
      // The current cursor position is in a list
      const currentListItemNode = this.findCurrentListItemNode(this.getSelection().anchorNode)

      // Remove the list if the current list item is empty (double Enter press)
      if (
        this.getSelection().anchorNode?.nodeName === 'LI' &&
        (this.getSelection().anchorNode?.textContent?.length || 0) === 0
      ) {
        const currentListRootNode = this.findCurrentListNode(currentListItemNode)
        this.removeList(new MouseEvent('empty'), currentListRootNode?.nodeName.toLowerCase())
        return
      }

      if (this.getSelection().anchorOffset === (this.getSelection().anchorNode?.textContent?.length || 0)) {
        this.insertNewListItemAfterNode(currentListItemNode)
      } else if (this.getSelection().anchorOffset === 0) {
        this.insertNewListItemBeforeNode(currentListItemNode)
      } else {
        this.splitListItemToNewLine(currentListItemNode)
      }
    } else {
      // The current cursor position is NOT in a list
      const currentParagraphNode = this.findCurrentParagraphNode(this.getSelection().anchorNode)

      if (this.getSelection().anchorOffset === (this.getSelection().anchorNode?.textContent?.length || 0)) {
        this.insertNewParagraphAfterNode(currentParagraphNode)
      } else if (this.getSelection().anchorOffset === 0) {
        this.insertNewParagraphBeforeNode(currentParagraphNode)
      } else {
        this.splitParagraphToNewLine(currentParagraphNode)
      }
    }

    this.updateValue()
  }

  /**
   * Splits the text content of a list item (<li>) at the current cursor position,
   * creating a new list item with the text after the cursor.
   *
   * @param {Node} currentListItemNode - The current list item node to be split.
   */
  splitListItemToNewLine(currentListItemNode: Node) {
    const textNode = currentListItemNode as Text
    const text = textNode.textContent || ''
    const textBefore = text.substring(0, this.getSelection().anchorOffset)
    const textAfter = text.substring(this.getSelection().anchorOffset)

    textNode.textContent = textBefore

    const listItem = document.createElement('li')
    listItem.textContent = textAfter
    textNode.parentNode?.insertBefore(listItem, textNode.nextSibling)

    const range = document.createRange()
    const selection = this.getSelection()

    const startPosition = this.findNodeInTree(listItem)

    if (startPosition) {
      range.setStart(startPosition, 0)
      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }

  /**
   * Inserts a new empty list item (<li>) immediately after the given list item node.
   *
   * @param {Node} currentListItemNode - The list item node after which a new list item will be inserted.
   */
  insertNewListItemAfterNode(currentListItemNode: Node) {
    this.insertNewListItem(currentListItemNode, false)
  }

  /**
   * Inserts a new empty list item (<li>) immediately before the given list item node.
   *
   * @param {Node} currentListItemNode - The list item node before which a new list item will be inserted.
   */
  insertNewListItemBeforeNode(currentListItemNode: Node) {
    this.insertNewListItem(currentListItemNode, true)
  }

  /**
   * Inserts a new empty list item (<li>) relative to the given list item node.
   *
   * @param {Node} currentListItemNode - The reference list item node for insertion.
   * @param {boolean} [prepend=false] - If true, inserts the new list item before the reference node; otherwise, inserts it after.
   */
  insertNewListItem(currentListItemNode: Node, prepend: boolean = false) {
    const currentListNode = this.findCurrentListNode(currentListItemNode)

    const listItem = document.createElement('li')
    const br = document.createElement('br')
    listItem.appendChild(br)

    const range = document.createRange()
    const selection = this.getSelection()

    let startPosition: Node | null
    if (prepend) {
      currentListNode.insertBefore(listItem, currentListItemNode)
      startPosition = currentListItemNode
    } else {
      currentListNode.insertBefore(listItem, currentListItemNode.nextSibling)
      startPosition = currentListItemNode.nextSibling
    }

    if (startPosition) {
      range.setStart(startPosition, 0)
      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }

  /**
   * Splits the text content of a paragraph (<p>) at the current cursor position,
   * creating a new paragraph with the text after the cursor.
   *
   * @param {Node} node - The text node within the paragraph to be split.
   */
  splitParagraphToNewLine(node: Node) {
    const textNode = node as Text
    const text = textNode.textContent || ''
    const textBefore = text.substring(0, this.getSelection().anchorOffset)
    const textAfter = text.substring(this.getSelection().anchorOffset)

    textNode.textContent = textBefore

    const paragraph = document.createElement('p')
    paragraph.textContent = textAfter
    textNode.parentNode?.insertBefore(paragraph, textNode.nextSibling)

    const range = document.createRange()
    const selection = this.getSelection()

    const startPosition = this.findNodeInTree(paragraph)

    if (startPosition) {
      range.setStart(startPosition, 0)
      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }

  /**
   * Inserts a new empty paragraph (<p>) relative to the given node.
   *
   * @param {Node} node - The reference node for insertion.
   * @param {boolean} [prepend=false] - If true, inserts the new paragraph before the reference node; otherwise, inserts it after.
   */
  insertNewParagraph(node: Node, prepend: boolean = false) {
    const paragraph = document.createElement('p')
    const br = document.createElement('br')
    paragraph.appendChild(br)

    const range = document.createRange()
    const selection = this.getSelection()

    let startPosition: Node | null
    if (prepend) {
      node.parentNode?.insertBefore(paragraph, node)
      startPosition = node
    } else {
      node.parentNode?.insertBefore(paragraph, node.nextSibling)
      startPosition = node.nextSibling
    }

    if (startPosition) {
      range.setStart(startPosition, 0)
      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }

  /**
   * Inserts a new empty paragraph (<p>) immediately after the given node.
   *
   * @param {Node} node - The node after which a new paragraph will be inserted.
   */
  insertNewParagraphAfterNode(node: Node) {
    this.insertNewParagraph(node, false)
  }

  /**
   * Inserts a new paragraph before the specified node.
   * @param {Node} node - The current node before which the new paragraph will be inserted.
   */
  insertNewParagraphBeforeNode(node: Node) {
    this.insertNewParagraph(node, true)
  }

  /**
   * Inserts a horizontal line and a new paragraph below the current paragraph.
   *
   * @param {Event} event - The event that triggered the insertion.
   */
  insertHorizontalLine(event: Event) {
    event.preventDefault()
    this.restoredHistoryEntries = []
    this.saveVersion()

    const currentParagraphNode = this.findCurrentParagraphNode(this.getSelection().anchorNode)

    const hr = document.createElement('hr')
    currentParagraphNode.parentNode?.insertBefore(hr, currentParagraphNode.nextSibling)

    const paragraph = document.createElement('p')
    const br = document.createElement('br')
    paragraph.appendChild(br)
    currentParagraphNode.parentNode?.insertBefore(paragraph, hr.nextSibling)

    const range = document.createRange()
    const selection = this.getSelection()

    const startPosition = this.findNodeInTree(paragraph)

    if (startPosition) {
      range.setStart(startPosition, 0)
      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }

    this.updateValue()
    this.selectionChanged()
  }

  /**
   * Pasts the text from the clipboard into the text editor.
   * Removes all formatting from the pasted text.
   * @param event {ClipboardEvent} The paste event.
   */
  pasteText(event: ClipboardEvent) {
    event.preventDefault()
    this.restoredHistoryEntries = []
    this.saveVersion()

    const text = event?.clipboardData?.getData('text/plain') || ''

    // if the
    if (new RegExp(this.URL_REGEXP).test(text) && this.getSelection().toString() !== '') {
      const selection = this.getSelection()
      const node = this.getSelection().anchorNode
      const range = document.createRange()

      if (node) {
        range.setStart(node, selection?.anchorOffset || 0)
        range.setEnd(node, selection?.focusOffset || 0)
        document.getSelection()?.removeAllRanges()
        document.getSelection()?.addRange(range)
        document.execCommand('createLink', false, text)

        const link = this.getSelection().anchorNode?.parentNode as HTMLElement
        link.setAttribute('target', '_blank')

        document.getSelection()?.removeAllRanges()
        document.getSelection()?.addRange(range)

        this.updateValue()
      }
    } else {
      document.execCommand('insertHTML', false, text)
    }
  }

  /**
   * Selects the text formatting type for the selected text from the dropdown.
   * @param type {SelectOption} The selected text formatting type.
   */
  selectTextFormattingType(type: SelectOption) {
    document.execCommand('formatBlock', false, type.value)

    this.formattingTypeOfSelection = type
    this.updateValue()
  }

  /**
   * Sets the custom style for the selected text.
   *
   * @param {CustomStyleSet} customStyle - The custom style to be applied.
   */
  selectCustomStyleSet(customStyle: CustomStyleSet) {
    const selectedText = this.getSelection().toString()

    if (selectedText === '') {
      return
    }

    const range = this.getSelection().getRangeAt(0)

    const span = document.createElement('span')
    span.innerText = selectedText || ''

    if (this.customStyleSetClassesMap.has(customStyle.name)) {
      span.classList.add(...(this.customStyleSetClassesMap.get(customStyle.name) || []))
    }

    if (this.customStyleSetStylesMap.has(customStyle.name)) {
      span.setAttribute('style', (this.customStyleSetStylesMap.get(customStyle.name) || []).join(';'))
    }

    if (range && range?.toString() !== '') {
      range.deleteContents()
      range.insertNode(span)
    }

    this.removeEmptySpanNodes(this.getSelection().anchorNode?.parentNode)
    this.updateValue()
    this.selectionChanged()
  }

  /**
   * Removes custom styling from selected text or node.
   * @return {void}
   */
  removeCustomStyle() {
    const spansToRemove: HTMLElement[] = []
    const selectedNodes = this.getSelection().getRangeAt(0).cloneContents().childNodes

    if (selectedNodes.length > 0) {
      for (const node of Array.from(selectedNodes)) {
        if (node.nodeName !== '#text') {
          spansToRemove.push(this.findSpanNodeParent(node as HTMLElement))
        }
      }
    } else {
      const selectedNode = this.getSelection().anchorNode as HTMLElement

      spansToRemove.push(this.findSpanNodeParent(selectedNode))
    }

    let cursorNode = undefined

    for (const span of spansToRemove) {
      this.findNodeInTree(span)
      const textNode = document.createTextNode(span.textContent || '')

      const nodeToReplace = this.findNodeInTree(span)

      if (nodeToReplace) {
        nodeToReplace.parentNode?.replaceChild(textNode, nodeToReplace)
        cursorNode = textNode
      }
    }

    this.updateValue()
    this.checkFormat(this.getSelection().anchorNode)
    this.activeButtonsMap.set('span', false)

    if (cursorNode) {
      this.setCursorPosition(cursorNode, cursorNode.textContent?.length || 0)
    }
  }

  /**
   * Executes the given command on the selected text. If no text is selected, the command has no effect.
   * If the command is executed on the selected text, the button for the command is set to active.
   * @param event {MouseEvent | KeyboardEvent} The click event.
   * @param command {string} The command to execute.
   */
  execCommand(event: MouseEvent | KeyboardEvent, command: string) {
    event.preventDefault()
    this.restoredHistoryEntries = []
    this.saveVersion()

    document.execCommand(command, false)

    if (this.getSelection().toString() === '') {
      switch (command) {
        case 'bold':
          this.activeButtonsMap.set('bold', !this.activeButtonsMap.get('bold'))
          break
        case 'italic':
          this.activeButtonsMap.set('italic', !this.activeButtonsMap.get('italic'))
          break
        case 'underline':
          this.activeButtonsMap.set('underline', !this.activeButtonsMap.get('underline'))
          break
        case 'strikeThrough':
          this.activeButtonsMap.set('strikeThrough', !this.activeButtonsMap.get('strikeThrough'))
          break
        case 'justifyLeft':
          this.activeButtonsMap.set('justifyLeft', !this.activeButtonsMap.get('justifyLeft'))
          this.activeButtonsMap.set('justifyCenter', false)
          this.activeButtonsMap.set('justifyRight', false)
          this.activeButtonsMap.set('justifyFull', false)
          break
        case 'justifyCenter':
          this.activeButtonsMap.set('justifyCenter', !this.activeButtonsMap.get('justifyCenter'))
          this.activeButtonsMap.set('justifyLeft', false)
          this.activeButtonsMap.set('justifyRight', false)
          this.activeButtonsMap.set('justifyFull', false)
          break
        case 'justifyRight':
          this.activeButtonsMap.set('justifyRight', !this.activeButtonsMap.get('justifyRight'))
          this.activeButtonsMap.set('justifyLeft', false)
          this.activeButtonsMap.set('justifyCenter', false)
          this.activeButtonsMap.set('justifyFull', false)
          break
        case 'justifyFull':
          this.activeButtonsMap.set('justifyFull', !this.activeButtonsMap.get('justifyFull'))
          this.activeButtonsMap.set('justifyLeft', false)
          this.activeButtonsMap.set('justifyCenter', false)
          this.activeButtonsMap.set('justifyRight', false)
          break
        case 'insertUnorderedList':
          this.activeButtonsMap.set('orderedList', false)
          break
        case 'insertOrderedList':
          this.activeButtonsMap.set('unorderedList', false)
          break
      }
    } else {
      this.resetActiveButtons()
    }

    this.checkFormat(this.getSelection().anchorNode)
  }

  /**
   * Inserts a list (unordered or ordered) at the current cursor position.
   *
   * @param {MouseEvent} event - The mouse event that triggered the list insertion.
   * @param {string} listType - The type of list to insert. Possible values: "ul" (unordered list) or "ol" (ordered list).
   *
   * @return {void}
   */
  insertList(event: MouseEvent, listType: string) {
    event.preventDefault()
    this.restoredHistoryEntries = []
    this.saveVersion()

    if (listType === 'ul') {
      this.activeButtonsMap.set('unorderedList', true)
      this.activeButtonsMap.set('orderedList', false)
    } else if (listType === 'ol') {
      this.activeButtonsMap.set('unorderedList', false)
      this.activeButtonsMap.set('orderedList', true)
    }

    const currentParagraphNode = this.findCurrentParagraphNode(this.getSelection().anchorNode)

    const list = document.createElement(listType)
    const listItem = document.createElement('li')
    const br = document.createElement('br')
    listItem.appendChild(br)
    list.appendChild(listItem)

    if (currentParagraphNode.childNodes[0].nodeName === 'BR') {
      currentParagraphNode.parentNode?.replaceChild(list, currentParagraphNode)
    } else {
      currentParagraphNode.parentNode?.insertBefore(list, currentParagraphNode.nextSibling)
    }

    const range = document.createRange()
    const selection = this.getSelection()

    const startPosition = this.findNodeInTree(listItem)

    if (startPosition) {
      range.setStart(startPosition, 0)
      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }

  /**
   * Replaces the current list with a different type of list (ul or ol).
   *
   * @param {MouseEvent} event - The MouseEvent that triggered the function.
   * @param {string} listType - The type of list to replace with ('ul' or 'ol').
   */
  replaceList(event: MouseEvent, listType: string) {
    event.preventDefault()
    this.restoredHistoryEntries = []
    this.saveVersion()

    const currentListNode = this.findCurrentListNode(this.getSelection().anchorNode)
    const listItems = Array.from(this.findCurrentListNode(this.getSelection().anchorNode).childNodes)
    const newListType = listType === 'ul' ? 'ol' : 'ul'

    const list = document.createElement(newListType)
    list.append(...listItems)
    currentListNode.parentNode?.replaceChild(list, currentListNode)

    const range = document.createRange()
    const selection = this.getSelection()
    const endPosition = this.findNodeInTree(listItems[listItems.length - 1])
    if (endPosition) {
      range.setStart(endPosition, 1)
      range.setEnd(endPosition, 1)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }

    if (newListType === 'ul') {
      this.activeButtonsMap.set('unorderedList', true)
      this.activeButtonsMap.set('orderedList', false)
    } else if (newListType === 'ol') {
      this.activeButtonsMap.set('unorderedList', false)
      this.activeButtonsMap.set('orderedList', true)
    }
    this.updateValue()
  }

  /**
   * Removes a list by converting selected list items to paragraphs.
   *
   * @param {MouseEvent} event - The mouse event that triggered the removal.
   * @param {string} listType - The type of list to remove ('ul' or 'ol').
   * @return {void}
   */
  removeList(event: MouseEvent, listType: string) {
    event.preventDefault()

    // the current <ul> or <ol> node
    const currentListNode = this.findCurrentListNode(this.getSelection().anchorNode) as ChildNode

    // the <li> items of the current list
    const listItems = Array.from(this.findCurrentListNode(this.getSelection().anchorNode).childNodes)

    // the <li> items which are currently selected which should be converted to paragraphs
    const listItemsToConvert = []
    for (const child of Array.from(currentListNode.childNodes)) {
      if (this.getSelection().containsNode(child, true)) {
        listItemsToConvert.push({ index: listItems.indexOf(child), node: child })
      }
    }

    if (listItemsToConvert.length === 0) {
      return
    }

    this.saveVersion()

    // convert the selected <li> items to <p> items
    const convertedListItems = []
    for (const listItem of listItemsToConvert) {
      convertedListItems.push({ index: listItem.index, node: this.convertListItemToParagraph(listItem.node) })
    }

    // get all <li> items before and after the selected <li> items in the current list
    const indexOfFirstListItem = convertedListItems[0].index
    const indexOfLastListItem = convertedListItems[convertedListItems.length - 1].index
    const listItemsBefore = [...listItems].slice(0, indexOfFirstListItem)
    const listItemsAfter = [...listItems].slice(indexOfLastListItem + 1)

    const listBefore = document.createElement(listType)
    listBefore.append(...listItemsBefore)

    const listAfter = document.createElement(listType)
    listAfter.append(...listItemsAfter)

    if (convertedListItems.length === 0) {
      return
    }

    // split up the current list and insert the new <p> items at the correct position
    if (listItemsBefore.length > 0) {
      currentListNode.parentNode?.replaceChild(listBefore, currentListNode)

      // insert <li> items before the new <p> paragraphs to keep the correct order
      listBefore.parentNode?.insertBefore(convertedListItems[0].node, listBefore.nextSibling)

      for (let i = 1; i < convertedListItems.length; i++) {
        listBefore.parentNode?.insertBefore(convertedListItems[i].node, convertedListItems[i - 1].node.nextSibling)
      }
    } else {
      // if there are no <li> items before the selected <li> items, replace the current list with the new <p> items
      currentListNode.parentNode?.replaceChild(convertedListItems[0].node, currentListNode)

      for (let i = 1; i < convertedListItems.length; i++) {
        convertedListItems[0].node.parentNode?.insertBefore(
          convertedListItems[i].node,
          convertedListItems[i - 1].node.nextSibling
        )
      }
    }

    // insert <li> items after the new <p> paragraphs to keep the correct order
    if (listItemsAfter.length > 0) {
      convertedListItems[convertedListItems.length - 1].node.parentNode?.insertBefore(
        listAfter,
        convertedListItems[convertedListItems.length - 1].node.nextSibling
      )
    }

    if (listType === 'ul') {
      this.activeButtonsMap.set('unorderedList', false)
    } else if (listType === 'ol') {
      this.activeButtonsMap.set('orderedList', false)
    }

    this.updateValue()
    this.setCursorPosition(
      convertedListItems[convertedListItems.length - 1].node,
      convertedListItems[convertedListItems.length - 1].node.childNodes.length
    )
  }

  /**
   * Converts a list item node into a paragraph node.
   *
   * @param {ChildNode} listItemNode - The list item node to convert.
   * @return {Node} - The newly created paragraph node.
   */
  convertListItemToParagraph(listItemNode: ChildNode): Node {
    const p = document.createElement('p')

    for (const childNode of Array.from(listItemNode.childNodes)) {
      p.appendChild(childNode)
    }

    return p
  }

  /**
   * Whenever the selection changes, the active buttons are reset and the new selection is checked for formatting.
   */
  selectionChanged(event?: MouseEvent) {
    if (event && event.detail === 2 && event.target instanceof HTMLElement && event.target.nodeName === 'A') {
      this.insertLink(event)
    }

    this.resetActiveButtons()
    this.checkFormat(this.getSelection().anchorNode)
  }

  /**
   * Checks the formatting of the given node and sets the active buttons accordingly.
   * @param node
   */
  checkFormat(node: Node | undefined | null) {
    if (!node || node === this.wsTextEditorContent.nativeElement) {
      return
    }

    switch (node.nodeName) {
      case 'B':
        this.activeButtonsMap.set('bold', true)
        break
      case 'I':
        this.activeButtonsMap.set('italic', true)
        break
      case 'U':
        this.activeButtonsMap.set('underline', true)
        break
      case 'STRIKE':
        this.activeButtonsMap.set('strikeThrough', true)
        break
      case 'P':
      case 'H1':
      case 'H2':
      case 'H3':
      case 'H4':
      case 'H5':
      case 'H6':
        this.formattingTypeOfSelection =
          this.formattingOptions.find((option) => option.value === node.nodeName) || this.formattingTypeOfSelection
        break
      case 'A':
        this.activeButtonsMap.set('link', true)
        break
      case 'UL':
        this.activeButtonsMap.set('unorderedList', true)
        break
      case 'OL':
        this.activeButtonsMap.set('orderedList', true)
        break
      case 'SPAN':
        this.activeButtonsMap.set('span', true)
        break
    }

    const style = (node as HTMLElement).style

    if (style) {
      switch (style.textAlign) {
        case 'left':
          this.activeButtonsMap.set('justifyLeft', true)
          this.activeButtonsMap.set('justifyCenter', false)
          this.activeButtonsMap.set('justifyRight', false)
          this.activeButtonsMap.set('justifyFull', false)
          break
        case 'center':
          this.activeButtonsMap.set('justifyCenter', true)
          this.activeButtonsMap.set('justifyLeft', false)
          this.activeButtonsMap.set('justifyRight', false)
          this.activeButtonsMap.set('justifyFull', false)
          break
        case 'right':
          this.activeButtonsMap.set('justifyRight', true)
          this.activeButtonsMap.set('justifyLeft', false)
          this.activeButtonsMap.set('justifyCenter', false)
          this.activeButtonsMap.set('justifyFull', false)
          break
        case 'justify':
          this.activeButtonsMap.set('justifyFull', true)
          this.activeButtonsMap.set('justifyLeft', false)
          this.activeButtonsMap.set('justifyCenter', false)
          this.activeButtonsMap.set('justifyRight', false)
          break
        default:
          this.activeButtonsMap.set('justifyLeft', true)
          this.activeButtonsMap.set('justifyCenter', false)
          this.activeButtonsMap.set('justifyRight', false)
          this.activeButtonsMap.set('justifyFull', false)
          break
      }
    }

    // recursive call to check the parent node it will stop at the first div it encounters which should be the contenteditable div
    this.checkFormat(node.parentNode as Node)
  }

  /**
   * Resets all active buttons. This is e.g. used when the text editor loses focus.
   */
  resetActiveButtons() {
    this.activeButtonsMap.clear()
  }

  /**
   * Inserts a link at the current selected text. A dialog is opened to enter the url and the target.
   * @param event {MouseEvent} The click event.
   */
  insertLink(event: MouseEvent) {
    event.preventDefault()
    this.restoredHistoryEntries = []

    const isNewLink = !this.activeButtonsMap.get('link')
    const selection = this.getSelection()

    if (selection?.toString() === '' && isNewLink) {
      this.notificationService.createNotification('ws.forms.textEditor.selectTextToLink', {
        severity: 'warning'
      })
      return
    }

    const node = selection?.anchorNode
    const range = document.createRange()

    if (node) {
      range.setStart(node, selection?.anchorOffset || 0)
      range.setEnd(node, selection?.focusOffset || 0)
    } else {
      return
    }

    if (isNewLink) {
      this.createLink(range)
    } else {
      this.editLink(range)
    }

    this.wsTextEditorContent.nativeElement.blur()
  }

  /**
   * Creates a link at the specified range.
   *
   * @param {Range} range - The range where the link should be created.
   */
  createLink(range: Range) {
    this.linkDialog = this.dialogService.open({
      dialogTitleText: 'ws.forms.textEditor.insertLinkTitle',
      dialogContentTemplateRef: this.addLinkDialogContent
    })

    setTimeout(() => {
      this.linkDialogInput.inputElement?.nativeElement.focus()
    }, 10)

    this.linkDialog.afterClose$.subscribe((action: string) => {
      if (action === 'confirm') {
        if (this.linkForm.valid) {
          this.saveVersion()

          document.getSelection()?.removeAllRanges()
          document.getSelection()?.addRange(range)
          document.execCommand('createLink', false, this.linkForm.get('url')?.value || '')
          this.activeButtonsMap.set('link', true)

          if (this.linkForm.get('blank')?.value) {
            const link = this.getSelection().anchorNode?.parentNode as HTMLElement
            link.setAttribute('target', '_blank')
          }
        } else {
          this.notificationService.createNotification('ws.forms.textEditor.invalidUrl', {
            severity: 'warning'
          })

          document.getSelection()?.removeAllRanges()
          document.getSelection()?.addRange(range)
        }
      }

      this.linkForm.reset()
    })
  }

  /**
   * Edits the link within the specified range.
   *
   * @param {Range} range - The range within which the link should be edited.
   *
   * @return {void}
   */
  editLink(range: Range) {
    const linkNode = this.findLinkNode(this.getSelection().anchorNode)
    const linkElement = linkNode as HTMLElement
    const url = linkElement.getAttribute('href')
    const target = linkElement.getAttribute('target')
    this.linkForm.get('url')?.setValue(url)
    this.linkForm.get('blank')?.setValue(target === '_blank')

    this.linkDialog = this.dialogService.open({
      dialogTitleText: 'ws.forms.textEditor.editLinkTitle',
      dialogContentTemplateRef: this.addLinkDialogContent
    })

    setTimeout(() => {
      this.linkDialogInput.inputElement?.nativeElement.focus()
    }, 10)

    this.linkDialog.afterClose$.subscribe((action: string) => {
      if (action === 'confirm') {
        if (this.linkForm.valid) {
          this.saveVersion()

          const linkElement = linkNode as HTMLElement
          linkElement.setAttribute('href', this.linkForm.get('url')?.value || '')
          if (this.linkForm.get('blank')?.value) {
            linkElement.setAttribute('target', '_blank')
          } else {
            linkElement.removeAttribute('target')
          }
        } else {
          this.notificationService.createNotification('ws.forms.textEditor.invalidUrl', {
            severity: 'warning'
          })
        }
      }

      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(range)

      this.linkForm.reset()
      this.updateValue()
    })
  }

  /**
   * Removes the link from the selected text.
   * @param event {MouseEvent} The click event.
   */
  removeLink(event: MouseEvent) {
    event.preventDefault()
    this.saveVersion()

    const node = this.getSelection().anchorNode

    if (node) {
      const range = document.createRange()
      range.setStart(node, 0)
      range.setEnd(node, node.textContent?.length || 0)
      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(range)
      document.execCommand('unlink', false)
    }

    this.activeButtonsMap.set('link', false)
  }

  /**
   * Applies the selected text color to the current text selection or the surrounding span element.
   *
   * @returns {void} Returns nothing.
   */
  applyTextColor() {
    if (this.textColorPicker.colorPickerMenuTrigger?.menuOpen) {
      return
    }

    const range = this.getSelection().getRangeAt(0)
    const selectedText = this.getSelection().toString()

    if (range && range?.toString() !== '') {
      const span = document.createElement('span')
      span.innerText = selectedText || ''

      span.setAttribute('style', 'color: ' + this.selectedTextColor + ';')

      range.deleteContents()
      range.insertNode(span)

      this.activeButtonsMap.set('span', true)
      this.removeEmptySpanNodes(this.getSelection().anchorNode?.parentNode)
    } else if (range && range?.toString() === '') {
      // if its in a span change color from span
      if (this.getSelection().anchorNode?.parentNode?.nodeName === 'SPAN') {
        const span = this.getSelection().anchorNode?.parentNode as HTMLElement
        span.setAttribute('style', 'color: ' + this.selectedTextColor + ';')
      }
    }

    this.updateValue()
    this.checkFormat(this.getSelection().anchorNode)
  }

  /**
   * Removes empty <span> nodes from the given DOM node and its descendants.
   *
   * @param {Node | undefined | null} node - The node to be checked for empty <span> nodes.
   * @returns {void}
   */
  removeEmptySpanNodes(node: Node | undefined | null) {
    if (!node) {
      return
    }

    if (node.nodeName === 'SPAN' && node.textContent === '') {
      node.parentNode?.removeChild(node)
    }

    if (node.childNodes) {
      for (const child of Array.from(node.childNodes)) {
        this.removeEmptySpanNodes(child)
      }
    }
  }

  /**
   * Focuses the editor by setting the cursor to the editor content if it is empty.
   *
   * @return {void}
   */
  focusEditor() {
    if (this.getHTMLContent() === '') {
      this.wsTextEditorContent.nativeElement.innerHTML = '<p><br></p>'
    }
  }

  /**
   * Removes _ngcontent attributes from the html string.
   * @param html {string} The html string to sanitize.
   */
  sanitizeHtml(html: string) {
    if (/^<(p|h[1-6])><\/\1>$/.test(html) || /^<(p|h[1-6])><br><\/\1>$/.test(html)) {
      return ''
    }

    html = this.removeUnnecessaryBrTags(html)

    return html.replace(/\s*_ngcontent[^"]*"+/g, '')
  }

  removeUnnecessaryBrTags(html: string) {
    html = html.replace(/<span><br><\/(p|h[1-6])>/g, '</$1>')
    html = html.replace(/<\/(span)><br>/g, '</span>')
    html = html.replace(/<br><\/(span)>/g, '</span>')
    return html
  }

  /**
   * Saves the current version of the html string.
   * If the history has more than 10 entries, the oldest entry is removed.
   */
  saveVersion() {
    if (this.contentHistory.length >= 10) {
      this.contentHistory.shift()
    }

    this.cursorNode = this.getSelection().anchorNode
    this.contentHistory.push({
      cursorPositionNode: this.getSelection().anchorNode || this.wsTextEditorContent.nativeElement,
      cursorOffset: this.getSelection().anchorOffset || 0,
      content: this.getChildNodesAsArray(this.wsTextEditorContent.nativeElement)
    })

    this.restoredHistoryEntries = []
  }

  /**
   * Restores the last saved version of the html string.
   * Saves the restored version in the restoredHistoryEntries array.
   */
  undo() {
    if (this.contentHistory.length > 0) {
      this.restoredHistoryEntries.push({
        cursorPositionNode: this.getSelection().anchorNode || this.wsTextEditorContent.nativeElement,
        cursorOffset: this.getSelection().anchorOffset || 0,
        content: this.getChildNodesAsArray(this.wsTextEditorContent.nativeElement)
      })

      this.restoreVersion(this.contentHistory.pop())

      this.updateValue()
      this.selectionChanged()
    }
  }

  /**
   * Restores the last version of the html string which was restored.
   * Saves the restored version in the contentHistory array.
   */
  redo() {
    if (this.restoredHistoryEntries.length > 0) {
      this.contentHistory.push({
        cursorPositionNode: this.getSelection().anchorNode || this.wsTextEditorContent.nativeElement,
        cursorOffset: this.getSelection().anchorOffset || 0,
        content: this.getChildNodesAsArray(this.wsTextEditorContent.nativeElement)
      })

      this.restoreVersion(this.restoredHistoryEntries.pop())

      this.updateValue()
      this.selectionChanged()
    }
  }

  /**
   * Restores a previous version of the text editor content and cursor position.
   *
   * @param {TextEditorHistoryEntry | undefined} historyEntry - The history entry representing the previous version to be restored.
   *
   * @return {void} - This method does not return a value.
   */
  restoreVersion(historyEntry: TextEditorHistoryEntry | undefined) {
    if (!historyEntry) {
      return
    }

    this.wsTextEditorContent.nativeElement.innerHTML = ''

    for (const childNode of historyEntry.content) {
      this.wsTextEditorContent.nativeElement.appendChild(childNode)
    }

    this.setCursorPosition(historyEntry.cursorPositionNode, historyEntry.cursorOffset)
  }

  /**
   * Set the cursor position in a given node at a specified offset.
   *
   * @param {Node | null | undefined} cursorPositionNode - The node in which the cursor position needs to be set.
   * @param {number} cursorOffset - The offset within the node at which the cursor needs to be placed.
   *
   * @return {void}
   */
  setCursorPosition(cursorPositionNode: Node | null | undefined, cursorOffset: number) {
    if (!cursorPositionNode) {
      return
    }

    const node = this.findNodeInTree(cursorPositionNode)

    if (node) {
      const range = document.createRange()
      range.setStart(node, cursorOffset)
      range.setEnd(node, cursorOffset)
      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(range)
    }
  }

  /**
   * Listener for the keydown event and checks for ctrl key combinations.
   * @param event {KeyboardEvent} The keydown event.
   */
  handleKeyDown(event: KeyboardEvent) {
    if (this.getHTMLContent() === '') {
      this.wsTextEditorContent.nativeElement.innerHTML = '<p><br></p>'
    }

    if (!event.ctrlKey && !(event.key === 'y' || event.key === 'Y')) {
      this.restoredHistoryEntries = []
    }

    if (event.ctrlKey) {
      switch (event.key) {
        case 'z':
        case 'Z':
          event.preventDefault()
          if (event.shiftKey) {
            this.redo()
          } else {
            this.undo()
          }
          break
        case 'y':
        case 'Y':
          event.preventDefault()
          this.redo()
          break
        case 'b':
        case 'B':
          event.preventDefault()
          this.execCommand(event, 'bold')
          break
        case 'i':
        case 'I':
          event.preventDefault()
          this.execCommand(event, 'italic')
          break
        case 'u':
        case 'U':
          event.preventDefault()
          this.execCommand(event, 'underline')
          break
        case 's':
        case 'S':
          event.preventDefault()
          this.execCommand(event, 'strikeThrough')
          break
      }
    }
  }

  /**
   * Updates the value variable with the current html string.
   */
  updateValue() {
    this.htmlString = this.getHTMLContent()
    this.value = this.htmlString
    this.onFieldChange(this.value)
  }

  /**
   * Switches the input mode of the application between plain HTML mode and generated HTML string mode.
   * If the input mode is currently in plain HTML mode, this method will generate a HTML string from the input.
   * If the input mode is currently in generated HTML string mode, this method will switch the mode back to plain HTML.
   *
   * @return {void} - This method does not return a value.
   */
  switchInputMode() {
    if (!this.plainHTMLMode) {
      this.generateHTMLString()
    }

    this.plainHTMLMode = !this.plainHTMLMode
  }

  /**
   * Generates an HTML string based on the value specified.
   * The method replaces certain HTML tags and adds line breaks where necessary.
   *
   * @returns {void}
   */
  generateHTMLString() {
    // this.htmlString = this.value.replace(/<\/(p|h[1-6]|li|ul|ol)>/g, '</$1>\n')
    this.htmlString = this.value.replace(/<\/(p|h[1-6]|ul|ol|li)>/g, '</$1>\n')
    this.htmlString = this.htmlString.replace(/<(ul|li|ol)/g, '\n<$1')
    this.htmlString = this.htmlString.replace(/<hr>/g, '<hr>\n')
    this.htmlString = this.htmlString.replace(/\n+/g, '\n')
  }

  /**
   * Retrieves the current selection within the text editor.
   *
   * @returns {Selection} The current selection as a Selection object.
   */
  getSelection(): Selection {
    const selection = window.getSelection()

    if (selection && this.findNodeInTree(selection?.anchorNode)) {
      return selection
    } else {
      const range = document.createRange()
      range.setStart(this.wsTextEditorContent.nativeElement, this.wsTextEditorContent.nativeElement.childNodes.length)
      range.setEnd(this.wsTextEditorContent.nativeElement, this.wsTextEditorContent.nativeElement.childNodes.length)
      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(range)

      return document.getSelection() as Selection
    }
  }

  /**
   * Returns an array of child nodes for the given node.
   *
   * @param {Node | undefined | null} node - The node whose child nodes should be retrieved.
   * @return {Node[]} - An array of child nodes.
   */
  getChildNodesAsArray(node: Node | undefined | null): Node[] {
    if (!node) {
      return []
    }

    const childNodes: Node[] = []

    node.childNodes.forEach((node: Node) => {
      childNodes.push(node.cloneNode(true))
    })

    return childNodes
  }

  /**
   * Retrieves styles from a given CustomStyleSet.
   *
   * @param {CustomStyleSet} customStyleSet - The CustomStyleSet object to extract styles from.
   *
   * @return {Object} - An object containing the extracted styles, with the style property names as keys and their corresponding values as values.
   */
  getStyles(customStyleSet: CustomStyleSet) {
    const styles: { [key: string]: string } = {}
    for (const customStyle of customStyleSet.style) {
      const [key, value] = customStyle.split(':')
      styles[key] = value
    }

    return styles
  }

  /**
   * Retrieves the HTML content of the text editor.
   *
   * @returns {string} The sanitized HTML content.
   */
  getHTMLContent() {
    return this.sanitizeHtml(this.wsTextEditorContent.nativeElement.innerHTML)
  }

  /**
   * Finds a specific node in a tree by depth-first search.
   *
   * @param {Node | undefined | null} node - The node to search for in the tree.
   * @returns {Node | undefined} - The found node or undefined if not found.
   */
  findNodeInTree(node: Node | undefined | null): Node | undefined {
    if (!node) {
      return
    }

    const nodeStack: Node[] = [this.wsTextEditorContent.nativeElement]

    while (nodeStack.length > 0) {
      const currentNode = nodeStack.pop()
      if (currentNode?.isEqualNode(node)) {
        return currentNode
      }

      if (currentNode?.childNodes) {
        for (let i = 0; i < currentNode.childNodes.length; i++) {
          nodeStack.push(currentNode.childNodes[i])
        }
      }
    }

    return undefined
  }

  /**
   * Finds the first anchor element (link) in the given node and its ancestors.
   *
   * @param {Node | undefined | null} node - The starting node to search for the link.
   * @returns {Node | undefined} Returns the first anchor element found, or undefined if not found.
   */
  findLinkNode(node: Node | undefined | null): Node | undefined {
    if (!node) {
      return
    }

    if (node.nodeName === 'A') {
      return node
    }

    return this.findLinkNode(node.parentNode as Node)
  }

  /**
   * Finds the parent <span> node of the given element.
   *
   * @param {HTMLElement} node - The HTML element to find the parent <span> node for.
   * @return {HTMLElement} The parent <span> node of the given element.
   */
  findSpanNodeParent(node: HTMLElement): HTMLElement {
    if (node.nodeName === 'SPAN') {
      return node
    } else {
      return this.findSpanNodeParent(node.parentNode as HTMLElement)
    }
  }

  /**
   * Finds and returns the current paragraph node based on the given starting node.
   * If no starting node is provided, it returns the paragraph node of the text editor's content.
   * If the starting node is of type P, H1-H6, or UL, it is considered as the current paragraph node.
   * If the starting node is not a valid paragraph node, it traverses up the DOM tree until it finds a valid paragraph node.
   *
   * @param {Node | undefined | null} node - The starting node to search from. If not provided, it defaults to the text editor's content.
   * @returns {Node} - The current paragraph node. If no valid paragraph node is found, it returns null.
   */
  findCurrentParagraphNode(node: Node | undefined | null): Node {
    if (!node) {
      return this.wsTextEditorContent.nativeElement
    }

    if (new RegExp(/^(P|H[1-6]|UL)$/).test(node.nodeName)) {
      return node
    }

    return this.findCurrentParagraphNode(node.parentNode)
  }

  /**
   * Finds the current list item node recursively from the given starting node.
   *
   * @param {Node | undefined | null} node - The starting node to search from.
   *
   * @returns {Node} - The current list item node, or the text editor content node if no list item node is found.
   */
  findCurrentListItemNode(node: Node | undefined | null): Node {
    if (!node) {
      return this.wsTextEditorContent.nativeElement
    }

    if (new RegExp(/^(LI)$/).test(node.nodeName)) {
      return node
    }

    return this.findCurrentListItemNode(node.parentNode)
  }

  /**
   * Finds the current list node or the root node if not provided.
   *
   * @param {Node | undefined | null} node - The node to start the search from. If not provided, the root node will be used.
   *
   * @return {Node} - The current list node or the root node if not found.
   */
  findCurrentListNode(node: Node | undefined | null): Node {
    if (!node) {
      return this.wsTextEditorContent.nativeElement
    }

    if (new RegExp(/^(UL|OL)$/).test(node.nodeName)) {
      return node
    }

    return this.findCurrentListNode(node.parentNode)
  }

  /**
   * Checks if a given node is part of a list.
   *
   * @param {Node|null|undefined} node - The node to be checked.
   * @return {boolean} - True if the node is part of a list, false otherwise.
   */
  isInList(node: Node | undefined | null): boolean {
    if (!node) {
      return false
    }

    if (new RegExp(/^(UL|OL)$/).test(node.nodeName)) {
      return true
    }

    return this.isInList(node.parentNode)
  }
}
