import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export class WsValidators {
  static customValidator(regExp: RegExp, translationKey: string, testWhiteSpacesAndSpecialChars = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value == null ||
        ((typeof control.value === 'string' || Array.isArray(control.value)) && control.value.length === 0)
      ) {
        return null
      }
      if (testWhiteSpacesAndSpecialChars && control.value.match(/[\s:/?#@[\]°^"!§$&'()*+,;\-_=%]+/)) {
        return { invalidWhiteSpaces: { value: control.value } }
      }

      return !control.value.match(regExp) ? { [translationKey]: { value: control.value } } : null
    }
  }

  static phoneValidator(): ValidatorFn {
    return WsValidators.customValidator(new RegExp(/^(?!00|\+)[0-9]+$/), 'invalidPhoneFormat')
  }

  static creditCardNumberValidator(): ValidatorFn {
    return WsValidators.customValidator(new RegExp(/^([0-9]{16})$/), 'invalidCardNumber')
  }

  static creditCardValidityValidator(): ValidatorFn {
    return WsValidators.customValidator(new RegExp(/^(0[1-9]|1[0-2])\/([2-9][0-9])$/), 'invalidCardValidity', false)
  }

  static urlValidator(): ValidatorFn {
    return WsValidators.customValidator(
      new RegExp(/^https:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/),
      'invalidUrlFormat',
      false
    )
  }

  static emailDomainValidator(): ValidatorFn {
    return WsValidators.customValidator(
      new RegExp('^(?!www\\.)(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$'),
      'mustBeAValidEmailDomain',
      false
    )
  }

  static sameAs(compareName: string, compareNameTranslation: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value
      if (!value) {
        // form input is valid when no value is given (otherwise the validator would show invalid when editing the compared field after the field to validate
        return null
      }
      const compareValue = control.parent?.get(compareName)?.value
      return value === compareValue ? null : { sameAs: { compareName, compareNameTranslation } }
    }
  }

  static greaterOrEqualThan(compareName: string, compareNameTranslation: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.greaterOrEqualThanHelper(control, 'toRange', compareName, compareNameTranslation)
    }
  }

  static laterOrSimultaneously(compareName: string, compareNameTranslation: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.greaterOrEqualThanHelper(control, 'laterOrSimultaneously', compareName, compareNameTranslation)
    }
  }

  static greaterOrEqualThanHelper(
    control: AbstractControl,
    translationKey: string,
    compareName: string,
    compareNameTranslation: string
  ): ValidationErrors | null {
    const value = control.value
    const compareValue = control.parent?.get(compareName)?.value

    const valueIsDefined: boolean = value !== null && value !== undefined && value !== ''
    const compareValueIsDefined: boolean = compareValue !== null && compareValue !== undefined && compareValue !== ''

    if (valueIsDefined && compareValueIsDefined) {
      if (value < compareValue) {
        return {
          [translationKey]: { compareNameTranslation }
        }
      }
    }

    return null
  }

  static lessOrEqualThan(compareName: string, compareNameTranslation: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.lessOrEqualThanHelper(control, 'fromRange', compareName, compareNameTranslation)
    }
  }

  static earlierOrSimultaneously(compareName: string, compareNameTranslation: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.lessOrEqualThanHelper(control, 'earlierOrSimultaneously', compareName, compareNameTranslation)
    }
  }

  static lessOrEqualThanHelper(
    control: AbstractControl,
    translationKey: string,
    compareName: string,
    compareNameTranslation: string
  ): ValidationErrors | null {
    const value = control.value
    const compareValue = control.parent?.get(compareName)?.value

    const valueIsDefined: boolean = value !== null && value !== undefined && value !== ''
    const compareValueIsDefined: boolean = compareValue !== null && compareValue !== undefined && compareValue !== ''

    if (valueIsDefined && compareValueIsDefined) {
      if (value > compareValue) {
        return {
          [translationKey]: { compareNameTranslation }
        }
      }
    }

    return null
  }

  static requiredIf(compareName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value
      const compareValue = control.parent?.get(compareName)?.value

      // only if compareValue is given and the value of the field to be validated is falsy, the field is marked as required
      const valueIsNotDefined = value === null || value === undefined || value === ''
      const compareValueIsDefined = compareValue !== null && compareValue !== undefined && compareValue !== ''

      if (valueIsNotDefined && compareValueIsDefined) {
        return { required: true }
      }

      return null
    }
  }

  static bicValidator(): ValidatorFn {
    return WsValidators.customValidator(
      new RegExp(/^[A-Za-z0-9]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/),
      'invalidBic'
    )
  }

  static ibanValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // according to https://www.iban.com/structure
      const CODE_LENGTHS: { [key: string]: number } = {
        AD: 24,
        AE: 23,
        AL: 28,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BI: 27,
        BR: 29,
        BY: 28,
        CH: 21,
        CR: 22,
        CY: 28,
        CZ: 24,
        DE: 22,
        DJ: 27,
        DK: 18,
        DO: 28,
        EE: 20,
        EG: 29,
        ES: 24,
        FI: 18,
        FK: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GE: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IQ: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MN: 20,
        MR: 27,
        MT: 31,
        MU: 30,
        NI: 28,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        RU: 33,
        SA: 24,
        SC: 31,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        SO: 23,
        ST: 25,
        SV: 28,
        TL: 23,
        TN: 24,
        TR: 26,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20
      }
      if (control.value.match(/[\s:/?#@[\]°^"!§$&'()*+,;\-_=%]+/)) {
        return { invalidWhiteSpaces: { value: control.value } }
      }
      if (
        control.value == null ||
        ((typeof control.value === 'string' || Array.isArray(control.value)) && control.value.length === 0)
      ) {
        return null
      }
      const iban = String(control.value)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, '')
      const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/)
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return { invalidIban: { value: control.value } }
      }
      // rearrange country code and check digits, and convert chars to ints
      const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return String(letter.charCodeAt(0) - 55)
      })
      // final check according to ISO 7064 mod 97-10
      return this.mod97(digits) !== 1 ? { invalidIban: { value: control.value } } : null
    }
  }

  private static mod97(string: any) {
    let checksum = string.slice(0, 2),
      fragment
    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7)
      checksum = parseInt(fragment, 10) % 97
    }
    return checksum
  }

  public static objectValueRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && typeof control.value === 'object') {
        for (const [key, value] of Object.entries(control.value)) {
          if (value) {
            return null
          }
        }
        return { required: true }
      }
      return null
    }
  }

  static latValidator(): ValidatorFn {
    return WsValidators.customValidator(new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/), 'invalidLat', false)
  }

  static lonValidator(): ValidatorFn {
    return WsValidators.customValidator(
      new RegExp(/^[-+]?((1[0-7]\d(\.\d+)?)|([1-9]?\d(\.\d+)?)|180(\.0+)?)$/),
      'invalidLon',
      false
    )
  }

  static noSpaces(): ValidatorFn {
    return WsValidators.customValidator(new RegExp(/^\S+$/), 'noSpaces', false)
  }

  static numberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value

      if (value === null || value === undefined || value === '') {
        return null
      }
      if (typeof value === 'number') {
        return isNaN(value) ? { numbersOnly: true } : null
      }
      if (typeof value === 'string') {
        return /^[0-9]+$/.test(value) ? null : { numbersOnly: true }
      }
      return { invalidNumber: true }
    }
  }
}
