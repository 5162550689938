import { Component, Input, OnInit } from '@angular/core'
import { OptionsFieldComponent } from '../options-field/options-field.component'
import { ControlValueAccessor } from '@angular/forms'

@Component({
  selector: 'ws-form-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrl: './chips-input.component.scss'
})
export class ChipsInputComponent extends OptionsFieldComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholder = ''
  @Input() public type: 'text' | 'number' = 'text'
  @Input() public icon = 'send'
  @Input() public duplicatesAllowed = true

  inputValue: string | number = ''

  public inputValues: (string | number)[] = []

  removeFromInputValues(index: number) {
    this.inputValues.splice(index, 1)
    this.updateValueOfFormElement()
    // do not reset form control errors if last element is removed because of required validation
    if (index > 0) {
      this.resetFormControlErrors()
    }
  }

  override ngOnInit() {
    super.ngOnInit()
    setTimeout(() => {
      this.setValueToInputValues()
    })
  }

  updateValueOfFormElement() {
    this.onFieldChange(this.value)
    this.setValueToInputValues()
  }

  setValueToInputValues() {
    this.inputValues = this.value
  }

  addToInputValues(value: string | number) {
    this.onFieldChange(value)
    if ((!value && value !== 0) || this.formControl.errors) {
      return
    }
    if (!this.duplicatesAllowed && this.inputValues.includes(value)) {
      this.validationErrorMessage = this.translate.instant('ws.forms.validation.duplicateValues')
      this.hasErrors = true
      return
    }
    this.inputValues.push(value)
    this.updateValueOfFormElement()
    this.inputValue = ''
    this.resetFormControlErrors()
  }

  handleInputKeyDown(event: any) {
    event.preventDefault()
    this.addToInputValues(this.inputValue)
  }

  checkForInputs() {
    if (this.inputValue) {
      this.validationErrorMessage = this.translate.instant('ws.forms.validation.clickToAddValue')
      this.hasErrors = true
    }
  }

  override writeValue(value: any) {
    super.writeValue(value)
    this.setValueToInputValues()
  }
}
