import { BrickComposite } from './brickComposite'
import defaultStyles from './columnStyles.json'
import { StyleAttributes, TestData } from './brick.types'

export class Column extends BrickComposite {
  constructor(styles: StyleAttributes = {}) {
    super('Column', '', styles, 'mj-column')
    this.addDefaultStyles(defaultStyles)
  }

  override hasGutter() {
    return ['padding', 'padding-bottom', 'padding-left', 'padding-right', 'padding-top'].some(
      (attr) => !!this.styles[attr]
    )
  }

  override getStyles(testData: TestData = {}): any {
    const border = this.styles['border']
      ? { border: this.replaceTestData(this.styles['border']?.toString() || '', testData) }
      : {
          'border-bottom': this.replaceTestData(this.styles['border-bottom']?.toString() || '', testData),
          'border-left': this.replaceTestData(this.styles['border-left']?.toString() || '', testData),
          'border-right': this.replaceTestData(this.styles['border-right']?.toString() || '', testData),
          'border-top': this.replaceTestData(this.styles['border-top']?.toString() || '', testData)
        }

    let tableStyle = {
      'background-color': this.replaceTestData(this.styles['background-color']?.toString() || '', testData),
      //'border-radius': this.styles['border-radius'],
      'vertical-align': this.styles['vertical-align']
    }

    tableStyle = { ...tableStyle, ...border }

    return {
      div: {
        'font-size': '0px',
        'text-align': 'left',
        direction: this.styles['direction'],
        display: 'inline-block',
        'vertical-align': this.styles['vertical-align']
      },
      table: {
        ...(this.hasGutter()
          ? {
              'background-color': this.styles['inner-background-color'],
              border: this.styles['inner-border']
              // 'border-bottom': this.styles['inner-border-bottom'],
              // 'border-left': this.styles['inner-border-left'],
              // 'border-radius': this.styles['inner-border-radius'],
              // 'border-right': this.styles['inner-border-right'],
              // 'border-top': this.styles['inner-border-top']
            }
          : tableStyle)
      },
      tdOutlook: {
        'vertical-align': this.styles['vertical-align']
      },
      gutter: {
        ...tableStyle,
        'vertical-align': this.styles['vertical-align'],
        padding: this.getPadding()
      }
    }
  }
}
