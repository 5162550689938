import { Injectable } from '@angular/core'
import { WsEmailTemplate } from '../../data/types/ws-email-template'

export interface UndoRedoAction {
  language: 'de' | 'en' | 'nl' | 'fr' | 'it'
  mjml: string
  html: string
  styles: string
  testData: string
}

@Injectable({
  providedIn: 'root'
})
export class WsEmailUndoRedoService {
  currentLanguage: 'de' | 'en' | 'nl' | 'fr' | 'it' = 'de'
  private actionStack: UndoRedoAction[] = []
  private stackPointer = -1

  public undoAvailable(): boolean {
    return this.stackPointer > 0
  }

  public redoAvailable(): boolean {
    return this.stackPointer < this.actionStack.length - 1
  }

  public resetStackPointer() {
    this.stackPointer = -1
  }

  public clearStack() {
    this.actionStack = []
    this.resetStackPointer()
  }

  isCurrentActionSameAsLast(current: UndoRedoAction, last: UndoRedoAction) {
    return last.mjml === current.mjml && last.styles === current.styles && last.testData === current.testData
  }

  public captureAction(action: WsEmailTemplate): void {
    // If the action is the same as the last action, don't add it to the stack
    if (this.stackPointer >= 0 && this.stackPointer < this.actionStack.length) {
      const currentAction: UndoRedoAction = {
        language: this.currentLanguage,
        mjml: JSON.stringify(action.mjml),
        html: JSON.stringify(action.html),
        styles: action.styles,
        testData: action.testData
      }
      const last: UndoRedoAction = this.actionStack[this.stackPointer]
      if (this.isCurrentActionSameAsLast(currentAction, last)) {
        return
      }
    }
    // If the stack pointer is not at the end of the stack, remove all actions after the stack pointer
    if (this.stackPointer !== -1 && this.stackPointer < this.actionStack.length - 1) {
      this.actionStack = this.actionStack.slice(0, this.stackPointer + 1)
    }

    this.actionStack.push({
      language: this.currentLanguage,
      mjml: JSON.stringify(action.mjml),
      html: JSON.stringify(action.html),
      styles: action.styles,
      testData: action.testData
    })
    this.stackPointer = this.actionStack.length - 1
  }

  public undo(): UndoRedoAction | undefined {
    if (this.stackPointer > 0) {
      this.stackPointer--
      this.restoreLanguage()
      return this.actionStack[this.stackPointer]
    }
    return undefined
  }

  public redo(): UndoRedoAction | undefined {
    if (this.stackPointer < this.actionStack.length - 1) {
      this.stackPointer++
      this.restoreLanguage()
      return this.actionStack[this.stackPointer]
    }
    return undefined
  }

  private restoreLanguage() {
    this.currentLanguage = this.actionStack[this.stackPointer].language
  }
}
