import { Brick } from './brick'
import defaultStyles from './buttonStyles.json'
import mjml2html from 'mjml-browser'
import { StyleAttributes, TestData } from './brick.types'

export class Button extends Brick {
  constructor(styles: StyleAttributes = {}, content: string = '') {
    super('Button', content, styles, 'mj-button')
    this.addDefaultStyles(defaultStyles)
  }

  override getStyles(testData: TestData = {}): any {
    const border = this.styles['border']
      ? { border: this.replaceTestData(this.styles['border']?.toString() || '', testData) }
      : {
          'border-bottom': this.replaceTestData(this.styles['border-bottom']?.toString() || '', testData),
          'border-left': this.replaceTestData(this.styles['border-left']?.toString() || '', testData),
          'border-right': this.replaceTestData(this.styles['border-right']?.toString() || '', testData),
          'border-top': this.replaceTestData(this.styles['border-top']?.toString() || '', testData)
        }

    return {
      table: {
        'border-collapse': 'separate',
        width: this.styles['width'],
        'line-height': '100%'
      },
      td: {
        ...border,
        cursor: 'auto',
        'font-style': this.styles['font-style'],
        height: this.styles['height'],
        'mso-padding-alt': this.styles['inner-padding'],
        //padding: this.getPadding(),
        background: this.replaceTestData(this.styles['background-color']?.toString() || '', testData)
      },
      content: {
        display: 'inline-block',
        //width: this.calculateAWidth(this.styles['width']),
        background: this.replaceTestData(this.styles['background-color']?.toString() || '', testData),
        color: this.replaceTestData(this.styles['color']?.toString() || '#ffffff', testData),
        'font-family': this.styles['font-family'],
        'font-size': this.styles['font-size'],
        'font-style': this.styles['font-style'],
        'font-weight': this.styles['font-weight'],
        'line-height': this.styles['line-height'],
        'letter-spacing': this.styles['letter-spacing'],
        margin: '0',
        'text-decoration': this.styles['text-decoration'],
        'text-transform': this.styles['text-transform'],
        padding: this.styles['inner-padding'],
        //padding: this.getPadding(),
        'mso-padding-alt': '0px',
        'border-radius': this.styles['border-radius']
      }
    }
  }

  override getMjmlWithGlobalStyles(globalStyles: StyleAttributes = {}) {
    const stylesWithoutGlobalStyle = Object.assign({}, this.styles)
    if (
      !this.styles['font-family'] ||
      this.styles['font-family'] === 'undefined' ||
      this.styles['font-family'] === ''
    ) {
      this.styles['font-family'] = globalStyles['font-family']
    }
    if (!this.styles['color'] || this.styles['color'] === 'undefined' || this.styles['color'] === '') {
      this.styles['color'] = globalStyles['color']
    }
    const mjml = this.getMjml()
    this.styles = stylesWithoutGlobalStyle
    return mjml
  }

  getMjmlAttributes(globalStyles: StyleAttributes = {}): string {
    const attributes = Object.keys(globalStyles)
      .filter((attribute) => attribute === 'font-family')
      .map((attribute) => `${attribute}="${globalStyles[attribute]}"`)
      .join(' ')
    return attributes ? `<mj-attributes><mj-all ${attributes}/></mj-attributes>` : ''
  }

  override getHtmlWithGlobalStyles(globalStyles: StyleAttributes = {}, testData: TestData = {}): string {
    //replace only styles test data not whole html/(mjml)
    const globalStylesNew = JSON.parse(JSON.stringify(globalStyles))
    this.replaceTestDataOfStyles(globalStylesNew, testData)

    const cachedStyles = JSON.parse(JSON.stringify(this.styles))
    this.replaceTestDataOfStyles(this.styles, testData)

    const componentMjml = `<mjml><mj-head>${this.getMjmlAttributes(globalStylesNew)}</mj-head><mj-body>${this.getMjml()}</mj-body></mjml>`
    this.html = mjml2html(componentMjml).html

    //revert testData replacement of styles
    this.styles = cachedStyles

    return this.html
  }

  override getHtml(): string {
    const componentMjml = `<mjml><mj-head><mj-style></mj-style></mj-head><mj-body>${this.getMjml()}</mj-body></mjml>`
    this.html = mjml2html(componentMjml).html
    return this.html
  }
}
