<h3 mat-dialog-title>{{ data.title }}</h3>

<mat-dialog-content>
  {{ data.message }}
</mat-dialog-content>

@if (data.action) {
  <mat-dialog-actions align="end">
    @if (!data.icon) {
      <button mat-button [mat-dialog-close]="true">{{ data.action }}</button>
    } @else if (data.icon && data.iconPosition === 'right') {
      <button mat-button [mat-dialog-close]="true">
        <mat-icon [fontIcon]="data.icon" iconPositionEnd></mat-icon>
        {{ data.action }}
      </button>
    } @else if (data.icon && data.iconPosition === 'left') {
      <button mat-button [mat-dialog-close]="true">
        <mat-icon [fontIcon]="data.icon"></mat-icon>
        {{ data.action }}
      </button>
    }
  </mat-dialog-actions>
}

@if (!data.action && data.icon) {
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true">
      <mat-icon [fontIcon]="data.icon"></mat-icon>
    </button>
  </mat-dialog-actions>
}
