<div class="ws-emoji-insert">
  <mat-tab-group
    (click)="$event.stopPropagation()"
    animationDuration="0ms"
    disablePagination
    (mousedown)="$event.preventDefault()"
  >
    <mat-tab (mousedown)="$event.preventDefault()" label="😀">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Smileys & Emotion'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="👋">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('People & Body'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🐼">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Animals & Nature'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🍔">
      <div class="ws-emoji-insert-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Food & Drink'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🏡">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Travel & Places'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🎉">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Activities'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🔣">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Objects'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🚩">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Flags'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="☑️">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        @for (emoji of emojiMap.get('Symbols'); track emoji) {
          <button class="my-2" (mousedown)="emojiSelected($event, emoji)">
            <span class="emoji">{{ emoji }}</span>
          </button>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
