import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import {
  AdvancedFileUploadComponent,
  DialogComponent,
  WsDialogService,
  WsNotificationsService
} from '@ws-core/core-library'
import { ImageStorageService } from '../../../services/util/image-storage.service'
import { UploadedFile } from '../../../data/types/uploaded-file'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { EditorStateService } from '../../../services/util/editor-state.service'
import { debounceTime, Subscription } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { WsEmailEditImageDialogComponent } from '../ws-email-edit-image-dialog/ws-email-edit-image-dialog.component'

@Component({
  selector: 'app-ws-email-select-image-dialog',
  templateUrl: './ws-email-select-image-dialog.component.html',
  styleUrl: './ws-email-select-image-dialog.component.scss'
})
export class WsEmailSelectImageDialogComponent implements OnInit, OnDestroy {
  // @Input()
  @Input() showDialog: boolean = false
  @Input() currentImageSrc: string = ''

  // Simple types
  uploading: boolean = false
  selectedFile: File | null = null
  imageSelectDialogState: ImageSelectDialogState = ImageSelectDialogStates.Selection

  // Arrays of objects
  allImages: UploadedFile[] = []
  shownImages: UploadedFile[] = []
  imageDeleteLoadingState: { [key: string]: boolean } = {}

  // Variables with other decorations (e.g. @ViewChild)
  @ViewChild('fileUpload') fileUpload: AdvancedFileUploadComponent | undefined

  // FormGroup declarations
  linkInputFormControl = new FormControl('', [Validators.pattern(/^(https:\/\/.*|\$\{[^{}]*})$/)])
  searchFormControl = new FormControl('')
  selectedImagesFormGroup = new FormGroup({})

  private searchFormSubscription?: Subscription

  // @Output()
  @Output() showDialogChange = new EventEmitter<boolean>()
  @Output() imageSelected = new EventEmitter<string>()

  // Injectables
  readonly imageStorageService = inject(ImageStorageService)
  readonly editorStateService = inject(EditorStateService)
  readonly dialogService: WsDialogService = inject(WsDialogService)
  readonly notificationsService: WsNotificationsService = inject(WsNotificationsService)
  readonly dialog: MatDialog = inject(MatDialog)
  protected readonly ImageSelectDialogStates = ImageSelectDialogStates

  // Component lifecycle hooks that initialize
  ngOnInit() {
    this.searchFormSubscription = this.searchFormControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((searchTerm) => {
        this.searchMenuItems(searchTerm)
      })
  }

  ngOnDestroy() {
    this.searchFormSubscription?.unsubscribe()
  }

  openImageEditor(imageFile: File) {
    const dialogRef = this.dialog.open(WsEmailEditImageDialogComponent, {
      data: { imageFile: imageFile, config: { format: 'webp', disabled: true } }
    })

    dialogRef.afterClosed().subscribe((result: File) => {
      if (result) {
        const imageExists = this.doesImageExist(result.name)
        imageExists ? this.confirmAndUpload(result) : this.uploadImage(result)
      }
    })
  }

  protected onShowDialogChange($event: boolean) {
    this.showDialogChange.emit($event)
    this.imageSelectDialogState = ImageSelectDialogStates.Selection
    if ($event) {
      this.loadImages()
    }
  }

  protected onFileSelected(file: File | null) {
    this.selectedFile = file
  }

  protected onUploadImage() {
    if (!this.selectedFile) return
    const imageExists = this.doesImageExist(this.selectedFile.name)
    imageExists ? this.confirmAndUpload(this.selectedFile) : this.uploadImage(this.selectedFile)
  }

  protected onSelectImage(url: string | undefined) {
    if (url === undefined) {
      return
    }
    this.showDialog = false
    this.showDialogChange.emit(false)
    console.log(url)
    this.imageSelected.emit(url)
    this.editorStateService.updateViewOfAllElements()
  }

  protected onLinkSelected() {
    if (this.linkInputFormControl.valid && this.linkInputFormControl.value) {
      const url = this.linkInputFormControl.value
      this.onSelectImage(url)
    }
  }

  private doesImageExist(filename: string): boolean {
    return !!this.allImages.find((image) => image.filename === filename)
  }

  protected onImageMultiselect($event: MouseEvent | KeyboardEvent) {
    $event.stopPropagation()
  }

  protected onDeleteImages() {
    const selectedImages = Object.entries(this.selectedImagesFormGroup.value)
      .filter(([, value]) => value === true)
      .map(([key]) => key)
    this.deleteImages(selectedImages)
  }

  protected handleUseLinkButtonClickAction() {
    this.linkInputFormControl.setValue(this.currentImageSrc)
    this.imageSelectDialogState = ImageSelectDialogStates.LinkInput
  }

  private handleImageUploadResponse(mediaFile: UploadedFile) {
    const existingImage = this.allImages.find((image) => image.identifier === mediaFile.identifier)

    if (!existingImage) {
      this.allImages.push(mediaFile)
    } else {
      existingImage.url = mediaFile.url
    }

    this.resetUploadState()
    this.onSelectImage(mediaFile.url)
  }

  private resetUploadState() {
    this.selectedFile = null
    this.fileUpload?.removeFromFiles(0)
    this.uploading = false
    this.imageSelectDialogState = ImageSelectDialogStates.Selection
    this.shownImages = this.allImages
  }

  private searchMenuItems(searchTerm: string | null) {
    if (searchTerm === null) {
      this.shownImages = this.allImages
      return
    }
    this.shownImages = this.allImages.filter((image) =>
      image.filename?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  protected onDeleteImage($event: MouseEvent | KeyboardEvent, identifier: string) {
    $event.stopPropagation()

    this.deleteImages([identifier])
  }

  protected deleteImages(identifiers: string[]) {
    if (!identifiers.length) return
    identifiers.forEach((identifier) => {
      this.imageDeleteLoadingState[identifier] = true
    })

    this.imageStorageService.deleteImages(identifiers).subscribe({
      next: () => {
        this.loadImages()
        this.notificationsService.success('template.editor.image.delete.success')
        identifiers.forEach((identifier) => {
          this.imageDeleteLoadingState[identifier] = false
        })
      },
      error: () => {
        this.notificationsService.error('template.editor.image.delete.error')
        identifiers.forEach((identifier) => {
          this.imageDeleteLoadingState[identifier] = false
        })
      }
    })
  }

  private loadImages() {
    this.imageStorageService.getImages().subscribe({
      next: (images) => {
        images = images.sort((a, b) => {
          return new Date(b.modified!).getTime() - new Date(a.modified!).getTime()
        })
        this.allImages = images
        this.shownImages = images
        Object.entries(this.selectedImagesFormGroup.controls).forEach(([key]) => {
          this.selectedImagesFormGroup.removeControl(key)
        })
        this.allImages.forEach((image) => {
          this.selectedImagesFormGroup.addControl(image.identifier, new FormControl(false))
        })
      },
      error: () => {
        this.notificationsService.error('template.editor.image.overview.error')
      }
    })
  }

  private confirmAndUpload(image: File) {
    const dialog: DialogComponent = this.dialogService.confirm('template.editor.image.upload.confirmOverride')
    dialog.afterClose$.subscribe((action: string) => {
      if (action === 'confirm') this.uploadImage(image)
    })
  }

  private uploadImage(image: File) {
    this.uploading = true
    this.imageStorageService.uploadImage(image).subscribe({
      next: (mediaFile) => {
        this.handleImageUploadResponse(mediaFile)
      },
      error: () => {
        this.notificationsService.error('template.editor.image.upload.error')
        this.uploading = false
      }
    })
  }
}

export const ImageSelectDialogStates = {
  Selection: 'Selection',
  LinkInput: 'LinkInput',
  Upload: 'Upload'
} as const

export type ImageSelectDialogState = (typeof ImageSelectDialogStates)[keyof typeof ImageSelectDialogStates]
