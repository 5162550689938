<mat-tab-group
  [selectedIndex]="selectedTabIndex"
  (selectedIndexChange)="handleTabLanguageChange($event)"
  animationDuration="0ms"
  class="ws-translation-tabs"
  [class.ws-translation-tabs__hide-header]="hideTabHeader"
>
  @for (language of languages; track $index) {
    <mat-tab label="{{ language | uppercase }}">
      <ng-template matTabContent>
        @switch (tag) {
          @case ('textarea') {
            <ws-form-textarea
              [label]="label"
              [rows]="rows"
              [autosize]="autosize"
              (fieldBlur)="setErrorsOnInput()"
              [formControl]="singleInputFormControl"
            ></ws-form-textarea>
          }
          @case ('input') {
            <ws-form-input
              [label]="label"
              (fieldBlur)="setErrorsOnInput()"
              [formControl]="singleInputFormControl"
              [autofocus]="autofocus"
            ></ws-form-input>
          }
        }
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>
