import { Injectable } from '@angular/core'
import { AllowedFileType } from '../../types/forms/allowed-file-type'
import { AllowedFileTypes } from '../../types/forms/allowed-file-types'
@Injectable({
  providedIn: 'root'
})
export class WsFileUploadService {
  restrictFileTypes(allowedTypes: string[]): AllowedFileTypes {
    const allowedFileTypes: AllowedFileType[] = []
    allowedTypes = Array.from(new Set<string>(allowedTypes))
    for (const fileType of allowedTypes) {
      allowedFileTypes.push(new AllowedFileType(fileType, !(fileType.indexOf('*') > -1)))
    }
    return new AllowedFileTypes(allowedFileTypes, allowedTypes.join(', '))
  }
}
