{
  "addNew": "Add new",
  "back": "Back",
  "desktopPreview": "Desktop preview",
  "mobilePreview": "Mobile preview",
  "emailAddress": "Email address",
  "confirm": "Confirm",
  "bricks": "Bricks",
  "confirm": "Confirm",
  "cancel": "Cancel",
  "delete": "delete",
  "drafts": "Drafts",
  "edit": "edit",
  "element": {
    "errorSaveElementBlock": "Error saving block. Please try again.",
    "name": "Block name",
    "saveElementBlock": "Save block",
    "successSaveElementBlock": "Block saved successfully.",
    "type": {
      "column": "Column",
      "columns": "Columns",
      "divider": "Divider",
      "headline": "Headline",
      "image": "Image",
      "section": "Section",
      "sections": "Sections",
      "spacer": "Spacer"
    },
    "dropContentHint": "Drop Content here",
    "emptySection": "Empty section"
  },
  "emailBlock": {
    "delete": "delete brick",
    "deleteEmailBlockDialog": "Are you sure to delete this brick?",
    "deleteError": "Brick could not be deleted. Please try again later.",
    "deleteSuccess": "Brick deleted successfully"
  },
  "height": "Height",
  "layers": "Layers",
  "mobilePreview": "Mobile preview",
  "reset": "Reset",
  "save": "Save",
  "send": "send",
  "template": {
    "close": "Close",
    "configuration": {
      "configuration": "Configurations",
      "element": "Element",
      "hide": "Hide configuration",
      "open": "Show configuration",
      "overview": "Overview",
      "textSettings": "Text settings"
    },
    "content": "Content",
    "create": "Create template",
    "datasetChoiceDialog": "Choose dataset",
    "delete": "Delete template",
    "deleteTemplateDialog": "Are you sure to delete this template?",
    "duplicate": "Duplicate template",
    "edit": "Edit template",
    "editor": {
      "advancedView": "Advanced view",
      "container": "Add container",
      "dataObject": "Add data object",
      "earlierVersions": "Earlier versions",
      "editTestData": "Edit test data",
      "helpingGrid": "Helping grid",
      "image": {
        "add": "Add image",
        "delete": {
          "error": "Image could not be deleted.",
          "success": "Image successfully deleted."
        },
        "edit": {
          "flipHorizontally": "Flip horizontally",
          "flipVertically": "Flip vertically",
          "rotateLeft": "Rotate left",
          "rotateRight": "Rotate right"
        },
        "overview": {
          "error": "Images could not be loaded."
        },
        "upload": {
          "confirmOverride": "A image with this name already exists. Do you want to overwrite the image? You will probably have to reload the template.",
          "error": "Error while uploading image.",
          "upload": "Upload new image"
        },
        "useLink": "Use external link"
      },
      "label": "Add text field",
      "newDataObject": "{ data object }",
      "newTextField": "New text field",
      "pageCounter": "Add page counter",
      "placeholder": "Add placeholder",
      "preview": "PDF preview",
      "qrCode": "Add qr code",
      "recipient": "Recipient",
      "templateOverview": "All templates",
      "testData": {
        "addVariable": "Add new variable",
        "customVariable": "Custom variable",
        "edit": "Edit test data",
        "key": "Key",
        "predefinedVariable": "Predefined variable",
        "value": "Value"
      },
      "testDelivery": "Test delivery",
      "toggleHelpingGridOff": "Hide grid",
      "toggleHelpingGridOn": "Display grid",
      "unsavedChanges": "Unsaved changes will be lost",
      "unsavedChangesLost": "If you continue, your unsaved changes will be lost"
    },
    "element": {
      "alignment": "Element alignment",
      "background": "Background",
      "backgroundColor": "Background color",
      "backgroundImageUrl": "Background image URL",
      "border": "border",
      "borderColor": "color",
      "borderNone": "none",
      "borderRadius": "Border radius",
      "bottom": "Bottom",
      "boundary": "Element boundary",
      "content": "content",
      "copy": "copy",
      "dashed": "Dashed",
      "degrees": "degrees",
      "delete": "delete",
      "dotted": "Dotted",
      "font": "Font",
      "fontBold": "Bold",
      "fontColor": "Font color",
      "fontFamily": "Font family",
      "fontItalic": "Italic",
      "fontSize": "Font size",
      "fontStyle": "Font style",
      "fontWeight": "Font weight",
      "fullWidth": "Full width",
      "groupedColumns": "Display in one row on mobile device",
      "height": "Height",
      "hide": "hide",
      "hideOnMobile": "Hide on mobile device",
      "horiz": "Horiz.",
      "horizontal": "Horizontal",
      "icon-sizes": "Icons size",
      "imageAltText": "Alternative text",
      "imageUrl": "Image URL",
      "innerPadding": "Inner spacing",
      "layer": "Layer",
      "left": "Left",
      "mobile": "Mobile",
      "name": "Element name",
      "opacity": "opacity",
      "padding": "Spacing",
      "position": "position",
      "positionInfo": "of element's upper left corner",
      "radius": "Radius",
      "right": "Right",
      "rotation": "rotation",
      "scaleProportionally": "scale proportionally",
      "show": "show",
      "size": "size",
      "solid": "Solid",
      "style": "Style",
      "text": "text",
      "textAlign": "text align",
      "textAlignBottom": "align bottom",
      "textAlignCenter": "align center",
      "textAlignJustify": "justify",
      "textAlignLeft": "align left",
      "textAlignRight": "align right",
      "textAlignTop": "align top",
      "textFormat": "text format",
      "textLineSpacing": "Line height",
      "textTransformLowerCase": "lower case",
      "textTransformNone": "normal",
      "textTransformUpperCase": "upper case",
      "textUnderline": "Underline",
      "textWordBreak": "word break",
      "textWordBreakBreakAll": "all",
      "textWordBreakBreakWord": "words",
      "textWordBreakKeepAll": "none",
      "textWordBreakNormal": "normal",
      "top": "Top",
      "url": "URL",
      "vert": "Vert.",
      "vertical": "Vertical",
      "visibility": "visibility",
      "width": "Width",
      "widthUnitSelection": "Change unit of width",
      "zIndex": "Layer"
    },
    "editor": {
      "label": "Add text field",
      "placeholder": "Add placeholder",
      "advancedView": "Advanced view",
      "image": {
        "add": "Add image",
        "overview": {
          "error": "Images could not be loaded."
        },
        "upload": {
          "confirmOverride": "A image with this name already exists. Do you want to overwrite the image? You will probably have to reload the template.",
          "error": "Error while uploading image.",
          "upload": "Upload new image"
        },
        "useLink": "Use external link",
        "select": "Select image"
      },
      "qrCode": "Add qr code",
      "container": "Add container",
      "newDataObject": "{ data object }",
      "newTextField": "New text field",
      "preview": "PDF preview",
      "testData": {
        "addVariable": "Add new variable",
        "customVariable": "Custom variable",
        "edit": "Edit test data",
        "key": "Key",
        "label": "Test data",
        "predefinedVariable": "Predefined variable",
        "value": "Value"
      },
      "saveChanges": "Save Changes",
      "editTestData": "Edit test data",
      "pageCounter": "Add page counter",
      "dataObject": "Add data object",
      "templateOverview": "All templates",
      "helpingGrid": "Helping grid",
      "toggleHelpingGridOn": "Display grid",
      "toggleHelpingGridOff": "Hide grid",
      "testDelivery": "Test delivery",
      "recipient": "Recipient",
      "earlierVersions": "Earlier versions"
    },
    "fields": "Custom fields for template",
    "image": {
      "edit": "Edit image",
      "name": "Image name",
      "noImagesFound": "No images found",
      "select": "Select image",
      "source": "Image source",
      "noImagesFound": "No images found"
    },
    "field": {
      "add": "Add custom field",
      "key": "Key of field",
      "value": "Content",
      "deleteConfirm": "Are you sure to delete the field \"{{key}}\" in \"{{lang}}\"?",
      "delete": "Delete field"
    },
    "message": {
      "saveSuccess": "Template saved successfully",
      "saveNoSuccess": "Template could not be saved",
      "saveNoChanges": "Template has no modifications",
      "createSuccess": "Template created successfully",
      "createError": "Template could not be created",
      "invalidNameError": "Template could not be created. Template name is invalid.",
      "loadingError": "Loading templates failed",
      "doesNotExistError": "Template does not exist"
    }
  },
  "name": "Template name",
  "nameExample": "e.g. confirmation",
  "objectSettings": "Object",
  "rename": "Rename template",
  "save": "Save template",
  "saveChanges": "Save changes",
  "select": "Select template",
  "settings": {
    "displayFooterOnFirstPage": "display footer on first page only",
    "displayHeaderOnFirstPage": "display header on first page only",
    "displayPageCounter": "display page counter",
    "footer": "Footer",
    "header": "Header",
    "hidePageCounter": "hide page counter",
    "landscape": "landscape",
    "marginBottom": "Margin bottom",
    "marginLeft": "Margin left",
    "marginRight": "Margin right",
    "marginTop": "Margin top",
    "margins": "Page margins",
    "orientation": "Page orientation",
    "page": "Page",
    "pageLayout": "Page layout",
    "portrait": "portrait",
    "save": "Save settings",
    "settings": "Settings"
  },
  "subject": "Subject"
}
