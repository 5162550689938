import { Pipe, PipeTransform } from '@angular/core'
import { SelectOption } from '@ws-core/core-library'

@Pipe({
  name: 'selectedOptions'
})
export class SelectedOptionsPipe implements PipeTransform {
  transform(
    options: SelectOption[],
    selectedValue: any,
    multiple: boolean,
    compareWithFunction?: (o1: any, o2: any) => boolean
  ): SelectOption[] {
    if (multiple) {
      return options.filter((option) => {
        if (!selectedValue) return false

        if (compareWithFunction) {
          return selectedValue.some((selectedValue: SelectOption[]) => compareWithFunction(option.value, selectedValue))
        } else {
          return selectedValue.includes(option.value)
        }
      })
    } else {
      return options.filter((option) => {
        if (compareWithFunction) {
          return compareWithFunction(option.value, selectedValue)
        } else {
          return option.value === selectedValue
        }
      })
    }
  }
}
