{
  "align":  "center",
  "border-color": "#000000",
  "border-style":  "solid",
  "border-width": "4px",
  "container-background-color":  "",
  "css-class": "",
  "padding":  "10px",
  "padding-bottom": "",
  "padding-left":  "",
  "padding-right": "",
  "padding-top":  "",
  "width": "100%"
}
