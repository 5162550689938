@if (ngControl) {
  <div class="ws-text-editor w-full bg-white" [class.hide-subscript]="!hint">
    <div class="ws-text-editor_toolbar pt-1.5 pb-1 px-1 flex flex-wrap" (mousedown)="$event.preventDefault()">
      @if (formattingOptions.length > 0 && showFormattingOptions) {
        <div class="ws-text-editor_toolbar-button-group">
          <div
            class="flex items-center justify-between cursor-pointer w-36"
            [matMenuTriggerFor]="plainHTMLMode ? null : formattingSelection"
            (mousedown)="$event.preventDefault()"
          >
            <button mat-button>{{ formattingTypeOfSelection.label | translate }}</button>
            <mat-icon>expand_more</mat-icon>

            <mat-menu #formattingSelection="matMenu" class="ws-text-editor_format-menu">
              <ng-template matMenuContent>
                @for (formatting of formattingOptions; track $index) {
                  <button
                    mat-menu-item
                    (click)="selectTextFormattingType(formatting)"
                    (mousedown)="$event.preventDefault()"
                  >
                    <span [class]="formatting.value">
                      {{ formatting.label | translate }}
                    </span>
                  </button>
                }
              </ng-template>
            </mat-menu>
          </div>
        </div>
      }

      @if (customStyleSets.length > 0 || showColorFormattingOptions) {
        <div class="ws-text-editor_toolbar-button-group">
          @if (customStyleSets.length > 0) {
            <div
              class="flex items-center justify-between cursor-pointer w-36"
              [matMenuTriggerFor]="plainHTMLMode ? null : customStyleSelection"
              (mousedown)="$event.preventDefault()"
            >
              <button mat-button>{{ 'ws.forms.textEditor.selectCustomStyle' | translate }}</button>
              <mat-icon>expand_more</mat-icon>

              <mat-menu #customStyleSelection="matMenu" class="ws-text-editor_format-menu">
                <ng-template matMenuContent>
                  @for (customStyle of customStyleSets; track $index) {
                    <button
                      mat-menu-item
                      (click)="selectCustomStyleSet(customStyle)"
                      (mousedown)="$event.preventDefault()"
                    >
                      <span [ngClass]="customStyle.class" [ngStyle]="getStyles(customStyle)">
                        {{ customStyle.name | translate }}
                      </span>
                    </button>
                  }
                </ng-template>
              </mat-menu>
            </div>
          }

          @if (showColorFormattingOptions) {
            <div class="flex items-center relative overflow-hidden">
              <mat-icon
                class="ws-text-editor_toolbar-button ws-text-editor_color-selection ml-1.5 my-2 cursor-pointer"
                [style.--selected-color]="selectedTextColor"
                [matTooltip]="'ws.forms.textEditor.tooltip.useColor' | translate"
                (mousedown)="applyTextColor()"
                >match_case</mat-icon
              >
              <mat-icon
                class="z-10 cursor-pointer"
                (mousedown)="textColorPicker.openColorPicker()"
                [matTooltip]="'ws.forms.textEditor.tooltip.selectColor' | translate"
                >expand_more</mat-icon
              >
              <ws-form-color-picker
                #textColorPicker
                [compact]="true"
                [(ngModel)]="selectedTextColor"
                class="ws-text-editor_color-picker absolute top-0 left-0 opacity-0 pt-0 pointer-events-none"
                (colorSelected)="applyTextColor()"
              ></ws-form-color-picker>
            </div>
          }

          @if (customStyleSets.length > 0 || showColorFormattingOptions) {
            <mat-icon
              class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
              [matTooltip]="'ws.forms.textEditor.tooltip.removeFormat' | translate"
              [ngClass]="{
                disabled: !activeButtonsMap.get('span')
              }"
              (mousedown)="removeCustomStyle()"
              >format_color_reset</mat-icon
            >
          }
        </div>
      }

      @if (showTextFormattingOptions) {
        <div class="ws-text-editor_toolbar-button-group">
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.bold' | translate"
            [ngClass]="{ active: activeButtonsMap.get('bold'), disabled: plainHTMLMode }"
            (mousedown)="execCommand($event, 'bold')"
            >format_bold</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.italic' | translate"
            [ngClass]="{ active: activeButtonsMap.get('italic'), disabled: plainHTMLMode }"
            (mousedown)="execCommand($event, 'italic')"
            >format_italic</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.underline' | translate"
            [ngClass]="{ active: activeButtonsMap.get('underline'), disabled: plainHTMLMode }"
            (mousedown)="execCommand($event, 'underline')"
            >format_underlined</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.strikeThrough' | translate"
            [ngClass]="{ active: activeButtonsMap.get('strikeThrough'), disabled: plainHTMLMode }"
            (mousedown)="execCommand($event, 'strikeThrough')"
            >format_strikethrough</mat-icon
          >
        </div>
      }

      @if (showAlignmentFormattingOptions) {
        <div class="ws-text-editor_toolbar-button-group">
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.alignLeft' | translate"
            [ngClass]="{
              active: activeButtonsMap.get('justifyLeft'),
              disabled: activeButtonsMap.get('unorderedList') || activeButtonsMap.get('orderedList') || plainHTMLMode
            }"
            (mousedown)="execCommand($event, 'justifyLeft')"
            >format_align_left</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.alignCenter' | translate"
            [ngClass]="{
              active: activeButtonsMap.get('justifyCenter'),
              disabled: activeButtonsMap.get('unorderedList') || activeButtonsMap.get('orderedList') || plainHTMLMode
            }"
            (mousedown)="execCommand($event, 'justifyCenter')"
            >format_align_center</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.alignRight' | translate"
            [ngClass]="{
              active: activeButtonsMap.get('justifyRight'),
              disabled: activeButtonsMap.get('unorderedList') || activeButtonsMap.get('orderedList') || plainHTMLMode
            }"
            (mousedown)="execCommand($event, 'justifyRight')"
            >format_align_right</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.alignJustify' | translate"
            [ngClass]="{
              active: activeButtonsMap.get('justifyFull'),
              disabled: activeButtonsMap.get('unorderedList') || activeButtonsMap.get('orderedList') || plainHTMLMode
            }"
            (mousedown)="execCommand($event, 'justifyFull')"
            >format_align_justify</mat-icon
          >
        </div>
      }

      @if (showListFormattingOptions) {
        <div class="ws-text-editor_toolbar-button-group">
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.unorderedList' | translate"
            [ngClass]="{ active: activeButtonsMap.get('unorderedList'), disabled: plainHTMLMode }"
            (mousedown)="
              activeButtonsMap.get('unorderedList')
                ? removeList($event, 'ul')
                : activeButtonsMap.get('orderedList')
                  ? replaceList($event, 'ol')
                  : insertList($event, 'ul')
            "
            >format_list_bulleted</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.orderedList' | translate"
            [ngClass]="{ active: activeButtonsMap.get('orderedList'), disabled: plainHTMLMode }"
            (mousedown)="
              activeButtonsMap.get('orderedList')
                ? removeList($event, 'ol')
                : activeButtonsMap.get('unorderedList')
                  ? replaceList($event, 'ul')
                  : insertList($event, 'ol')
            "
            >format_list_numbered</mat-icon
          >
        </div>
      }

      @if (showLinkOptions) {
        <div class="ws-text-editor_toolbar-button-group">
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.link' | translate"
            [ngClass]="{ active: activeButtonsMap.get('link'), disabled: plainHTMLMode }"
            (mousedown)="insertLink($event)"
            >link</mat-icon
          >
          <mat-icon
            class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
            [matTooltip]="'ws.forms.textEditor.tooltip.unlink' | translate"
            [ngClass]="{ disabled: !activeButtonsMap.get('link') || plainHTMLMode }"
            (mousedown)="removeLink($event)"
            >link_off</mat-icon
          >
        </div>
      }

      @if (showSeparatorInsert || showEmojiInsert) {
        <div class="ws-text-editor_toolbar-button-group">
          @if (showSeparatorInsert) {
            <mat-icon
              class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
              [matTooltip]="'ws.forms.textEditor.tooltip.separator' | translate"
              [ngClass]="{ disabled: plainHTMLMode }"
              (mousedown)="insertHorizontalLine($event)"
              >horizontal_rule</mat-icon
            >
          }

          @if (showEmojiInsert) {
            <mat-icon
              class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
              [matTooltip]="'ws.forms.textEditor.tooltip.insertEmoji' | translate"
              [matMenuTriggerFor]="emojiMenu"
              (mousedown)="$event.preventDefault()"
              >mood</mat-icon
            >
          }

          <mat-menu #emojiMenu="matMenu" class="ws-text-editor_emoji-menu" (mousedown)="$event.preventDefault()">
            <ng-template matMenuContent>
              <ws-form-emoji-insert (emojiClicked)="insertEmoji($event)"></ws-form-emoji-insert>
            </ng-template>
          </mat-menu>
        </div>
      }

      <mat-icon
        class="ws-text-editor_toolbar-button ml-auto mx-1.5 my-2 cursor-pointer"
        [matTooltip]="
          (plainHTMLMode ? 'ws.forms.textEditor.tooltip.closeHtml' : 'ws.forms.textEditor.tooltip.showHtml') | translate
        "
        (mousedown)="switchInputMode()"
        >{{ plainHTMLMode ? 'code_off' : 'code' }}</mat-icon
      >
      <mat-icon
        class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
        [matTooltip]="'ws.forms.textEditor.tooltip.removeFormat' | translate"
        [ngClass]="{ disabled: plainHTMLMode }"
        (mousedown)="execCommand($event, 'removeFormat')"
        >format_clear</mat-icon
      >
      <mat-icon
        class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
        [matTooltip]="'ws.forms.textEditor.tooltip.undo' | translate"
        [ngClass]="{ disabled: contentHistory.length === 0 || plainHTMLMode }"
        (mousedown)="undo()"
        >undo</mat-icon
      >
      <mat-icon
        class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
        [matTooltip]="'ws.forms.textEditor.tooltip.redo' | translate"
        [ngClass]="{ disabled: restoredHistoryEntries.length === 0 || plainHTMLMode }"
        (mousedown)="redo()"
        >redo</mat-icon
      >
    </div>

    <div class="ws-text-editor_text-field-container">
      <textarea
        class="ws-text-editor_text-field p-4 w-full"
        [(ngModel)]="htmlString"
        [ngClass]="{ hidden: !plainHTMLMode }"
        (input)="updateValue()"
        [disabled]="true"
      ></textarea>

      <div
        class="ws-text-editor_text-field p-4"
        contenteditable
        #wsTextEditorContent
        (click)="selectionChanged($event)"
        (keyup)="selectionChanged()"
        (focusout)="resetActiveButtons()"
        (keydown.enter)="insertParagraph($event)"
        (keydown.tab)="$event.preventDefault()"
        (paste)="pasteText($event)"
        (keydown)="handleKeyDown($event)"
        (input)="updateValue()"
        (focus)="focusEditor()"
        [ngClass]="{ hidden: plainHTMLMode }"
      ></div>
    </div>
  </div>
}

<ng-template #addLinkDialogContent>
  <div class="flex flex-col p-4 md:p-8">
    <form [formGroup]="linkForm">
      <ws-form-input
        label="{{ 'ws.forms.textEditor.link' | translate }}"
        #linkDialogInput
        (keydown.enter)="$event.preventDefault(); linkDialog?.close('confirm')"
        formControlName="url"
      ></ws-form-input>
      <ws-form-checkbox
        label="{{ 'ws.forms.textEditor.openInNewTab' | translate }}"
        formControlName="blank"
      ></ws-form-checkbox>
    </form>
  </div>
</ng-template>
