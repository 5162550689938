import { Component, Inject, inject } from '@angular/core'
import { ImageCroppedEvent, ImageTransform, OutputFormat } from 'ngx-image-cropper'
import { FormControl, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-ws-email-edit-image-dialog',
  templateUrl: './ws-email-edit-image-dialog.component.html',
  styleUrl: './ws-email-edit-image-dialog.component.scss'
})
export class WsEmailEditImageDialogComponent {
  imageFile: File | null = null
  imageURL: string | null = null
  aspectRatio: number
  allowMoveImage: boolean
  maintainAspectRatio: boolean
  containWithinAspectRatio: boolean
  resizeToWidth: number
  cropperMinWidth: number
  cropperMinHeight: number
  format: OutputFormat
  alignImage: 'left' | 'center'
  roundCropper: boolean
  imageQuality: number
  transform: ImageTransform
  cropperHidden: boolean
  disabled: boolean
  loading: boolean = false

  croppedImage: Blob | string | null | undefined = null
  filenameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9 _-]*[a-zA-Z0-9])?$/ // no special characters except space, underscore, and hyphen
  readonly defaultConfig: ImageEditorConfig = {
    aspectRatio: 16 / 9,
    allowMoveImage: false,
    maintainAspectRatio: false,
    containWithinAspectRatio: true,
    resizeToWidth: 0,
    cropperMinWidth: 0,
    cropperMinHeight: 0,
    format: 'jpeg',
    alignImage: 'center',
    roundCropper: false,
    imageQuality: 92,
    transform: {},
    cropperHidden: false,
    disabled: false
  }

  fileNameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(100),
    Validators.pattern(this.filenameRegex)
  ])

  public dialogRef = inject(MatDialogRef<WsEmailEditImageDialogComponent>)

  constructor(@Inject(MAT_DIALOG_DATA) public data: ImageEditorDialogData) {
    const config = { ...this.defaultConfig, ...data.config }

    this.imageFile = data.imageFile || null
    this.imageURL = data.imageURL || null
    this.aspectRatio = config.aspectRatio
    this.allowMoveImage = config.allowMoveImage
    this.maintainAspectRatio = config.maintainAspectRatio
    this.containWithinAspectRatio = config.containWithinAspectRatio
    this.resizeToWidth = config.resizeToWidth
    this.cropperMinWidth = config.cropperMinWidth
    this.cropperMinHeight = config.cropperMinHeight
    this.format = config.format
    this.alignImage = config.alignImage
    this.roundCropper = config.roundCropper
    this.imageQuality = config.imageQuality
    this.transform = config.transform
    this.cropperHidden = config.cropperHidden
    this.disabled = config.disabled
    this.loading = true
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob
  }

  onImageLoaded() {
    this.loading = false
    this.fileNameFormControl.patchValue(this.getFilenameWithoutExtension())
  }

  saveImage() {
    if (!this.croppedImage || !this.fileNameFormControl.valid) return
    const fileName = this.fileNameFormControl.value + `.${this.format}`
    const fileType = `image/${this.format}`
    const imageFile = new File([this.croppedImage], fileName, { type: fileType })
    this.closeDialog(imageFile)
  }

  closeDialog(file?: File) {
    this.dialogRef.close(file)
  }

  rotateImage(degrees: number) {
    const currentRotation = this.transform.rotate ?? 0
    if (currentRotation === 360 || currentRotation === -360) {
      this.transform = { ...this.transform, rotate: degrees }
      return
    }
    this.transform = { ...this.transform, rotate: currentRotation + degrees }
  }

  resetImage() {
    this.transform = {}
    if (this.imageFile !== null) {
      const tempImage = this.imageFile
      this.imageFile = null
      setTimeout(() => {
        this.imageFile = tempImage
      }, 0)
    } else if (this.imageURL !== null) {
      const tempImage = this.imageURL
      this.imageURL = null
      setTimeout(() => {
        this.imageURL = tempImage
      }, 0)
    }
  }

  flipImageHorizontally() {
    this.transform = { ...this.transform, flipH: !this.transform.flipH }
  }

  flipImageVertically() {
    this.transform = { ...this.transform, flipV: !this.transform.flipV }
  }

  protected toggleCrop() {
    this.disabled = !this.disabled
  }

  private getFilenameWithoutExtension(): string {
    return this.imageFile?.name.split('.')[0] ?? Date.now().toString()
  }
}

interface ImageEditorConfig {
  aspectRatio: number
  allowMoveImage: boolean
  maintainAspectRatio: boolean
  containWithinAspectRatio: boolean
  resizeToWidth: number
  cropperMinWidth: number
  cropperMinHeight: number
  format: OutputFormat
  alignImage: 'left' | 'center'
  roundCropper: boolean
  imageQuality: number
  transform: ImageTransform
  cropperHidden: boolean
  disabled: boolean
}

interface ImageEditorDialogData {
  imageFile: File
  imageURL: string
  config: ImageEditorConfig
}
