import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { SelectOption } from '../../../types/forms/select-option'
import { OptionsFieldComponent } from '../options-field/options-field.component'
@Component({
  selector: 'ws-simple-input',
  templateUrl: './simple-input.component.html',
  styleUrls: ['./simple-input.component.scss']
})
export class SimpleInputComponent extends OptionsFieldComponent implements ControlValueAccessor, OnChanges {
  @Input() public icon = ''
  @Input() public placeholder = ''
  @Input() public type = 'text'

  public override value: string | number = ''
  public filteredOptions: SelectOption[] = []

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: string | number) {}

  public override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes)

    // updates autocomplete options if options change from @Input
    if (changes['options']) {
      this.setFilteredOptions(this.value)
    }
  }

  public override registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: string | number): void {
    this.value = value
  }

  public override onFieldChange(input: string | number) {
    super.onFieldChange(input)
    this.setFilteredOptions(input)
  }

  public setFilteredOptions(value: string | number) {
    if (value) {
      const searchInput = typeof value === 'string' ? value.toLowerCase() : value.toString()
      // filters options depending on their label because that's what the user sees
      this.filteredOptions = this.options.filter((option) => option.label.toLowerCase().startsWith(searchInput))
    } else {
      // reset options to empty array to hide autocomplete if no value given, to distinct input with autocomplete from select
      this.filteredOptions = []
    }
  }
}
