<span matSnackBarLabel>
  {{ data.message | translate }}
</span>

@if (data.action || data.icon) {
  <span matSnackBarActions>
    <button mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">
      @if (data.icon && data.iconPosition === 'right') {
        <mat-icon iconPositionEnd>{{ data.icon }}</mat-icon>
      }

      @if (data.icon && data.iconPosition === 'left') {
        <mat-icon>{{ data.icon }}</mat-icon>
      }

      @if (data.action) {
        {{ data.action | translate }}
      }
    </button>
  </span>
}
