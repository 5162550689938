import { Component, inject } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { wsCoreLibraryTranslations } from '@ws-core/core-library'
import { EmailService } from './services/email.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'email'

  private readonly translate: TranslateService = inject(TranslateService)
  private readonly emailService: EmailService = inject(EmailService)

  constructor() {
    this.translate.onLangChange.subscribe((langChangeEvent) => {
      this.translate.use(langChangeEvent.lang)
      document.documentElement.lang = langChangeEvent.lang
    })

    this.translate.setDefaultLang('de')

    const selectedLang = localStorage.getItem('ws|selectedLang')
    if (selectedLang) {
      this.translate.use(selectedLang)
    } else {
      const browserLang = this.translate.getBrowserLang()
      if (browserLang) {
        this.translate.use(browserLang)
      }
    }

    this.translate.setTranslation('en', wsCoreLibraryTranslations['en'])
    this.translate.setTranslation('de', wsCoreLibraryTranslations['de'])

    const url = new URL(window.location.href)
    const emailTempKeyParamName = 'token'
    const emailUserParamName = 'user'
    const tempApiKeyFromQueryParam = url.searchParams.get(emailTempKeyParamName) ?? ''
    const userFromQueryParam = url.searchParams.get(emailUserParamName) ?? ''

    if (tempApiKeyFromQueryParam) {
      this.emailService.setTempApiKey(tempApiKeyFromQueryParam)
      this.emailService.setUserId(userFromQueryParam)
    }
  }
}
