<mat-dialog-content [formGroup]="createTemplateForm">
  <h3>
    @if (templateToDuplicateId) {
      {{ 'template.duplicate' | translate }}
    } @else {
      {{ 'template.create' | translate }}
    }
  </h3>
  <ws-form-input label="{{ 'template.name' | translate }}" formControlName="templateName"></ws-form-input>
</mat-dialog-content>

<div class="flex flex-wrap justify-end">
  <mat-dialog-actions>
    <ws-button (click)="close()" [flat]="true">
      {{ 'template.close' | translate }}
    </ws-button>

    <ws-button [disabled]="saving" [flat]="true" (click)="save()" class="bg-accent text-white">
      @if (saving) {
        <ws-icon class="animate-spin h-5 w-5 mr-3">refresh</ws-icon>
      } @else {
        {{ 'template.save' | translate }}
      }
    </ws-button>
  </mat-dialog-actions>
</div>
