<div *ngIf="selectedIndex === 0" class="wsemail-editor-btn-wrapper flex flex-row justify-between p-4 bg-primary">
  <div class="wsemail-editor-btn-left-wrapper pt-1">
    <div class="flex justify-start gap-4 items-center">
      <!--      <button mat-icon-button matTooltipPosition="below" matTooltip="Add text field">-->
      <!--        <ws-icon>text_fields</ws-icon>-->
      <!--      </button>-->
      <!--      <button mat-icon-button matTooltip="Add Placeholder">-->
      <!--        <mat-icon>data_object</mat-icon>-->
      <!--      </button>-->
      <!--      <button mat-icon-button matTooltip="Add image">-->
      <!--        <mat-icon>add_photo_alternate</mat-icon>-->
      <!--      </button>-->
      <!--      <button mat-icon-button matTooltip="Add link">-->
      <!--        <mat-icon>link</mat-icon>-->
      <!--      </button>-->
      <!--      <button mat-icon-button matTooltip="Add button">-->
      <!--        <mat-icon>check_box_outline_blank</mat-icon>-->
      <!--      </button>-->

      <div>
        <img
          class="h-[55px] object-contain w-auto"
          src="/assets/icons/ws_icon.svg"
          alt="LOGO"
          width="132"
          height="120"
        />
      </div>
      <button
        class="mx-1 px-2"
        mat-button
        [disabled]="loadingTemplateHistory || templateHistory.length === 0"
        [matMenuTriggerFor]="formattingSelection"
      >
        <div class="flex items-center text-white">
          <mat-icon class="mr-2">history</mat-icon>
          {{ 'template.editor.earlierVersions' | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </button>
      <mat-menu class="max-w-none" #formattingSelection="matMenu">
        @for (historyEntry of templateHistory; track $index) {
          <button (click)="onHistoryVersionSelect(historyEntry)" mat-menu-item>
            <span class="flex items-center justify-between whitespace-nowrap">
              <mat-icon color="accent">restart_alt</mat-icon>
              <strong class="mr-4">v{{ templateHistory.length - $index }}</strong>
              <span>{{ historyEntry.created | date: 'd.M.Y HH:mm' }}</span>
            </span>
          </button>
        }
      </mat-menu>
      <div class="wsemail-editor__template-language-version-tabs">
        @for (language of languages; track language) {
          <div
            class="tab flex items-center cursor-pointer text-white"
            [ngClass]="{
              'active-tab': currentLanguage === language,
              'no-template-tab': language !== 'de' && !wsEmailTemplate.mjml[language]
            }"
            (click)="onTranslationTabClick(language); $event.stopPropagation()"
          >
            <span class="uppercase">{{ language }}</span>

            @if (language !== 'de' && wsEmailTemplate.mjml[language]) {
              <ws-icon (click)="resetTemplate(language); $event.stopPropagation()" class="text-red-600 ml-1" size="14px"
                >close
              </ws-icon>
            }
          </div>
        }
      </div>
    </div>
  </div>

  <div class="wsemail-editor-config-toogle flex items-center">
    <!--    <button (click)="openAiDialog()" class="mx-2" mat-button>-->
    <!--      <mat-icon>robot_2</mat-icon>-->
    <!--      Ai Template bearbeiten-->
    <!--    </button>-->
    <ws-button (click)="openEmailTestDeliveryDialog()" class="mx-2 text-white" [flat]="true">
      <mat-icon class="mr-2">mail</mat-icon>
      {{ 'template.editor.testDelivery' | translate }}
    </ws-button>

    <ws-button (click)="openEditTestDataDialog()" class="mx-2 text-white" [flat]="true">
      <mat-icon class="mr-2">code</mat-icon>
      Testdatensatz bearbeiten
    </ws-button>
    <ws-button
      (click)="undoAction()"
      [disabled]="!undoRedoService.undoAvailable() || processingUndoRedo"
      [ngClass]="{ 'opacity-0': !undoRedoService.undoAvailable() }"
      class="text-white"
      [flat]="true"
    >
      <mat-icon>undo</mat-icon>
    </ws-button>
    <ws-button
      (click)="redoAction()"
      [disabled]="!undoRedoService.redoAvailable() || processingUndoRedo"
      [ngClass]="{ 'opacity-0': !undoRedoService.redoAvailable() }"
      class="text-white"
      [flat]="true"
    >
      <mat-icon>redo</mat-icon>
    </ws-button>
    <ws-button (click)="onGuidelinesClick()" class="mx-2 text-white" [flat]="true">
      <mat-icon class="mr-2">visibility</mat-icon>
      Hilfslinien
    </ws-button>
    <!--    <button (click)="copyCodeToClipboard(template.getHtml())" class="mx-2" mat-button>-->
    <!--      <mat-icon>code</mat-icon>-->
    <!--      Copy HTML-->
    <!--    </button>-->
    <!--    <button (click)="copyCodeToClipboard(template.getMjml())" class="mx-2" mat-button>-->
    <!--      <mat-icon>code</mat-icon>-->
    <!--      Copy MJML-->
    <!--    </button>-->
    <ws-button (click)="saveEmailTemplate()" [flat]="true" class="mx-2 bg-accent text-white">
      <span class="">{{ 'template.saveChanges' | translate }}</span>
    </ws-button>
    <ws-button
      class="mx-2 px-2 text-white"
      *ngIf="!sidenav.opened"
      (click)="sidenav.toggle()"
      matTooltip="{{ 'template.configuration.open' | translate }}"
      [flat]="true"
    >
      <mat-icon>first_page</mat-icon>
    </ws-button>
    <ws-button
      class="mx-2 px-2 text-white"
      *ngIf="sidenav.opened"
      (click)="sidenav.toggle()"
      matTooltip="{{ 'template.configuration.hide' | translate }}"
      [flat]="true"
    >
      <mat-icon>last_page</mat-icon>
    </ws-button>
  </div>
</div>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    [style.width.px]="360"
    class="rounded sidenav-shadow"
    mode="side"
    position="start"
    [opened]="selectedIndex === 0"
  >
    <mat-tab-group>
      <mat-tab class="p-4">
        <ng-template mat-tab-label>
          <mat-icon class="mr-2 text-accent">dashboard_customize</mat-icon>
          Layout
        </ng-template>
        <div class="ws-email-block-list p-4 bg-white">
          <div
            id="newSectionDroplist"
            [cdkDropListConnectedTo]="template.id"
            [cdkDropListData]="getNewSectionsValues()"
            cdkDropListSortingDisabled
            cdkDropList
            class="droplist-for-sections"
          >
            <h1 class="element-settings-title">{{ 'element.type.sections' | translate }}</h1>
            @for (section of newSections; track section.value.id) {
              <div
                (cdkDragStarted)="dragAndDropService.onDragStarted()"
                [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
                cdkDrag
                [cdkDragData]="section.value"
                (cdkDragDropped)="onDragDropped(section.value)"
                class="mb-5"
              >
                <div *cdkDragPlaceholder></div>
                @if (section.value.columnHasWidths) {
                  <div class="font-invisible text-gray-500 h-16 flex">
                    @for (col of section.value.getChildren(); track col.id) {
                      <div
                        class="flex justify-center items-center h-full w-full border border-gray-300"
                        [style.width]="col.styles['width']"
                      >
                        {{ col.styles['width'] }}
                      </div>
                    }
                  </div>
                } @else {
                  <div
                    [class]="'font-invisible text-gray-500 h-16 grid grid-cols-' + section.value.getChildren().length"
                  >
                    @for (col of section.value.getChildren(); track col.id) {
                      <div
                        class="flex items-center justify-center text-center w-full border border-gray-300"
                        [style.width]="col.styles['width']"
                      >
                        <div>
                          @if (section.value.getChildren().length === 3) {
                            {{ (100 / section.value.getChildren().length | number: '1.2-2') + '%' }}
                          } @else {
                            {{ 100 / section.value.getChildren().length + '%' }}
                          }
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
          <div
            id="newColumnDroplist"
            [cdkDropListConnectedTo]="getSectionsIds"
            [cdkDropListData]="[newColumn]"
            cdkDropListSortingDisabled
            cdkDropList
            class="droplist-for-columns mt-8 mb-4 text-sm"
          >
            <h1 class="element-settings-title mb-2">{{ 'template.content' | translate }}</h1>
            <div
              (cdkDragDropped)="onDragDropped(newColumn)"
              (cdkDragStarted)="dragAndDropService.onDragStarted()"
              cdkDrag
              [cdkDragData]="newColumn"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">grid_view</mat-icon>
              {{ 'element.type.column' | translate }}
            </div>
          </div>
          <div
            id="columnContentDropList"
            [cdkDropListConnectedTo]="getColumnIds"
            [cdkDropListData]="[newImage, newButton, newHeadline, newText, newSocial, newDivider, newSpacer]"
            cdkDropListSortingDisabled
            cdkDropList
            class="droplist-for-column-content grid grid-cols-3 gap-4 my-2 text-sm"
          >
            <div
              (cdkDragDropped)="onDragDropped(newImage)"
              (cdkDragStarted)="dragAndDropService.onDragStarted()"
              cdkDrag
              [cdkDragData]="newImage"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">image</mat-icon>
              {{ 'element.type.image' | translate }}
            </div>
            <div
              (cdkDragDropped)="onDragDropped(newButton)"
              (cdkDragStarted)="onButtonDragStarted()"
              cdkDrag
              [cdkDragData]="newButton"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">smart_button</mat-icon>
              Button
            </div>
            <div
              (cdkDragDropped)="onDragDropped(newHeadline)"
              (cdkDragStarted)="dragAndDropService.onDragStarted()"
              [cdkDragData]="newHeadline"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              cdkDrag
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">Title</mat-icon>
              {{ 'element.type.headline' | translate }}
            </div>
            <div
              (cdkDragDropped)="onDragDropped(newText)"
              (cdkDragStarted)="dragAndDropService.onDragStarted()"
              cdkDrag
              [cdkDragData]="newText"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">Title</mat-icon>
              Text
            </div>
            <div
              (cdkDragDropped)="onDragDropped(newSocial)"
              (cdkDragStarted)="dragAndDropService.onDragStarted()"
              [cdkDragData]="newSocial"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              cdkDrag
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">group</mat-icon>
              Social
            </div>
            <div
              (cdkDragDropped)="onDragDropped(newDivider)"
              (cdkDragStarted)="dragAndDropService.onDragStarted()"
              cdkDrag
              [cdkDragData]="newDivider"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">horizontal_rule</mat-icon>
              {{ 'element.type.divider' | translate }}
            </div>
            <div
              (cdkDragDropped)="onDragDropped(newSpacer)"
              (cdkDragStarted)="dragAndDropService.onDragStarted()"
              cdkDrag
              [cdkDragData]="newSpacer"
              [ngClass]="{ 'cursor-pointer': !dragAndDropService.dragging }"
              class="rounded-lg w-22 h-20 bg-gray-100 shadow-md hover:border-accent flex flex-col justify-center items-center"
            >
              <mat-icon class="text-accent">Crop_3_2</mat-icon>
              {{ 'element.type.spacer' | translate }}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab class="p-4">
        <ng-template mat-tab-label>
          <mat-icon class="mr-2 text-accent">dashboard_customize</mat-icon>
          {{ 'bricks' | translate }}
        </ng-template>
        <app-ws-email-default-blocks
          [sectionIds]="getSectionsIds"
          [columnIds]="getColumnIds"
          [template]="template"
          (templateChange)="startAutoSaveProcess()"
        ></app-ws-email-default-blocks>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="wsemail-editor-outer-container" (click)="editorStateService.unselectSelectedElement()">
      <mat-tab-group
        [selectedIndex]="selectedIndex"
        (selectedTabChange)="onEditorTabChange($event)"
        class="wsemail-editor-tab-group"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        animationDuration="0ms"
      >
        <mat-tab class="wsemail-editor-tab">
          <ng-template mat-tab-label class="border-primary border-2">
            <mat-icon class="mr-2 text-accent">dvr</mat-icon>
            Editor
          </ng-template>
          <div class="wsemail-editor-desktop-container w-full flex justify-center">
            <div [style.width.px]="editorWidth">
              <app-ws-email-editor
                [ngClass]="{ 'ws-email-editor-mobile': editorWidth === deviceWidthOptions.mobile }"
                [template]="template"
                class="wsemail-editor h-full"
              ></app-ws-email-editor>
            </div>
          </div>
        </mat-tab>
        <!--        Mobile Editor Version-->
        <!--        <mat-tab class="wsemail-editor-tab">-->
        <!--          <ng-template mat-tab-label>-->
        <!--            <mat-icon class="mr-2">smartphone</mat-icon>-->
        <!--            Editor Mobil-->
        <!--          </ng-template>-->
        <!--          <div class="wsemail-editor-desktop-container w-full flex justify-center">-->
        <!--            <div [style.width.px]="deviceWidthOptions.mobile">-->
        <!--              <app-ws-email-editor-->
        <!--                [template]="template"-->
        <!--                class="wsemail-editor ws-email-editor-mobile h-full"-->
        <!--              ></app-ws-email-editor>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </mat-tab>-->
        <mat-tab class="wsemail-editor-desktop-preview-tab">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2 text-accent">desktop_mac</mat-icon>
            {{ 'desktopPreview' | translate }}
          </ng-template>
          <div class="wsemail-editor-desktop-preview-container flex justify-center">
            @if (templatePreview) {
              <iframe
                #desktopPreviewIframe
                *ngIf="selectedIndex === 1"
                [style.width.px]="deviceWidthOptions.desktop"
                [srcdoc]="templatePreview | safeHtml"
              ></iframe>
            }
          </div>
        </mat-tab>
        <mat-tab class="wsemail-editor-mobile-preview-tab">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2 text-accent">smartphone</mat-icon>
            {{ 'mobilePreview' | translate }}
          </ng-template>
          <div class="ws-email-mobile-preview-container flex justify-center">
            <div class="ws-email-mobile-preview">
              @if (templatePreview) {
                <iframe
                  #mobilePreviewIframe
                  *ngIf="selectedIndex === 2"
                  [style.width.px]="deviceWidthOptions.mobile"
                  [srcdoc]="templatePreview | safeHtml"
                ></iframe>
              }
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-sidenav-content>

  <mat-sidenav
    #sidenav
    [style.width.px]="360"
    class="rounded bg-white sidenav-shadow"
    mode="side"
    position="end"
    [opened]="selectedIndex === 0"
  >
    <!--    <div>-->
    <!--      <mat-tab-group [(selectedIndex)]="selectedTemplateTab">-->
    <!--        <mat-tab class="p-4">-->
    <!--          <ng-template mat-tab-label>-->
    <!--            <mat-icon class="mr-2">dashboard_customize</mat-icon>-->
    <!--            {{ 'drafts' | translate }}-->
    <!--          </ng-template>-->
    <!--          <div class="template-drafts p-4">-->
    <!--            <div class="template-drafts-list">-->
    <!--              @for (template of drafts | keyvalue; track $index) {-->
    <!--                <div-->
    <!--                  class="template-drafts-list-item bg-white border border-gray-300 shadow-md hover:border-blue-500"-->
    <!--                  (click)="chooseTemplate(template.key)"-->
    <!--                >-->
    <!--                  {{ template.key.name }}-->
    <!--                  <iframe-->
    <!--                    appIframe-->
    <!--                    (iframeClick)="chooseTemplate(template.key)"-->
    <!--                    resizeWidth="100%"-->
    <!--                    resizeHeight="500px"-->
    <!--                    scrolling="no"-->
    <!--                    [srcdoc]="template.value || '' | safeHtml: { removeHref: true }"-->
    <!--                  ></iframe>-->
    <!--                </div>-->
    <!--              }-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </mat-tab>-->
    <!--        <mat-tab>-->
    <!--          <ng-template mat-tab-label>-->
    <!--            <mat-icon class="mr-2">layers</mat-icon>-->
    <!--            {{ 'layers' | translate }}-->
    <!--          </ng-template>-->
    <!--          <div class="ws-email-layers">-->
    <!--            <app-ws-email-layers-->
    <!--              (selectedElementChange)="startAutoSaveProcess()"-->
    <!--              [selectedElement]="selectedElement"-->
    <!--              [template]="template"-->
    <!--            ></app-ws-email-layers>-->
    <!--          </div>-->
    <!--        </mat-tab>-->
    <!--      </mat-tab-group>-->
    <!--    </div>-->
    <div class="element-settings bg-white">
      <mat-tab-group [(selectedIndex)]="selectedSettingsTab">
        @if (!selectedElement || !['Text', 'Button'].includes(selectedElement.type)) {
          <mat-tab class="p-4">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-2 text-accent">settings</mat-icon>
              Body
            </ng-template>
            <app-ws-email-page-settings
              (pageSettingsChange)="startAutoSaveProcess()"
              [template]="template"
              [templateStyles]="template.styles"
            ></app-ws-email-page-settings>
          </mat-tab>
        }
        @if (!!selectedElement) {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-2 text-accent">tune</mat-icon>
              {{ 'template.configuration.element' | translate }}
            </ng-template>
            <app-ws-email-element-settings
              (selectedElementPropertyChange)="startAutoSaveProcess()"
              [selectedElement]="selectedElement"
            ></app-ws-email-element-settings>
          </mat-tab>
        }
        @if (selectedElement?.type === 'Text' || selectedElement?.type === 'Button') {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-2 text-accent">format_size</mat-icon>
              {{ 'template.configuration.textSettings' | translate }}
            </ng-template>
            <app-ws-email-text-settings
              (selectedElementTextPropertyChange)="startAutoSaveProcess()"
              [selectedElement]="selectedElement"
            ></app-ws-email-text-settings>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<ng-template #testDeliveryEmailDialog>
  <div class="p-4">
    <div class="container" [formGroup]="testDeliveryForm">
      <h3>{{ 'template.editor.testDelivery' | translate }}</h3>

      <ws-form-input
        class="my-2"
        label="{{ 'template.editor.recipient' | translate }}"
        formControlName="from"
        placeholder="{{ 'emailAddress' | translate }}"
      ></ws-form-input>
    </div>
  </div>
</ng-template>
<ng-template #testDeliveryEmailDialogAction>
  <div class="p-5 flex flex-wrap justify-end">
    <ws-button (click)="testEmailDeliveryDialog?.close('close')" [flat]="true" class="text-grey-300">
      {{ 'template.close' | translate }}
    </ws-button>
    <ws-button
      (click)="closeTestDeliveryEmailDialog('send')"
      [disabled]="testDeliveryForm.invalid"
      [flat]="true"
      class="bg-accent text-white"
    >
      {{ 'send' | translate }}
    </ws-button>
  </div>
</ng-template>
