import { Brick } from './brick'
import { StyleAttributes } from './brick.types'

export abstract class BrickComposite extends Brick {
  override children: Brick[] = []
  override canHaveChildren = true

  protected constructor(type = '', content = '', styles: StyleAttributes = {}, mjmlTag = '', name = '') {
    super(type, content, styles, mjmlTag, name)
  }

  override renewIds() {
    this.id = this.generateId()
    this.children.forEach((child) => child.renewIds())
  }

  override getMjml(withoutHiddenBricks = false): string {
    if (this.isHidden() && withoutHiddenBricks) return ''
    const children = withoutHiddenBricks ? this.getVisibleChildren() : this.children

    const styles = Object.keys(this.styles)
      .map((style) => ` ${style}="${this.styles[style]}"`)
      .join('')

    const childrenMjml = children.map((child) => child.getMjml(withoutHiddenBricks)).join('\n')

    return `<${this.mjmlTag}${styles}>\n${childrenMjml}\n</${this.mjmlTag}>`
  }

  override getMjmlWithGlobalStyles(globalStyles: StyleAttributes = {}): string {
    const children = this.children

    const styles = Object.keys(this.styles)
      .map((style) => ` ${style}="${this.styles[style]}"`)
      .join('')

    const childrenMjml = children.map((child) => child.getMjmlWithGlobalStyles(globalStyles)).join('\n')

    return `<${this.mjmlTag}${styles}>\n${childrenMjml}\n</${this.mjmlTag}>`
  }

  override add(brick: Brick) {
    this.children.push(brick)
  }

  override remove(brick: Brick) {
    this.children = this.children.filter((child) => child !== brick)
  }

  override getChildren(): Brick[] {
    return this.children
  }

  override setChildren(children: Brick[]) {
    this.children = children
  }

  override getVisibleChildren(): Brick[] {
    return this.children.filter((child) => !child.isHidden())
  }
}
