<mat-dialog-content class="image-editor-content h-full">
  <div class="ws-image-cropper__buttons">
    <button
      (click)="toggleCrop()"
      class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
      matTooltip="{{ 'template.editor.image.edit.crop' | translate }}"
    >
      <mat-icon>crop</mat-icon>
    </button>
    <button
      (click)="rotateRight()"
      class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
      matTooltip="{{ 'template.editor.image.edit.rotateRight' | translate }}"
    >
      <mat-icon>rotate_90_degrees_cw</mat-icon>
    </button>
    <button
      (click)="rotateLeft()"
      class="mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
      matTooltip="{{ 'template.editor.image.edit.rotateLeft' | translate }}"
    >
      <mat-icon>rotate_90_degrees_ccw</mat-icon>
    </button>
    <button
      (click)="flipImageHorizontally()"
      class="mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
      matTooltip="{{ 'template.editor.image.edit.flipHorizontally' | translate }}"
    >
      <mat-icon>flip</mat-icon>
    </button>
    <button
      (click)="flipImageVertically()"
      class="mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
      matTooltip="{{ 'template.editor.image.edit.flipVertically' | translate }}"
    >
      <mat-icon class="transform rotate-90">flip</mat-icon>
    </button>
    <button
      (click)="resetImage()"
      class="mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
      matTooltip="{{ 'reset' | translate }}"
    >
      <mat-icon>reset_image</mat-icon>
    </button>
  </div>
  <div [ngClass]="!loading ? 'hidden' : ''" class="absolute top-0 left-0 h-full w-full bg-white opacity-50 z-10">
    <mat-spinner class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" diameter="32"></mat-spinner>
  </div>
  <div class="image-cropper-container flex items-center justify-center mx-auto">
    <image-cropper
      (imageCropped)="onImageCropped($event)"
      (imageLoaded)="onImageLoaded()"
      [alignImage]="alignImage"
      [allowMoveImage]="allowMoveImage"
      [aspectRatio]="aspectRatio"
      [canvasRotation]="canvasRotation"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [cropperMinHeight]="cropperMinHeight"
      [cropperMinWidth]="cropperMinWidth"
      [disabled]="disabled"
      [format]="format"
      [hidden]="cropperHidden"
      [imageFile]="imageFile ? imageFile : undefined"
      [imageQuality]="imageQuality"
      [imageURL]="imageURL ? imageURL : undefined"
      [maintainAspectRatio]="maintainAspectRatio"
      [resizeToWidth]="resizeToWidth"
      [roundCropper]="roundCropper"
      [transform]="transform"
      output="blob"
    ></image-cropper>
  </div>
  <div>
    <ws-form-input
      [formControl]="fileNameFormControl"
      label="{{ 'template.image.name' | translate }}"
      suffix=".{{ format }}"
    ></ws-form-input>
  </div>
</mat-dialog-content>

<div class="flex justify-between" mat-dialog-actions>
  <button (click)="saveImage()" class="px-2" color="primary" mat-flat-button>
    {{ 'save' | translate }}
    <mat-icon>check</mat-icon>
  </button>
  <button (click)="closeDialog()" mat-button>
    {{ 'template.close' | translate }}
    <mat-icon>close</mat-icon>
  </button>
</div>
