import { NgModule } from '@angular/core'
import { DialogDirective } from '../../directives/dialog.directive'
import { DialogComponent } from './dialog/dialog.component'
import { DialogContainerComponent } from './dialog-container/dialog-container.component'
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component'
import { SnackbarComponent } from './snackbar/snackbar.component'

@NgModule({
  declarations: [],
  imports: [DialogDirective, DialogComponent, DialogContainerComponent, NotificationDialogComponent, SnackbarComponent],
  exports: [DialogDirective, DialogComponent, DialogContainerComponent, NotificationDialogComponent, SnackbarComponent]
})
export class WsNotificationsModule {}
