<mat-dialog-content>
  <div>
    <h3 class="flex">{{ 'template.deleteTemplateDialog' | translate }}</h3>
    {{ this.data }}
  </div>
</mat-dialog-content>

<div class="flex flex-wrap justify-end">
  <mat-dialog-actions>
    <ws-button class="mat-button" (click)="close()" [flat]="true">
      {{ 'template.close' | translate }}
    </ws-button>
    <ws-button class="bg-accent text-white" (click)="save()" [flat]="true">
      {{ 'template.delete' | translate }}
    </ws-button>
  </mat-dialog-actions>
</div>
