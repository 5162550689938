import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { Template } from '../../../data/models/template'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { fontOptions } from '../ws-email-text-settings/ws-email-text-settings.component'
import { EditorStateService } from '../../../services/util/editor-state.service'

@Component({
  selector: 'app-ws-email-page-settings',
  templateUrl: './ws-email-page-settings.component.html',
  styleUrl: './ws-email-page-settings.component.scss'
})
export class WsEmailPageSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() template?: Template
  @Input() templateStyles: { [key: string]: any } = {}
  @Output() pageSettingsChange = new EventEmitter()
  timeoutPropertyChange?: number
  colorValidator: ValidatorFn = Validators.pattern(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$|^\$\{[a-zA-Z_][a-zA-Z0-9_-]*}$/)
  pageSettingsForm: FormGroup = new FormGroup({})
  valueChangesSubscription?: Subscription
  protected readonly fontOptions = fontOptions

  ngOnInit(): void {
    this.initPageSettingsForm()
  }

  constructor(private editorStateService: EditorStateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['template'] || changes['templateStyles']) {
      this.initPageSettingsForm()
    }
  }

  reset(property: string) {
    this.pageSettingsForm.patchValue({ [property]: '' })
  }

  initPageSettingsForm() {
    const formControlFields = {
      'background-color': new FormControl(this.template?.styles['background-color'] || '', [this.colorValidator]),
      'font-family': new FormControl(this.template?.styles['font-family'] || ''),
      color: new FormControl(this.template?.styles['color'] || '', [this.colorValidator]),
      'link-color': new FormControl(this.template?.styles['link-color'] || '', [this.colorValidator])
    }
    this.pageSettingsForm = new FormGroup(formControlFields)

    this.valueChangesSubscription?.unsubscribe()

    this.valueChangesSubscription = this.pageSettingsForm.valueChanges.subscribe((value) => {
      Object.keys(value).forEach((field) => {
        if (this.pageSettingsForm.get(field)?.errors || this.pageSettingsForm == undefined) return
        if (this.template == undefined) return
        this.template.styles[field] = value[field]
      })
      clearTimeout(this.timeoutPropertyChange)
      this.timeoutPropertyChange = setTimeout(() => {
        this.pageSettingsChange.emit()
      }, 500)
      this.editorStateService.updateViewOfAllElements()
    })
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe()
  }
}
