import { Component, inject } from '@angular/core'
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarRef
} from '@angular/material/snack-bar'
import { NotificationData } from '../../../types/notifications/notification-data'
import { TranslateModule } from '@ngx-translate/core'
import { MatButton } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'ws-snackbar',
  standalone: true,
  templateUrl: './snackbar.component.html',
  imports: [MatSnackBarLabel, MatSnackBarActions, TranslateModule, MatButton, MatSnackBarAction, MatIconModule],
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  public snackBarRef: MatSnackBarRef<SnackbarComponent> = inject(MatSnackBarRef)
  public data: NotificationData = inject(MAT_SNACK_BAR_DATA)
}
