import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'
import { WsDialogService, WsNotificationsService } from '@ws-core/core-library'
import { TranslateService } from '@ngx-translate/core'
import { first } from 'rxjs'
import { Brick } from '../../../data/models/brick'
import { Template } from '../../../data/models/template'
import { BrickFactoryService } from '../../../services/util/brick-factory.service'
import { EditorStateService } from '../../../services/util/editor-state.service'
import { EmailService } from '../../../services/email.service'
import { CdkDragAndDropService } from '../../../services/util/cdk-drag-and-drop.service'

@Component({
  selector: 'app-ws-email-element-wrapper',
  templateUrl: './ws-email-element-wrapper.component.html',
  styleUrl: './ws-email-element-wrapper.component.scss'
})
export class WsEmailElementWrapperComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ContentChild('textContainer') textContainer?: ElementRef
  @ViewChild('textEditor') textEditor?: any
  @ViewChild('simpleDialogContentTemplateRef', { static: true })
  simpleDialogContentTemplateRef!: TemplateRef<Element>
  @Input() component?: WsEmailElementComponent
  @Input() parent?: Brick
  @Input() element?: Brick
  @Input() dragHandle = true
  @Input() elementIsActive = false
  @Output() elementIsActiveChange = new EventEmitter<boolean>()

  @Input() dragDisabled: boolean = false
  @Input() template?: Template
  @Input() showTextEditor!: boolean
  @Input() elementIsSelectable = true

  blockElementName: string = ''

  constructor(
    public el: ElementRef,
    public editorStateService: EditorStateService,
    private brickFactoryService: BrickFactoryService,
    private emailService: EmailService,
    private cd: ChangeDetectorRef,
    private dialogService: WsDialogService,
    private wsNotificationsService: WsNotificationsService,
    private translate: TranslateService,
    protected dragAndDropService: CdkDragAndDropService
  ) {}

  ngOnInit(): void {
    this.editorStateService.registerComponent(this)
    if (this.editorStateService.element === this.element) {
      this.editorStateService.setSelectedElement(this)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['element']) {
      this.element = changes['element'].currentValue
    }
  }

  ngAfterViewInit() {
    if (this.elementIsSelectable) {
      this.el.nativeElement.addEventListener('click', (event: Event) => {
        this.editorStateService.setSelectedElement(this)
        event.stopPropagation()
      })
    }
    this.el.nativeElement.classList.add('ws-email-element')
    this.cd.detectChanges()
  }

  addLabelToElement() {
    this.elementIsActive = true
    this.elementIsActiveChange.emit(this.elementIsActive)
    this.triggerElementComponentChangeDetection()
  }

  removeLabelFromElement() {
    this.elementIsActive = false
    this.elementIsActiveChange.emit(this.elementIsActive)
    this.triggerElementComponentChangeDetection()
  }

  removeElement(event?: MouseEvent) {
    //Todo: ask if really want to delete --> confirmation dialog
    if (event) event.stopPropagation()
    if (!this.element || !this.parent) return
    const index = this.parent.getChildren().findIndex((element) => element.id === this.element!.id)
    if (index !== -1) {
      // by removing the element from the parent, the element will be removed from dom and eventually gets destroyed
      if (this.editorStateService.element === this.element) {
        this.editorStateService.unselectSelectedElement()
      }
      this.parent.getChildren().splice(index, 1)
      this.editorStateService.unregisterComponent(this)
      this.element = undefined
    }
  }

  duplicateElement(event: MouseEvent = new MouseEvent('click')) {
    event.stopPropagation()
    this.editorStateService.unselectSelectedElement()
    this.elementIsActive = false
    if (!this.element || !this.parent) return
    const index = this.parent.getChildren().findIndex((element) => element.id === this.element!.id)

    if (index !== -1) {
      const copiedElement = this.brickFactoryService.duplicateBrick(this.element)
      if (!copiedElement) return
      this.parent.getChildren().splice(index + 1, 0, copiedElement)
      setTimeout(() => {
        this.editorStateService.selectElement(this.parent!.getChildren()[index + 1])
      }, 200)
    }
  }

  //Do not remove this method it is in use
  handleTextChange() {
    if (!this.element) return
    this.element.content = this.textContainer?.nativeElement.innerHTML || ''
  }

  saveElementBlock() {
    if (!this.element) return
    const dialog = this.dialogService.open({
      dialogTitleText: this.translate.instant('element.saveElementBlock'),
      dialogContentTemplateRef: this.simpleDialogContentTemplateRef,
      dialogActions: [
        { label: this.translate.instant('cancel'), action: 'cancel', buttonType: 'secondary' },
        { label: this.translate.instant('save'), action: 'add', buttonType: 'primary' }
      ]
    })

    dialog.afterClose$.subscribe((e: any) => {
      if (e === 'add') {
        if (!this.blockElementName) {
          this.wsNotificationsService.error(this.translate.instant('element.errorSaveElementBlock'))
          return
        }
        if (this.element) {
          this.element.name = this.blockElementName
          this.emailService
            .saveDefaultBrick(
              this.element,
              this.element.getMjmlWithGlobalStyles(this.template?.getStyleOfAttributes()),
              this.blockElementName
            )
            .pipe(first())
            .subscribe({
              next: () => {
                this.wsNotificationsService.success(this.translate.instant('element.successSaveElementBlock'))
                this.blockElementName = ''
              },
              error: () => this.wsNotificationsService.error(this.translate.instant('element.errorSaveElementBlock'))
            })
        }
      }
    })
  }

  triggerElementComponentChangeDetection() {
    this.component?.triggerChangeDetection()
  }

  ngOnDestroy(): void {
    this.editorStateService.unregisterComponent(this)
  }

  onDragDropped() {
    this.dragAndDropService.onDragEnded()
  }
}
