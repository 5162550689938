import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'

@Component({
  selector: 'ws-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrl: './file-preview.component.scss'
})
export class FilePreviewComponent {
  @Input() public previewSize = 50
  @Input() public multiple = false
  @Input() public sortingAllowed = false
  @Input() files: File[] = []

  @Output() indexOfFileRemoved: EventEmitter<number> = new EventEmitter<number>()
  @Output() sortingOfFilesChanged: EventEmitter<File[]> = new EventEmitter<File[]>()

  /**
   * Emit index of removed file
   *
   * @param index - index of removed file
   */
  emitIndexOfRemovedFile(index: number) {
    this.indexOfFileRemoved.emit(index)
  }

  /**
   * Drag and drop event for sorting files
   *
   * @param event - event object
   */
  dropFileInList(event: CdkDragDrop<File[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex)
    this.emitSortingOfFilesChanged()
  }

  /**
   * Emit sorting of files changed
   */
  emitSortingOfFilesChanged() {
    this.sortingOfFilesChanged.emit(this.files)
  }
}
