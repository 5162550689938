import { ApplicationRef, inject, Injectable, ViewContainerRef } from '@angular/core'
import { SpeechRecognitionDialogComponent } from './speech-recognition-dialog/speech-recognition-dialog.component'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { SpeechRecognitionResult } from '../../types/forms/speech-recognition-result'
import { WsDialogService } from '../notifications/ws-dialog-service'

/**
 * Service for speech recognition using the browser's speech recognition API.
 * Currently, all browsers except Firefox are supported.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition
 */
@Injectable({
  providedIn: 'root'
})
export class WsSpeechRecognitionService {
  resultNeedle = 0
  speechResult = ''
  currentSpeechResult = ''
  grammar = ''
  listening = false
  dialogAborted = false
  inlineSpeechRecognition = false

  microphonePermission: 'denied' | 'granted' | 'prompt' | undefined

  speechRecognitionObject: any
  speechRecognitionDialog: any
  speechRecognitionDialogRef: any

  public readonly speechRecognitionSupported = 'webkitSpeechRecognition' in window

  private dialogService: WsDialogService = inject(WsDialogService)
  private applicationRef: ApplicationRef = inject(ApplicationRef)
  private translate: TranslateService = inject(TranslateService)

  constructor() {
    if (!this.speechRecognitionSupported) return
    this.checkMicrophonePermission()
  }

  /**
   * Starts the speech recognition. The speech recognition can be started in two modes: inline or in a dialog.
   * In inline mode, the speech recognition result is returned instantly, including interim results.
   * In dialog mode, the speech recognition result is returned after the user has confirmed the result in a dialog.
   * Include the grammar to use for the speech recognition to improve the recognition results.
   * @param {boolean} inline True to start the speech recognition in inline mode, false to start it in dialog mode
   * @param {string} grammar The grammar to use for the speech recognition. The grammar needs to be in the JSGF format.
   */
  startSpeechRecognition(inline: boolean, grammar: string): Subject<SpeechRecognitionResult> {
    if (this.microphonePermission === undefined || this.microphonePermission === 'prompt') {
      this.checkMicrophonePermission(true)
    }

    if (this.microphonePermission !== 'granted') {
      navigator.mediaDevices.getUserMedia({ audio: true }).catch(() => {
        this.microphonePermission = 'denied'
      })
      return new BehaviorSubject<SpeechRecognitionResult>({
        type: 'error',
        text: this.translate.instant('ws.forms.speechRecognition.missingMicPermission')
      })
    }

    if (this.listening) {
      return new BehaviorSubject<SpeechRecognitionResult>({
        type: 'error',
        text: this.translate.instant('ws.forms.speechRecognition.alreadyListening')
      })
    }

    this.grammar = grammar
    this.inlineSpeechRecognition = inline

    this.initSpeechRecognition()

    if (!inline) {
      this.registerSpeechRecognitionDialog()
    }

    const subject = new Subject<SpeechRecognitionResult>()
    this.recognizeSpeech(subject)
    return subject
  }

  /**
   * Checks if the microphone permission is granted. If not, the user is asked to grant the permission.
   * The user can only be asked once to grant the permission, further requests will be blocked by the browser.
   * If the user denies the permission, the speech recognition cannot be used.
   * If the permission is granted, the speech recognition is initialized.
   * Detects changes to the microphone permission and initializes the speech recognition if the permission is granted.
   */
  checkMicrophonePermission(startIntervalOnIphone: boolean = false) {
    //weird type conversion because microphone is not supported in PermissionDescriptor
    navigator.permissions
      .query(<PermissionDescriptor>(<unknown>{ name: 'microphone' }))
      .then((permissionStatus: PermissionStatus) => {
        this.microphonePermission = permissionStatus.state

        if (startIntervalOnIphone && navigator.userAgent.includes('iPhone')) {
          // start interval on iphone to check for permission change because onchange is not supported in iOS
          const IphoneInterval: number = setInterval(() => {
            this.checkMicrophonePermission()

            if (this.microphonePermission === 'granted') {
              clearInterval(IphoneInterval)
              this.initSpeechRecognition()
            } else if (this.microphonePermission === 'denied') {
              clearInterval(IphoneInterval)
            }
          }, 300)
          // force stop interval after 10 seconds to prevent infinite loop or microphone permission never changes
          setTimeout(() => {
            clearInterval(IphoneInterval)
          }, 10000)
        }

        // this onchange function is not fired in iOS
        permissionStatus.onchange = (event: any) => {
          this.microphonePermission = event.target.state

          if (this.microphonePermission === 'granted') {
            this.initSpeechRecognition()
          }
        }
      })
  }

  /**
   * Initializes the speech recognition object.
   * The speech recognition object is used to start and stop the speech recognition and to get the speech recognition results.
   * The speech recognition object is initialized with the current language and the grammar.
   * The current language of the application is used as the language for the speech recognition.
   * If the grammar is empty, the speech recognition is not limited to the grammar.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition
   */
  initSpeechRecognition() {
    if (!this.speechRecognitionObject) {
      this.speechRecognitionObject = new (window as any).webkitSpeechRecognition()
    }

    this.speechRecognitionObject.continuous = true
    this.speechRecognitionObject.interimResults = true

    if (this.translate.currentLang) {
      this.speechRecognitionObject.lang = this.translate.currentLang
    }

    if (this.grammar !== '') {
      const speechRecognitionList = new (window as any).webkitSpeechGrammarList()
      speechRecognitionList.addFromString(this.grammar, 1)
      this.speechRecognitionObject.grammars = speechRecognitionList
    }
  }

  /**
   * Registers the speech recognition dialog component.
   * The speech recognition dialog component is used to display the speech recognition in a dialog.
   */
  registerSpeechRecognitionDialog() {
    const applicationRootComponent = this.applicationRef.components[0]
    if (!applicationRootComponent) return

    const rootViewContainerRef = applicationRootComponent.injector.get(ViewContainerRef)
    this.speechRecognitionDialogRef = rootViewContainerRef.createComponent(SpeechRecognitionDialogComponent)
  }

  /**
   * Starts the speech recognition.
   * The speech recognition can be started in two modes: inline or in a dialog.
   * In inline mode, the speech recognition result is returned instantly, including interim results.
   * In dialog mode, the speech recognition result is returned after the user has confirmed the result in a dialog.
   * @param {Subject<SpeechRecognitionResult>} subject The subject to send the speech recognition results to
   */
  recognizeSpeech(subject: Subject<SpeechRecognitionResult>) {
    if (this.listening) {
      subject.next({ type: 'error', text: this.translate.instant('ws.forms.speechRecognition.alreadyListening') })
      return
    }

    this.listening = true
    this.speechResult = ''
    this.currentSpeechResult = ''

    if (this.inlineSpeechRecognition) {
      this.resultNeedle = 0
      this.speechRecognitionObject.start()
      this.speechRecognitionObject.onresult = (event: any) => {
        this.processSpeechResult(event.results, subject)
      }
      this.speechRecognitionObject.onend = () => {
        this.saveSpeechResult(subject)
        this.resetSpeechRecognition()
      }
    } else {
      this.speechRecognitionDialog = this.dialogService.open({
        dialogContentTemplateRef: this.speechRecognitionDialogRef.instance.speechRecognitionDialogContent,
        dialogActions: [
          {
            label: this.translate.instant('ws.notifications.cancel'),
            action: 'cancel',
            buttonType: 'secondary'
          },
          {
            label: this.translate.instant('ws.dialog.confirm'),
            action: 'confirm',
            buttonType: 'primary'
          }
        ]
      })

      this.speechRecognitionDialog.afterClose$.subscribe((action: string) => {
        if (action === 'confirm') {
          this.stopListeningToMicrophone()
        } else {
          this.speechRecognitionObject.abort()
          this.dialogAborted = true
          subject.next({ type: 'abort', text: '' })
        }

        this.listening = false
        this.speechRecognitionDialogRef.destroy()
      })

      this.speechRecognitionObject.start()
      this.speechRecognitionObject.onresult = (event: any) => {
        this.processSpeechResult(event.results, subject)
        if (this.speechRecognitionDialog) {
          this.speechRecognitionDialog.cdRf.detectChanges()
        }
      }

      this.speechRecognitionObject.onend = () => {
        if (this.dialogAborted) {
          this.resetSpeechRecognition()
          return
        }

        this.resultNeedle = 0
        if (this.speechResult[this.speechResult.length - 1] !== ' ') {
          this.speechResult += ' '
        }

        if (this.listening) {
          this.speechRecognitionObject.start()
        } else {
          this.saveSpeechResult(subject)
          this.resetSpeechRecognition()
        }
      }
    }
  }

  /**
   * Saves the speech recognition result by sending the final result to the subject.
   * @param {Subject<SpeechRecognitionResult>} subject The subject to send the speech recognition results to
   */
  saveSpeechResult(subject: Subject<SpeechRecognitionResult>) {
    if (this.speechResult[this.speechResult.length - 1] === ' ') {
      this.speechResult = this.speechResult.slice(0, -1)
    }

    subject.next({ type: 'final', text: this.speechResult })
  }

  /**
   * Processes the speech recognition results.
   * The speech recognition results are processed by adding the interim results to the current speech result and the final results to the speech result.
   * Interim results will be deleted if a final result is received.
   * @param {any} results The speech recognition results
   * @param {Subject<SpeechRecognitionResult>} subject The subject to send the speech recognition results to
   */
  processSpeechResult(results: any, subject: Subject<SpeechRecognitionResult>) {
    this.currentSpeechResult = ''

    for (let i = this.resultNeedle; i < results.length; i++) {
      if (results[i].isFinal && results[i][0].confidence > 0) {
        this.speechResult += results[i][0].transcript
        this.resultNeedle = i + 1
      } else if (!results[i].isFinal) {
        this.currentSpeechResult += results[i][0].transcript
      }
    }

    if (this.inlineSpeechRecognition) {
      subject.next({ type: 'interim', text: this.speechResult + this.currentSpeechResult })
    } else {
      this.speechRecognitionDialogRef.instance.speechResult = this.speechResult
      this.speechRecognitionDialogRef.instance.currentSpeechResult = this.currentSpeechResult
      this.speechRecognitionDialog.cdRf.detectChanges()
    }
  }

  /**
   * Stops the speech recognition.
   * The speech recognition will be stopped, the browser will wait for a final result.
   * Wait a few ms to give the speech recognition some time to process the last audio input.
   */
  stopSpeechRecognition() {
    if (this.speechRecognitionObject) {
      //wait a few ms to give the speech recognition some time to process the last audio input, otherwise it will return an empty result
      setTimeout(() => {
        this.listening = false
        this.stopListeningToMicrophone()
      }, 750)
    }
  }

  stopListeningToMicrophone() {
    // this is a workaround for iOS in order to stop speech recognition there
    try {
      this.speechRecognitionObject.start()
    } catch (err) {
      console.warn('error while trying to start speech recognition again to stop it on iOS\n', err)
    }

    this.speechRecognitionObject.stop()
  }

  /**
   * Aborts the speech recognition immediately.
   * The speech recognition will be aborted, meaning that the last result will not be saved if it is not a final result.
   */
  abortSpeechRecognition() {
    if (this.speechRecognitionObject) {
      this.listening = false
      this.speechRecognitionObject.abort()
    }
  }

  /**
   * Resets the speech recognition.
   * The speech recognition will be reset by setting the speech recognition object to null and resetting all other variables.
   */
  resetSpeechRecognition() {
    this.listening = false
    this.speechResult = ''
    this.currentSpeechResult = ''
    this.resultNeedle = 0
    this.dialogAborted = false
    this.grammar = ''
    this.speechRecognitionObject = null
  }
}
