<app-ws-email-element-wrapper
  #wrapper
  [element]="element"
  [elementIsActive]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [component]="this"
>
  <span class="min-h-3 flex items-center">
    <p [ngStyle]="element?.getStyles(template.testData).p" style="font-size: 10px"></p>
  </span>
</app-ws-email-element-wrapper>
