export class Border {
  width: string = ''
  color: string = ''
  style: string = ''

  constructor(width: string, color: string, style: string) {
    this.width = width
    this.color = color
    this.style = style
  }

  hasAllValues(): boolean {
    return !!(this.width && this.color && this.style)
  }

  getFormValues() {
    const formValues: Record<string, string> = {}

    formValues['border-width'] = this.width
    formValues['border-color'] = this.color
    formValues['border-style'] = this.style

    return formValues
  }
}
