@if (ngControl) {
  <mat-form-field class="w-full" [class.hide-subscript]="!hint && !hasErrors">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    @if (native) {
      <input
        type="date"
        class="ws-form__datepicker__input"
        [placeholder]="placeholder"
        [formControl]="formControl"
        (ngModelChange)="onDateChange($event)"
        matInput
        (focus)="handleInputFocus()"
        (blur)="handleInputBlur()"
        [min]="min"
        [max]="max"
      />
    } @else {
      <input
        class="ws-form__datepicker__input"
        [placeholder]="placeholder"
        [formControl]="formControl"
        (ngModelChange)="onDateChange($event)"
        matInput
        #dateInput
        (focus)="handleInputFocus()"
        (blur)="handleInputBlur()"
        [matDatepicker]="picker"
        [min]="min"
        [max]="max"
      />
    }
    @if (clearable && formControl.value) {
      <mat-icon class="cursor-pointer" (click)="clearInputValue()" matSuffix>close</mat-icon>
    } @else {
      @if (icon) {
        <mat-datepicker-toggle matIconSuffix [disabled]="native" [for]="picker">
          <mat-icon matDatepickerToggleIcon>{{ icon }}</mat-icon>
        </mat-datepicker-toggle>
      }
    }

    <mat-datepicker #picker></mat-datepicker>

    @if (hint && !hideHint) {
      <mat-hint>{{ hint | translate }}</mat-hint>
    }

    @if (!hint && !hideHint) {
      <mat-hint>{{ 'ws.forms.forExample' | translate }} {{ today }}</mat-hint>
    }

    @if (hasErrors) {
      <mat-error>{{ validationErrorMessage }}</mat-error>
    }
  </mat-form-field>
}

<!--<pre>date: {{ date }}</pre>-->
<!--<pre>value: {{ value }}</pre>-->
<!--<pre>touched: {{ formControl.touched }}</pre>-->
<!--<pre>dirty: {{ formControl.dirty }}</pre>-->
<!--<pre>invalid: {{ formControl.invalid }}</pre>-->
<!--<pre>errors: {{ formControl.errors | json }}</pre>-->
<!--<pre>has errors: {{ hasErrors }}</pre>-->
<!--<pre>validationErrorMessage: {{ validationErrorMessage }}</pre>-->
