@if (formIsInitialized && selectedElement) {
  <div [formGroup]="textSettingsForm" class="p-4">
    @if (selectedElement.styles['mj-class'] === 'heading') {
      <div class="mb-4">
        <h1 class="element-settings-title">Überschriftsart</h1>
        <div class="text-settings__heading-button-container flex items-center pt-2">
          <button
            (click)="textSettingsForm.controls['font-size'].setValue(fontSizeH1)"
            [ngClass]="{ 'text-settings__selected-headline': textSettingsForm.get('font-size')?.value === fontSizeH1 }"
            mat-button
          >
            H1
          </button>
          <button
            (click)="textSettingsForm.controls['font-size'].setValue(fontSizeH2)"
            [ngClass]="{ 'text-settings__selected-headline': textSettingsForm.get('font-size')?.value === fontSizeH2 }"
            mat-button
          >
            H2
          </button>
          <button
            (click)="textSettingsForm.controls['font-size'].setValue(fontSizeH3)"
            [ngClass]="{ 'text-settings__selected-headline': textSettingsForm.get('font-size')?.value === fontSizeH3 }"
            mat-button
          >
            H3
          </button>
          <button
            (click)="textSettingsForm.controls['font-size'].setValue(fontSizeH4)"
            [ngClass]="{ 'text-settings__selected-headline': textSettingsForm.get('font-size')?.value === fontSizeH4 }"
            mat-button
          >
            H4
          </button>
        </div>
      </div>
    }
    @if (atLeastOneFieldExists(['font-family', 'font-size'])) {
      <div class="mb-4">
        <div class="flex justify-start items-center">
          <h1 class="element-settings-title text-left">{{ 'template.element.font' | translate }}</h1>
          @if (selectedElement.type === 'Text' && selectedElement.styles['font-family']) {
            <ws-icon (click)="resetFields(['font-family', 'font-size', 'color'])">close_small</ws-icon>
          }
          @if (selectedElement.type === 'Button' && selectedElement.styles['font-family']) {
            <ws-icon (click)="reset('font-family')">close_small</ws-icon>
          }
        </div>
        @if (fieldExists('font-family')) {
          <ws-form-select
            label="{{ 'template.element.fontFamily' | translate }}"
            formControlName="font-family"
            style="--ws-form-field-padding-top: 2em"
            [options]="fontOptions"
          ></ws-form-select>
        }
        @if (fieldExists('font-family')) {
          <ws-form-input
            style="--ws-form-field-padding-top: 2em"
            formControlName="font-size"
            type="number"
            suffix="px"
            label="{{ 'template.element.fontSize' | translate }}"
            [min]="1"
          >
          </ws-form-input>
        }
      </div>
    }
    @if (atLeastOneFieldExists(['color', 'font-weight', 'font-style', 'text-decoration'])) {
      <div class="element-text-settings-font-style text-center mb-2">
        @if (fieldExists('color')) {
          <div class="flex justify-between">
            <ws-form-input formControlName="color" label="{{ 'template.element.fontColor' | translate }}">
            </ws-form-input>
            <div class="text-center">
              <ws-form-color-picker [compact]="true" formControlName="color"></ws-form-color-picker>
            </div>
          </div>
        }
        @if (fieldExists('font-style')) {
          <h1 class="element-settings-title text-left">{{ 'template.element.fontStyle' | translate }}</h1>
          <button
            mat-icon-button
            (click)="
              textSettingsForm.controls['font-weight'].setValue(
                textSettingsForm.controls['font-weight'].value === 'bold' ? 'normal' : 'bold'
              )
            "
            class="ml-2"
            matTooltip="{{ 'template.element.fontBold' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>format_bold</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="
              textSettingsForm.controls['font-style'].setValue(
                textSettingsForm.controls['font-style'].value === 'normal' ? 'italic' : 'normal'
              )
            "
            class="ml-2"
            matTooltip="{{ 'template.element.fontItalic' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>format_italic</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="
              textSettingsForm.controls['text-decoration'].setValue(
                textSettingsForm.controls['text-decoration'].value === 'underline' ? 'none' : 'underline'
              )
            "
            class="ml-2"
            matTooltip="{{ 'template.element.textUnderline' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>format_underlined</mat-icon>
          </button>
        }
      </div>
    }
    <div *ngIf="fieldExists('align') && !fieldExists('vertical-align')" id="element-settings-image-align" class="mb-4">
      <h1 class="element-settings-title text-left">{{ 'template.element.alignment' | translate }}</h1>
      <div class="text-center mt-2">
        <span class="text-sm text-center font-bold">{{ 'template.element.horizontal' | translate }}</span>

        <div id="element-settings-image-horizontal-align">
          <button
            mat-icon-button
            (click)="textSettingsForm.controls['align'].setValue('left')"
            class="ml-2"
            matTooltip="{{ 'template.element.textAlignLeft' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>format_align_left</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="textSettingsForm.controls['align'].setValue('center')"
            class="ml-2"
            matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>format_align_center</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="textSettingsForm.controls['align'].setValue('right')"
            class="ml-2"
            matTooltip="{{ 'template.element.textAlignRight' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>format_align_right</mat-icon>
          </button>
        </div>
      </div>
    </div>

    @if (fieldExists('line-height')) {
      <div>
        <ws-form-input
          class="mt-4"
          label="{{ 'template.element.textLineSpacing' | translate }}"
          formControlName="line-height"
          [step]="0.1"
          [min]="1"
          type="number"
        >
        </ws-form-input>
      </div>
    }
  </div>
}
<!--Debug :-->

<!--{{ selectedElement | json }}-->
