import { Component, inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import { NotificationData } from '../../../types/notifications/notification-data'
import { MatButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'

@Component({
  selector: 'ws-notification-dialog',
  standalone: true,
  templateUrl: './notification-dialog.component.html',
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButton, MatIcon],
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent {
  public dialogRef: MatDialogRef<NotificationDialogComponent> = inject(MatDialogRef)
  public data: NotificationData = inject(MAT_DIALOG_DATA)
}
