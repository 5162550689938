@if (ngControl) {
  <div class="mat-mdc-form-field w-full py-2">
    <mat-slide-toggle [class.read-only]="readonly" [formControl]="formControl" (ngModelChange)="onFieldChange($event)">
      @if (label) {
        <span class="ml-2">{{ label | translate }}</span>
      }
    </mat-slide-toggle>
    @if (hasErrors) {
      <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align">
        <div class="mat-mdc-form-field-error-wrapper">
          <mat-error>
            {{ validationErrorMessage }}
          </mat-error>
        </div>
      </div>
    }
  </div>
}
