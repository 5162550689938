import defaultStyles from './socialElementStyles.json'
import { Brick } from './brick'
import { StyleAttributes, TestData } from './brick.types'

export interface SocialNetwork {
  'share-url': string | undefined
  'base-url': string
  'background-color': string
  src: string
  alt: string
  title: string
}

export interface SocialNetworks {
  [key: string]: SocialNetwork
}
export class SocialElement extends Brick {
  IMG_BASE_URL = 'https://email.waldhart.at/assets/icons/social/'

  defaultSocialNetworks: SocialNetworks = {
    facebook: {
      'share-url': 'https://www.facebook.com/sharer/sharer.php?u=[[URL]]',
      'base-url': 'https://www.facebook.com/',
      'background-color': '#3b5998',
      src: `${this.IMG_BASE_URL}facebook.png`,
      alt: 'facebook',
      title: 'Facebook'
    },
    twitter: {
      'share-url': 'https://x.com/intent/tweet?url=[[URL]]',
      'base-url': 'https://x.com/',
      'background-color': '#55acee',
      src: `${this.IMG_BASE_URL}twitter.png`,
      alt: 'twitter',
      title: 'Twitter'
    },
    google: {
      'share-url': 'https://plus.google.com/share?url=[[URL]]',
      'base-url': 'https://plus.google.com/',
      'background-color': '#dc4e41',
      src: `${this.IMG_BASE_URL}google-plus.png`,
      alt: 'google',
      title: 'Google'
    },
    pinterest: {
      'share-url': 'https://pinterest.com/pin/create/button/?url=[[URL]]&media=&description=',
      'base-url': 'https://pinterest.com/',
      'background-color': '#bd081c',
      src: `${this.IMG_BASE_URL}pinterest.png`,
      alt: 'pinterest',
      title: 'Pinterest'
    },
    linkedin: {
      'share-url': 'https://www.linkedin.com/shareArticle?mini=true&url=[[URL]]&title=&summary=&source=',
      'base-url': 'https://www.linkedin.com/',
      'background-color': '#0077b5',
      src: `${this.IMG_BASE_URL}linkedin.png`,
      alt: 'linkedin',
      title: 'LinkedIn'
    },
    instagram: {
      'background-color': '#3f729b',
      'base-url': 'https://www.instagram.com/',
      src: `${this.IMG_BASE_URL}instagram.png`,
      'share-url': undefined,
      alt: 'instagram',
      title: 'Instagram'
    },
    web: {
      src: `${this.IMG_BASE_URL}web.png`,
      'base-url': '',
      'background-color': '#4BADE9',
      'share-url': undefined,
      alt: 'web',
      title: 'Web'
    },
    snapchat: {
      src: `${this.IMG_BASE_URL}snapchat.png`,
      'base-url': 'https://www.snapchat.com/',
      'background-color': '#FFFA54',
      'share-url': undefined,
      alt: 'snapchat',
      title: 'Snapchat'
    },
    youtube: {
      src: `${this.IMG_BASE_URL}youtube.png`,
      'base-url': 'https://www.youtube.com/',
      'background-color': '#EB3323',
      'share-url': undefined,
      alt: 'youtube',
      title: 'YouTube'
    },
    tumblr: {
      src: `${this.IMG_BASE_URL}tumblr.png`,
      'base-url': 'https://www.tumblr.com/',
      'share-url': 'https://www.tumblr.com/widgets/share/tool?canonicalUrl=[[URL]]',
      'background-color': '#344356',
      alt: 'tumblr',
      title: 'Tumblr'
    },
    github: {
      src: `${this.IMG_BASE_URL}github.png`,
      'base-url': 'https://www.github.com/',
      'background-color': '#000000',
      'share-url': undefined,
      alt: 'github',
      title: 'GitHub'
    },
    xing: {
      src: `${this.IMG_BASE_URL}xing.png`,
      'base-url': 'https://www.xing.com/',
      'share-url': 'https://www.xing.com/app/user?op=share&url=[[URL]]',
      'background-color': '#296366',
      alt: 'xing',
      title: 'Xing'
    },
    vimeo: {
      'base-url': 'https://www.vimeo.com/',
      src: `${this.IMG_BASE_URL}vimeo.png`,
      'background-color': '#53B4E7',
      'share-url': undefined,
      alt: 'vimeo',
      title: 'Vimeo'
    },
    medium: {
      'base-url': 'https://www.medium.com/',
      src: `${this.IMG_BASE_URL}medium.png`,
      'background-color': '#000000',
      'share-url': undefined,
      alt: 'medium',
      title: 'Medium'
    },
    soundcloud: {
      'base-url': 'https://www.soundcloud.com/',
      src: `${this.IMG_BASE_URL}soundcloud.png`,
      'background-color': '#EF7F31',
      'share-url': undefined,
      alt: 'soundcloud',
      title: 'SoundCloud'
    },
    dribbble: {
      'base-url': 'https://www.dribbble.com/',
      src: `${this.IMG_BASE_URL}dribbble.png`,
      'background-color': '#D95988',
      'share-url': undefined,
      alt: 'dribbble',
      title: 'Dribbble'
    },
    x: {
      'base-url': 'https://www.x.com/',
      src: `${this.IMG_BASE_URL}twitter-x.png`,
      'background-color': '#000000',
      'share-url': undefined,
      alt: 'x',
      title: 'X'
    },
    custom: {
      'base-url': '',
      src: '',
      'background-color': '#000000',
      'share-url': undefined,
      alt: 'social-network-icon',
      title: 'Social Network'
    }
  }
  constructor(content: string = '', styles: StyleAttributes = {}) {
    super('SocialElement', content, styles, 'mj-social-element')
    this.addDefaultStyles(defaultStyles)
    this.styles['src'] = this.styles['src']
      ? this.styles['src']
      : this.defaultSocialNetworks[this.styles['name']]?.src || ''
  }

  getSocialAttributes() {
    const name = this.styles?.['name'].toString().split('-')[0].toLowerCase()

    const socialNetwork = this.defaultSocialNetworks[name] || {}

    const href = this.styles['href']

    // if (href && socialNetwork['share-url']) {
    //   href = socialNetwork['share-url'].replace('[[URL]]', href.toString())
    // }

    const attrs = ['icon-size', 'icon-height', 'srcset', 'sizes', 'src', 'background-color', 'alt', 'title'].reduce(
      (r, attr: string) => ({
        ...r,
        [attr]: this.styles[attr] || socialNetwork[attr as keyof SocialNetwork]
      }),
      {}
    )

    return {
      href,
      ...attrs
    }
  }

  override getStyles(testData: TestData = {}): any {
    const attrs: any = this.getSocialAttributes()
    const {
      'icon-size': iconSize,
      'icon-height': iconHeight,
      'background-color': backgroundColor,
      alt: alt,
      title: title
    } = attrs

    return {
      td: {
        padding: this.getPadding(),
        'vertical-align': this.styles?.['vertical-align']
      },
      table: {
        background: backgroundColor,
        'border-radius': this.styles?.['border-radius'],
        width: iconSize
      },
      icon: {
        padding: this.styles?.['icon-padding'],
        'font-size': '0',
        height: iconHeight || iconSize,
        'vertical-align': 'middle',
        width: iconSize
      },
      img: {
        'border-radius': this.styles?.['border-radius'],
        alt: alt,
        title: title,
        display: 'block'
      },
      tdText: {
        'vertical-align': 'middle',
        padding: this.styles?.['text-padding']
      },
      text: {
        color: this.styles?.['color'],
        'font-size': this.styles?.['font-size'],
        'font-weight': this.styles?.['font-weight'],
        'font-style': this.styles?.['font-style'],
        'font-family': this.styles?.['font-family'],
        'line-height': this.styles?.['line-height'],
        'text-decoration': this.styles?.['text-decoration']
      }
    }
  }
}
