import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  inject,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { FieldComponent } from '../field/field.component'
import { NgControl } from '@angular/forms'
import { WsDialogService } from '../../notifications/ws-dialog-service'
import { MatMenuTrigger } from '@angular/material/menu'
import { DialogComponent } from '../../notifications/dialog/dialog.component'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'

/**
 * Form field component to select a color.
 * It can either be an input field with a button to open the color picker or in compact mode as just a button to open the color picker.
 * The color picker can return the selected color in hex or rgb format.
 *
 * @example
 * <ws-form-color-picker
 *    [(ngModel)]="selectedColor"
 *  ></ws-form-color-picker>
 *
 * <ws-form-color-picker
 *    [(ngModel)]="selectedColor"
 *    [compact]="true"
 *  ></ws-form-color-picker>
 *
 * <ws-form-color-picker
 *    [(ngModel)]="selectedColor"
 *    placeholder="#00A9E2"
 *    [preselectColors]="['00A9E2', '#FFB900', 'E74856', '7A7574', 'A82B70', 'A95A45', 'B8D432', '00B7C3']"
 *  ></ws-form-color-picker>
 */
@Component({
  selector: 'ws-form-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss', '../field/field.component.scss']
})
export class ColorPickerComponent extends FieldComponent implements OnInit {
  /** A placeholder text to show in the input field.*/
  @Input() public placeholder = ''
  /** Whether the color picker should return the selected color in rgb format.*/
  @Input() rgb = false
  /** Whether the color picker should be shown as a button to open the color picker dialog or as an input field with a button to open the color picker dialog.*/
  @Input() compact = false

  /**
   * An array of hex color codes to show as preselectable colors in the color picker. Hex codes can be with or without the #.
   * Only the first 8 valid colors will be shown.
   */
  @Input() preselectColors: string[] = []

  /** The width of the color slider.*/
  sliderWidth = 376
  /** The size of the color saturation canvas.*/
  size = 200
  /** The position of the color marker in the color slider.*/
  colorMarkerPosition = 0
  /**
   * @ignore
   */
  public override value = ''
  /**
   * The hex code of the currently selected color in the color picker.
   * Does not include the #.
   */
  valueHex = ''
  /**
   * The hex code of the color to draw in the color saturation canvas.
   * Is needed because the color saturation canvas is drawn with a gradient from white to the selected color.
   * The selected color is not always the same as the color to draw.
   */
  colorToDraw = ''
  /**
   * The hex code of the color to display in the input field and as a preview of the selected color.
   * Is needed because on mobile the value is only updated when the user presses the 'save' button.
   */
  colorToDisplay = '#000000'
  /** Whether the user is currently dragging the saturation marker.*/
  /**
   * The id of the color saturation canvas.
   * @ignore
   */
  canvasId = 'color-picker-canvas-' + Math.floor(Math.random() * 1000000)
  /**
   * The id of the color slider canvas.
   * @ignore
   */
  sliderId = 'color-picker-slider-' + Math.floor(Math.random() * 1000000)
  draggingSaturation = false
  /** Whether the user is currently dragging the color marker.*/
  draggingColor = false
  colorPickerOpen = false

  /** The rgb values of the currently selected color in the color picker.*/
  valueRGB: { r: number; g: number; b: number } = { r: 0, g: 0, b: 0 }

  /** The position of the saturation marker in the color saturation canvas.*/
  saturationMarkerPosition = { x: this.size - 1, y: 0 }
  /**
   * Reference to the color saturation canvas.
   * @ignore
   */
  colorSaturationCanvas!: HTMLCanvasElement
  /**
   * Reference to the color slider canvas.
   * @ignore
   */
  colorSliderCanvas!: HTMLCanvasElement
  /**
   * The context of the color saturation canvas.
   * @ignore
   */
  colorSaturationContext: CanvasRenderingContext2D | null = null
  /**
   * The context of the color slider canvas.
   * @ignore
   */
  colorSliderContext: CanvasRenderingContext2D | null = null
  /**
   * Reference to the dialog opened by the color picker.
   * @ignore
   */
  dialogRef: DialogComponent | undefined

  /**
   * Reference to the color picker dialog template.
   * @ignore
   */
  @ViewChild('colorPickerDialog') colorPickerDialog!: TemplateRef<any>
  /**
   * Reference to the color picker menu trigger.
   * @ignore
   */
  @ViewChild(MatMenuTrigger) colorPickerMenuTrigger: MatMenuTrigger | undefined

  /**
   * Event emitted when the user selects a color in the color picker.
   * Returns the selected color either in hex or rgb format.
   */
  @Output() colorSelected = new EventEmitter<string>()

  public dialogService: WsDialogService = inject(WsDialogService)

  constructor(
    @Self()
    @Optional()
    public override ngControl: NgControl,
    @Inject('PlatformService') public override platformService: AbstractPlatformService
  ) {
    super(ngControl, platformService)
  }

  ngOnInit() {
    this.processPreselectColors()
  }

  /**
   * Processes the preselect colors to only use the first 8 valid colors.
   * A color is valid if it passes the isValidHexCode function.
   */
  processPreselectColors() {
    const preselectColors = [...this.preselectColors]
    this.preselectColors = []

    for (const color of preselectColors) {
      if (this.preselectColors.length >= 8) {
        break
      }

      if (this.isValidHexCode(color)) {
        if (color.startsWith('#')) {
          this.preselectColors.push(color.substring(1).toUpperCase())
        } else {
          this.preselectColors.push(color.toUpperCase())
        }
      }
    }
  }

  /**
   * Draws the color saturation gradient and the black gradient on the color saturation canvas.
   * A gradient from white to the selected color is drawn from left to right.
   * A gradient from transparent to black is drawn from top to bottom.
   */
  drawSaturationGradient() {
    if (!this.colorSaturationContext || !this.colorSliderContext) {
      return
    }

    const colorGradient = this.colorSaturationContext.createLinearGradient(0, 0, this.size, 0)
    colorGradient.addColorStop(0, '#fff')
    colorGradient.addColorStop(1, '#' + this.colorToDraw)
    this.colorSaturationContext.fillStyle = colorGradient
    this.colorSaturationContext.fillRect(0, 0, this.size, this.size)

    const blackGradient = this.colorSaturationContext.createLinearGradient(0, 0, 0, this.size)
    blackGradient.addColorStop(0, 'rgba(0, 0, 0, 0)')
    blackGradient.addColorStop(1, 'rgba(0, 0, 0, 1)')
    this.colorSaturationContext.fillStyle = blackGradient
    this.colorSaturationContext.fillRect(0, 0, this.size, this.size)
  }

  /**
   * Draws the color slider gradient on the color slider canvas.
   * A gradient from red to yellow to green to cyan to blue to magenta to red is drawn from left to right.
   */
  drawSlider() {
    if (!this.colorSliderContext) {
      return
    }

    const sliderGradient = this.colorSliderContext.createLinearGradient(0, 0, this.sliderWidth, 0)
    sliderGradient.addColorStop(0, 'rgb(255, 0, 0)')
    sliderGradient.addColorStop(0.15, 'rgb(255, 0, 255)')
    sliderGradient.addColorStop(0.33, 'rgb(0, 0, 255)')
    sliderGradient.addColorStop(0.49, 'rgb(0, 255, 255)')
    sliderGradient.addColorStop(0.67, 'rgb(0, 255, 0)')
    sliderGradient.addColorStop(0.84, 'rgb(255, 255, 0)')
    sliderGradient.addColorStop(1, 'rgb(255, 0, 0)')
    this.colorSliderContext.fillStyle = sliderGradient
    this.colorSliderContext.fillRect(0, 0, this.sliderWidth, 10)
  }

  /**
   * Adds all necessary mouse and touch event listeners to the color saturation canvas and the color slider canvas.
   */
  addEventListeners() {
    this.colorSaturationCanvas.addEventListener('touchstart', this.onTouchStartColorSaturation)
    this.colorSaturationCanvas.addEventListener('touchend', this.onTouchEndColorSaturation)
    this.colorSaturationCanvas.addEventListener('touchmove', this.onTouchMoveColorSaturation)
    this.colorSliderCanvas.addEventListener('touchstart', this.onTouchStartColorSlider)
    this.colorSliderCanvas.addEventListener('touchend', this.onTouchEndColorSlider)
    this.colorSliderCanvas.addEventListener('touchmove', this.onTouchMoveColorSlider)

    this.colorSaturationCanvas.addEventListener('mousedown', this.onMouseDownColorSaturation)
    this.colorSaturationCanvas.addEventListener('mouseup', this.onMouseUpColorSaturation)
    this.colorSaturationCanvas.addEventListener('mousemove', this.onMouseMoveColorSaturation)
    this.colorSliderCanvas.addEventListener('mousedown', this.onMouseDownColorSlider)
    this.colorSliderCanvas.addEventListener('mouseup', this.onMouseUpColorSlider)
    this.colorSliderCanvas.addEventListener('mousemove', this.onMouseMoveColorSlider)
  }

  /**
   * Touch event listener for the color saturation canvas.
   * Starts dragging the saturation marker and selects the color at the touch position.
   */
  onTouchStartColorSaturation = (event: TouchEvent) => {
    event.preventDefault()
    this.draggingSaturation = true
    this.selectColorSaturation(event.touches[0].clientX, event.touches[0].clientY)
  }

  /**
   * Touch event listener for the color saturation canvas.
   * Stops dragging the saturation marker.
   */
  onTouchEndColorSaturation = (event: TouchEvent) => {
    event.preventDefault()
    this.draggingSaturation = false
  }

  /**
   * Touch event listener for the color saturation canvas.
   * Drags the saturation marker and selects the color at the touch position.
   */
  onTouchMoveColorSaturation = (event: TouchEvent) => {
    if (this.draggingSaturation && event.touches.length === 1) {
      event.preventDefault()
      this.selectColorSaturation(event.touches[0].clientX, event.touches[0].clientY)
    }
  }

  /**
   * Touch event listener for the color slider canvas.
   * Starts dragging the color marker and selects the color at the touch position.
   */
  onTouchStartColorSlider = (event: TouchEvent) => {
    event.preventDefault()
    this.draggingColor = true
    this.selectColorOnSlider(event.touches[0].clientX)
  }

  /**
   * Touch event listener for the color slider canvas.
   * Stops dragging the color marker.
   */
  onTouchEndColorSlider = (event: TouchEvent) => {
    event.preventDefault()
    this.draggingColor = false
  }

  /**
   * Touch event listener for the color slider canvas.
   * Drags the color marker and selects the color at the touch position.
   */
  onTouchMoveColorSlider = (event: TouchEvent) => {
    if (this.draggingColor && event.touches.length === 1) {
      event.preventDefault()
      this.selectColorOnSlider(event.touches[0].clientX)
    }
  }

  /**
   * Mouse event listener for the color saturation canvas.
   * Starts dragging the saturation marker and selects the color at the mouse position.
   */
  onMouseDownColorSaturation = (event: MouseEvent) => {
    event.preventDefault()
    this.draggingSaturation = true
    document.body.style.cursor = 'grab'
    this.selectColorSaturation(event.clientX, event.clientY)
  }

  /**
   * Mouse event listener for the color saturation canvas.
   * Stops dragging the saturation marker.
   */
  onMouseUpColorSaturation = (event: MouseEvent) => {
    event.preventDefault()
    document.body.style.removeProperty('cursor')
    this.draggingSaturation = false
  }

  /**
   * Mouse event listener for the color saturation canvas.
   * Drags the saturation marker and selects the color at the mouse position.
   */
  onMouseMoveColorSaturation = (event: MouseEvent) => {
    if (this.draggingSaturation && event.buttons === 1) {
      event.preventDefault()
      this.selectColorSaturation(event.clientX, event.clientY)
    }
  }

  /**
   * Mouse event listener for the color slider canvas.
   * Starts dragging the color marker and selects the color at the mouse position.
   */
  onMouseDownColorSlider = (event: MouseEvent) => {
    event.preventDefault()
    this.draggingColor = true
    document.body.style.cursor = 'grab'
    this.selectColorOnSlider(event.clientX)
  }

  /**
   * Mouse event listener for the color slider canvas.
   * Stops dragging the color marker.
   */
  onMouseUpColorSlider = (event: MouseEvent) => {
    event.preventDefault()
    document.body.style.removeProperty('cursor')
    this.draggingColor = false
  }

  /**
   * Mouse event listener for the color slider canvas.
   * Drags the color marker and selects the color at the mouse position.
   */
  onMouseMoveColorSlider = (event: MouseEvent) => {
    if (this.draggingColor && event.buttons === 1) {
      event.preventDefault()
      this.selectColorOnSlider(event.clientX)
    }
  }

  /**
   * Opens the color picker.
   * On desktop devices the color picker is opened as a mat-menu, next to the input field or button.
   * On mobile devices the color picker is opened as a dialog.
   */
  openColorPicker(event?: MouseEvent) {
    if (event) {
      event.preventDefault()
    }

    if (this.isValidHexCode(this.value)) {
      this.valueHex = this.value.substring(1).toUpperCase()
      this.valueRGB = this.hexToRgb(this.valueHex)
    }

    if (this.platformService.isMobile) {
      this.colorPickerOpen = true

      this.dialogRef = this.dialogService.open({
        dialogTitleText: 'Color Picker',
        dialogContentTemplateRef: this.colorPickerDialog,
        dialogActions: [
          {
            label: 'ws.forms.cancel',
            action: 'cancel',
            buttonType: 'secondary'
          },
          {
            label: 'ws.forms.save',
            action: 'save',
            buttonType: 'primary'
          }
        ],
        dialogOptions: {
          backdropDismiss: true
        }
      })

      this.dialogRef.afterClose$.subscribe((action: string) => {
        this.colorPickerOpen = false

        if (action === 'save') {
          this.emitValueChange()
        }
      })
    } else {
      this.colorPickerMenuTrigger?.openMenu()
    }

    this.calculateColorToDrawFromHex()
    if (this.valueHex !== '') {
      this.colorToDisplay = '#' + this.valueHex
    }

    // timeout is needed because otherwise the canvas are undefined
    setTimeout(() => {
      this.colorSaturationCanvas = document.getElementById(this.canvasId) as HTMLCanvasElement
      this.colorSaturationContext = this.colorSaturationCanvas.getContext('2d', { willReadFrequently: true })
      this.colorSliderCanvas = document.getElementById(this.sliderId) as HTMLCanvasElement
      this.sliderWidth = this.colorSliderCanvas.offsetWidth
      this.colorSliderContext = this.colorSliderCanvas.getContext('2d', { willReadFrequently: true })
      this.drawSaturationGradient()
      this.drawSlider()
      this.calculateSliderPositionFromHex()
      this.calculateSaturationMarkerPositionFromHex()
      this.addEventListeners()
    }, 100)

    // slider is not drawn on mobile devices unless i call it again in another timeout
    setTimeout(() => {
      this.drawSlider()
    }, 100)
  }

  /**
   * Intercepts the paste event on the input field to only allow pasting of valid hex color codes.
   * If the pasted text is invalid, a notification is shown.
   * @param onFormField {boolean} Whether the paste event was triggered directly on the form input field or in the color picker.
   */
  hexCodePasted(event: ClipboardEvent, onFormField?: boolean) {
    event.preventDefault()
    let pastedText = event.clipboardData?.getData('text').toUpperCase()

    if (!pastedText) {
      return
    }

    if (!pastedText.startsWith('#')) {
      pastedText = '#' + pastedText
    }

    if (this.isValidHexCode(pastedText)) {
      if (onFormField) {
        this.value = pastedText.toUpperCase()

        this.emitValueChange()
      } else {
        this.valueHex = pastedText.substring(1)
        this.colorToDisplay = pastedText
        this.valueRGB = this.hexToRgb(pastedText)
        this.calculateColorToDrawFromHex()
        this.drawSaturationGradient()
        this.calculateSliderPositionFromHex()
        this.calculateSaturationMarkerPositionFromHex()
      }
    } else {
      this.notificationService.error('ws.forms.colorPicker.invalidHexCode')
    }
  }

  /**
   * Closes the color picker on desktop devices.
   */
  closeColorPickerMenu() {
    if (!this.platformService.isMobile) {
      this.emitValueChange()
      this.colorPickerMenuTrigger?.closeMenu()
    }
  }

  keyUp(event: KeyboardEvent, inputField: 'form' | 'hex') {
    if (event.key !== 'Unidentified') {
      return
    }

    switch (inputField) {
      case 'form':
        if (this.value.length === 0) {
          return
        }

        if (this.value === '#') {
          return
        }

        if (this.value.length === 1 && this.value[0].match(/[0-9A-Fa-f]/)) {
          this.value = '#' + this.value
          return
        }

        if (!this.value[this.value.length - 1].match(/[0-9A-Fa-f]/)) {
          this.value = this.value.substring(0, this.value.length - 1)
        } else {
          this.value = this.value.toUpperCase()
        }
        return
      case 'hex':
        if (this.valueHex.length === 0) {
          return
        }

        if (this.valueHex.length === 1 && this.valueHex[0].match(/[0-9A-Fa-f]/)) {
          return
        }

        if (this.valueHex.length > 6) {
          this.valueHex = this.valueHex.substring(0, 6)
          return
        }

        if (!this.valueHex[this.valueHex.length - 1].match(/[0-9A-Fa-f]/)) {
          this.valueHex = this.valueHex.substring(0, this.valueHex.length - 1)
        } else {
          this.valueHex = this.valueHex.toUpperCase()
        }
        return
    }
  }

  /**
   * Intercepts the keydown event on the input field to only allow valid characters.
   * For input fields with a hex color code, only numbers and letters are allowed which are in the range of 0-9 and A-F.
   * For input fields with a rgb color code, only numbers are allowed.
   * On the form field it will automatically add a # if the user starts typing a hex color code.
   * On the rgb value fields it will convert the value to 255 if the user types a number greater than 255.
   * @param inputField {string} The input field the event was triggered on.
   */
  processInput(event: KeyboardEvent, inputField: 'rgb-r' | 'rgb-g' | 'rgb-b' | 'hex' | 'form') {
    if (event.altKey || event.ctrlKey) {
      return
    }

    if (event.key === 'Backspace') {
      switch (inputField) {
        case 'rgb-r':
          if (
            this.valueRGB.r.toString().length === 1 ||
            window.getSelection()?.toString() === this.valueRGB.r.toString()
          ) {
            event.preventDefault()
            this.valueRGB.r = 0
            this.rgbInputChanged()
          }
          break
        case 'rgb-g':
          if (
            this.valueRGB.g.toString().length === 1 ||
            window.getSelection()?.toString() === this.valueRGB.g.toString()
          ) {
            event.preventDefault()
            this.valueRGB.g = 0
            this.rgbInputChanged()
          }
          break
        case 'rgb-b':
          if (
            this.valueRGB.b.toString().length === 1 ||
            window.getSelection()?.toString() === this.valueRGB.b.toString()
          ) {
            event.preventDefault()
            this.valueRGB.b = 0
            this.rgbInputChanged()
          }
          break
      }
    }

    if (event.key === 'Delete') {
      switch (inputField) {
        case 'rgb-r':
          this.valueRGB.r = 0
          event.preventDefault()
          this.rgbInputChanged()
          break
        case 'rgb-g':
          this.valueRGB.g = 0
          event.preventDefault()
          this.rgbInputChanged()
          break
        case 'rgb-b':
          this.valueRGB.b = 0
          event.preventDefault()
          this.rgbInputChanged()
          break
      }
    }

    if (event.key.length === 1 && (event.key.match(/[a-zA-Z0-9]/) || event.key === '#')) {
      event.preventDefault()
    } else {
      return
    }

    if (event.key.length > 1) {
      return
    }

    switch (inputField) {
      case 'hex':
        if (window.getSelection()?.toString() !== '') {
          this.valueHex = ''
        }

        if (this.valueHex.length >= 6) {
          return
        } else {
          if (event.key.match(/[0-9A-Fa-f]/)) {
            this.valueHex += event.key.toUpperCase()
          }
        }

        if (this.valueHex.length === 6 && !this.platformService.isMobile) {
          this.value = '#' + this.valueHex
          this.colorToDisplay = '#' + this.valueHex
          this.valueRGB = this.hexToRgb(this.value)
          this.calculateColorToDrawFromHex()
          this.drawSaturationGradient()
          this.calculateSliderPositionFromHex()
          this.calculateSaturationMarkerPositionFromHex()

          this.emitValueChange()
          this.formControl.patchValue(this.value)
        } else if (this.valueHex.length === 6 && this.platformService.isMobile) {
          this.colorToDisplay = '#' + this.valueHex
          this.valueRGB = this.hexToRgb(this.valueHex)
          this.calculateColorToDrawFromHex()
          this.drawSaturationGradient()
          this.calculateSliderPositionFromHex()
          this.calculateSaturationMarkerPositionFromHex()
        }
        break
      case 'form':
        if (window.getSelection()?.toString() !== '') {
          this.value = ''
        }

        if (!this.rgb) {
          if (this.value.length >= 7) {
            return
          } else {
            if (event.key === '#' && this.value.length === 0) {
              this.value += event.key
            } else if (event.key.match(/[0-9A-Fa-f]/)) {
              if (this.value.length === 0) {
                this.value += '#'
              }
              this.value += event.key.toUpperCase()
            }
          }

          if (this.value.length === 7) {
            this.emitValueChange()
          }

          this.formControl.patchValue(this.value)
        }
        break
      case 'rgb-r':
        if (event.key.match(/[0-9]/)) {
          if (window.getSelection()?.toString() !== '') {
            this.valueRGB.r = parseInt(event.key)
          } else {
            if (parseInt(this.valueRGB.r.toString() + event.key) > 255) {
              this.valueRGB.r = 255
              this.rgbInputChanged()
            } else if (parseInt(this.valueRGB.r.toString() + event.key) < 0) {
              this.valueRGB.r = 0
              this.rgbInputChanged()
            } else {
              this.valueRGB.r = parseInt(this.valueRGB.r.toString() + event.key)
              this.rgbInputChanged()
            }
          }
        }
        break
      case 'rgb-g':
        if (event.key.match(/[0-9]/)) {
          if (window.getSelection()?.toString() !== '') {
            this.valueRGB.g = parseInt(event.key)
          } else {
            if (parseInt(this.valueRGB.g.toString() + event.key) > 255) {
              this.valueRGB.g = 255
              this.rgbInputChanged()
            } else if (parseInt(this.valueRGB.g.toString() + event.key) < 0) {
              this.valueRGB.g = 0
              this.rgbInputChanged()
            } else {
              this.valueRGB.g = parseInt(this.valueRGB.g.toString() + event.key)
              this.rgbInputChanged()
            }
          }
        }
        break
      case 'rgb-b':
        if (event.key.match(/[0-9]/)) {
          if (window.getSelection()?.toString() !== '') {
            this.valueRGB.b = parseInt(event.key)
          } else {
            if (parseInt(this.valueRGB.b.toString() + event.key) > 255) {
              this.valueRGB.b = 255
              this.rgbInputChanged()
            } else if (parseInt(this.valueRGB.b.toString() + event.key) < 0) {
              this.valueRGB.b = 0
              this.rgbInputChanged()
            } else {
              this.valueRGB.b = parseInt(this.valueRGB.b.toString() + event.key)
              this.rgbInputChanged()
            }
          }
        }
        break
    }
  }

  /**
   * Sets the value to the selected color in either hex or rgb.
   * Emits the colorSelected, colorSelectedHex, colorSelectedRgb and colorSelectedRgbString events.
   * Triggers the onFieldChange function.
   */
  emitValueChange() {
    if (this.valueHex.length === 6) {
      if (this.rgb) {
        this.value = 'rgb(' + this.valueRGB.r + ',' + this.valueRGB.g + ',' + this.valueRGB.b + ')'
      } else {
        if (this.isValidHexCode(this.valueHex)) {
          this.value = '#' + this.valueHex
        } else {
          this.notificationService.error('ws.forms.colorPicker.invalidHexCode')
        }
      }
    }

    this.colorSelected.emit(this.value)
    this.onFieldChange(this.value)
    this.formControl.patchValue(this.value)
  }

  /**
   * Calculates the color which is used to draw the color saturation canvas.
   * The color is calculated by converting the hex color code to hsv and setting the saturation and brightness to 1.
   */
  calculateColorToDrawFromHex() {
    const colorHsv = this.hexToHsv(this.valueHex)
    colorHsv.s = 1
    colorHsv.v = 1

    this.colorToDraw = this.hsvToHex(colorHsv)
  }

  /**
   * Calculates the color saturation and brightness from the position of the saturation marker and using the hsv color model.
   * Saturation and brightness can be between 0 and 1.
   */
  calculateColorSaturationFromMarkerPosition() {
    const saturation: number = this.saturationMarkerPosition.x / this.size
    const value: number = 1 - this.saturationMarkerPosition.y / this.size

    const hsv: { h: number; s: number; v: number } = {
      h: 1 - this.colorMarkerPosition / this.sliderWidth,
      s: saturation,
      v: value
    }
    this.valueHex = this.hsvToHex(hsv).toUpperCase()
    this.colorToDisplay = '#' + this.valueHex
    this.valueRGB = this.hexToRgb(this.valueHex)
  }

  /**
   * Calculates the color of the image data by reading the rgb values from the image data.
   * Image data normally is a single pixel.
   * The rgb values are then converted to hex format.
   * @param imageData {Uint8ClampedArray} The image data to read the rgb values from.
   */
  calculateHexAndRgbFromImageData(imageData: Uint8ClampedArray) {
    const rgb: string = 'rgb(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ')'
    this.valueRGB = { r: imageData[0], g: imageData[1], b: imageData[2] }
    this.valueHex = this.rgbToHex(rgb).toUpperCase()
    this.colorToDisplay = '#' + this.valueHex
  }

  /**
   * Selects the color in the color saturation canvas at the given position.
   * The colors are not read from the canvas but calculated from the position using the hsv color model.
   * @param clientX {number} The x position of the mouse or touch event.
   * @param clientY {number} The y position of the mouse or touch event.
   */
  selectColorSaturation(clientX: number, clientY: number) {
    const rect = this.colorSaturationCanvas.getBoundingClientRect()
    const markerPosition: { x: number; y: number } = { x: clientX - rect.left, y: clientY - rect.top }

    if (markerPosition.x <= 0) {
      markerPosition.x = 0
    } else if (markerPosition.x >= this.size) {
      markerPosition.x = this.size
    }

    if (markerPosition.y <= 0) {
      markerPosition.y = 0
    } else if (markerPosition.y >= this.size) {
      markerPosition.y = this.size
    }

    this.saturationMarkerPosition = markerPosition

    this.calculateColorSaturationFromMarkerPosition()

    if (!this.platformService.isMobile) {
      this.emitValueChange()
    }
  }

  /**
   * Selects the color in the color slider at the given position.
   * The color is read from the image data of the color slider canvas at the given position.
   * Y position is ignored because the colors in the color slider canvas are drawn from left to right and don't change on the y axis.
   * @param clientX {number} The x position of the mouse or touch event.
   */
  selectColorOnSlider(clientX: number) {
    const rect = this.colorSliderCanvas.getBoundingClientRect()
    let sliderPositionX: number = clientX - rect.left

    if (sliderPositionX <= 0) {
      sliderPositionX = 0
    } else if (sliderPositionX >= this.sliderWidth) {
      sliderPositionX = this.sliderWidth - 1
    }

    this.colorMarkerPosition = sliderPositionX

    const imageData = this.colorSliderContext?.getImageData(sliderPositionX, 0, 1, 1).data
    if (imageData) {
      this.calculateHexAndRgbFromImageData(imageData)
      this.calculateColorToDrawFromHex()
      this.drawSaturationGradient()
      this.calculateColorSaturationFromMarkerPosition()
    }

    if (!this.platformService.isMobile) {
      this.emitValueChange()
    }
  }

  /**
   * Changes the color to the selected preselect color.
   * The saturation canvas is redrawn with the new color.
   * The color slider is moved to the correct position.
   * The saturation marker is moved to the correct position.
   * @param color {string} The hex color code of the preselect color.
   */
  selectPreselectColor(color: string) {
    this.valueHex = color.toUpperCase()
    this.colorToDisplay = '#' + this.valueHex
    this.valueRGB = this.hexToRgb(color)
    this.calculateColorToDrawFromHex()
    this.drawSaturationGradient()
    this.calculateSliderPositionFromHex()
    this.calculateSaturationMarkerPositionFromHex()

    if (!this.platformService.isMobile) {
      this.emitValueChange()
      this.formControl.patchValue(this.value)
    }
  }

  /**
   * Keeps the rgb values in the correct range of 0-255.
   * Converts the rgb values to hex format.
   * Calculates the color to draw in the color saturation canvas.
   * Draws the color saturation canvas.
   * Calculates the position of the color marker in the color slider.
   * Calculates the position of the saturation marker in the color saturation canvas.
   */
  rgbInputChanged() {
    if (this.valueRGB.r > 255) {
      this.valueRGB.r = 255
    } else if (this.valueRGB.r < 0 && !this.valueRGB.r) {
      this.valueRGB.r = 0
    }

    if (this.valueRGB.g > 255) {
      this.valueRGB.g = 255
    } else if (this.valueRGB.g < 0) {
      this.valueRGB.g = 0
    }

    if (this.valueRGB.b > 255) {
      this.valueRGB.b = 255
    } else if (this.valueRGB.b < 0) {
      this.valueRGB.b = 0
    }

    this.valueHex = this.rgbToHex(
      'rgb(' + this.valueRGB.r + ',' + this.valueRGB.g + ',' + this.valueRGB.b + ')'
    ).toUpperCase()
    this.colorToDisplay = '#' + this.valueHex
    this.calculateColorToDrawFromHex()
    this.drawSaturationGradient()
    this.calculateSliderPositionFromHex()
    this.calculateSaturationMarkerPositionFromHex()

    if (!this.platformService.isMobile) {
      this.emitValueChange()
    }
  }

  /**
   * Converts the hex value to rgb format.
   * Calculates the color to draw in the color saturation canvas.
   * Draws the color saturation canvas.
   * Calculates the position of the color marker in the color slider.
   * Calculates the position of the saturation marker in the color saturation canvas.
   */
  hexInputChanged() {
    if (this.valueHex.length === 6 && this.isValidHexCode(this.valueHex)) {
      this.valueHex = this.valueHex.toUpperCase()
      this.colorToDisplay = '#' + this.valueHex
      this.valueRGB = this.hexToRgb(this.valueHex)
      this.calculateColorToDrawFromHex()
      this.drawSaturationGradient()
      this.calculateSliderPositionFromHex()
      this.calculateSaturationMarkerPositionFromHex()

      if (!this.platformService.isMobile) {
        this.emitValueChange()
      }
    }
  }

  /**
   * Calculates the position of the color marker in the color slider from the selected color.
   */
  calculateSliderPositionFromHex() {
    const hsv: { h: number; s: number; v: number } = this.rgbToHsv(this.valueRGB)
    this.colorMarkerPosition = Math.round((1 - hsv.h) * this.sliderWidth)
  }

  /**
   * Calculates the position of the saturation marker in the color saturation canvas from the selected color.
   */
  calculateSaturationMarkerPositionFromHex() {
    const hsv: { h: number; s: number; v: number } = this.rgbToHsv(this.valueRGB)
    this.saturationMarkerPosition = { x: Math.round(hsv.s * this.size), y: Math.round(this.size - hsv.v * this.size) }
  }

  /**
   * Checks whether the given hex color code is valid.
   * A valid hex color code starts with a # and is followed by 6 characters in the range of 0-9 and A-F.
   * @param hex {string} The hex color code to check.
   */
  isValidHexCode(hex: string): boolean {
    if (!hex.startsWith('#')) {
      hex = '#' + hex
    }

    return /(^#[0-9A-F]{6}$)/i.test(hex)
  }

  /**
   * Converts a hex color code to rgb format.
   * @param hex {string} The hex color code to convert.
   */
  hexToRgb(hex: string): { r: number; g: number; b: number } {
    if (!hex.startsWith('#')) {
      hex = '#' + hex
    }

    const bigint: number = parseInt(hex.substring(1), 16)
    const r: number = (bigint >> 16) & 255
    const g: number = (bigint >> 8) & 255
    const b: number = bigint & 255
    return { r, g, b }
  }

  /**
   * Converts a rgb color string to hex format.
   * @param rgb {string} The rgb color code to convert.
   */
  rgbToHex(rgb: string): string {
    const parts: string[] = rgb.substring(rgb.indexOf('(')).split(',')
    const r: number = parseInt(this.trim(parts[0].substring(1)), 10)
    const g: number = parseInt(this.trim(parts[1]), 10)
    const b: number = parseInt(this.trim(parts[2]), 10)
    return this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
  }

  /**
   * Converts a hsv color to hex format.
   * @param hsv {object} The hsv color to convert.
   */
  hsvToHex(hsv: { h: number; s: number; v: number }): string {
    const rgb: { r: number; g: number; b: number } = this.hsvToRgb(hsv)
    return this.rgbToHex('rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')')
  }

  /**
   * Converts a rgb color to hsv format.
   * @param rgb {object} The rgb color to convert.
   */
  rgbToHsv(rgb: { r: number; g: number; b: number }): { h: number; s: number; v: number } {
    const r: number = rgb.r / 255
    const g: number = rgb.g / 255
    const b: number = rgb.b / 255

    const max: number = Math.max(r, g, b)
    const min: number = Math.min(r, g, b)
    const v: number = max

    let h = 0
    let s = 0
    const d: number = max - min
    s = max === 0 ? 0 : d / max

    if (max === min) {
      h = 0
    } else {
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    return { h, s, v }
  }

  /**
   * Converts a hex color code to hsv format.
   * @param hex {string} The hex color code to convert.
   */
  hexToHsv(hex: string): { h: number; s: number; v: number } {
    if (!hex.startsWith('#')) {
      hex = '#' + hex
    }

    const bigint: number = parseInt(hex.substring(1), 16)
    const r: number = (bigint >> 16) & 255
    const g: number = (bigint >> 8) & 255
    const b: number = bigint & 255

    const hsv: { h: number; s: number; v: number } = this.rgbToHsv({ r, g, b })
    return hsv
  }

  /**
   * Converts a hsv color to rgb format.
   * @param hsv {object} The hsv color to convert.
   */
  hsvToRgb(hsv: { h: number; s: number; v: number }): { r: number; g: number; b: number } {
    let r = 0
    let g = 0
    let b = 0
    const i: number = Math.floor(hsv.h * 6)
    const f: number = hsv.h * 6 - i
    const p: number = hsv.v * (1 - hsv.s)
    const q: number = hsv.v * (1 - f * hsv.s)
    const t: number = hsv.v * (1 - (1 - f) * hsv.s)
    switch (i % 6) {
      case 0:
        ;(r = hsv.v), (g = t), (b = p)
        break
      case 1:
        ;(r = q), (g = hsv.v), (b = p)
        break
      case 2:
        ;(r = p), (g = hsv.v), (b = t)
        break
      case 3:
        ;(r = p), (g = q), (b = hsv.v)
        break
      case 4:
        ;(r = t), (g = p), (b = hsv.v)
        break
      case 5:
        ;(r = hsv.v), (g = p), (b = q)
        break
    }
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    }
  }

  trim(str: string): string {
    return str.replace(/^\s+|\s+$/gm, '')
  }

  /**
   * Converts the given number to hex format.
   */
  componentToHex(c: number): string {
    const hex: string = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  clearValue(event: MouseEvent) {
    event.stopPropagation()
    this.clearInputValue()
  }

  /**
   * Listens to the mousemove event on the document to enable dragging of the markers when the mouse is moved outside the color picker during dragging.
   */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.draggingSaturation && event.buttons === 1) {
      this.selectColorSaturation(event.clientX, event.clientY)
    } else if (this.draggingColor && event.buttons === 1) {
      this.selectColorOnSlider(event.clientX)
    }
  }

  /**
   * Listens to the mouseup event on the document to disable dragging of the markers when the mouse is released outside the color picker during dragging.
   */
  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    this.draggingSaturation = false
    this.draggingColor = false
    document.body.style.removeProperty('cursor')
  }
}
