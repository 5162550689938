@if (ngControl) {
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <div>
    @for (option of mappedValueOptions; track $index) {
      <mat-chip class="mr-2 mt-2">
        {{ option.label | translate }}
        <button matChipRemove (click)="removeFromSelection(option.value)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip>
    }
  </div>
  <ws-form-select
    [filter]="filter"
    [multiple]="true"
    #selectElement
    [compareWithFunction]="compareWithFunction"
    (valueChange)="updateValueOfFormElement()"
    matOptionClass="chips-select_select_option"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [options]="options"
  ></ws-form-select>
}
