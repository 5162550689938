import { Injectable } from '@angular/core'
import { NativeDateAdapter } from '@angular/material/core'

@Injectable()
export class WsDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    let indexFirstSeparator = 0
    let indexSecondSeparator = 0

    if (typeof value === 'string') {
      //iterate over the input to find the first and second separator
      for (const [i, character] of Object.entries(value)) {
        if (isNaN(Number(character))) {
          if (indexFirstSeparator === 0) {
            indexFirstSeparator = Number(i)
          } else if (indexSecondSeparator === 0) {
            indexSecondSeparator = Number(i)
          }
        }
      }

      //only evaluate string if there are two separators
      if (indexFirstSeparator !== 0 || indexSecondSeparator !== 0) {
        let yearString = ''
        let monthString = ''
        let dateString = ''

        //when first separator has index 4, the format is yyyy-mm-dd else its dd-mm-yyyy
        if (indexFirstSeparator === 4) {
          yearString = value.substring(0, indexFirstSeparator)
          monthString = value.substring(indexFirstSeparator + 1, indexSecondSeparator)
          dateString = value.substring(indexSecondSeparator + 1, value.length)
        } else {
          yearString = value.substring(indexSecondSeparator + 1, value.length)
          monthString = value.substring(indexFirstSeparator + 1, indexSecondSeparator)
          dateString = value.substring(0, indexFirstSeparator)
        }

        if (
          yearString.length === 4 &&
          (monthString.length === 1 || monthString.length === 2) &&
          (dateString.length === 1 || dateString.length === 2)
        ) {
          if (!isNaN(Number(yearString)) && !isNaN(Number(monthString)) && !isNaN(Number(dateString))) {
            const year = Number(yearString)
            const month = Number(monthString) - 1
            const date = Number(dateString)

            if (month >= 0 && month < 12 && date > 0 && date < 32) {
              return new Date(year, month, date)
            }
          }
        }
      } else {
        //if there are no separators, the format is ddmmyyyy
        if (value.length === 8) {
          const yearString = value.substring(4, 8)
          const monthString = value.substring(2, 4)
          const dateString = value.substring(0, 2)

          if (!isNaN(Number(yearString)) && !isNaN(Number(monthString)) && !isNaN(Number(dateString))) {
            const year = Number(yearString)
            const month = Number(monthString) - 1
            const date = Number(dateString)

            if (month >= 0 && month < 12 && date > 0 && date < 32) {
              return new Date(year, month, date)
            }
          }
        }
      }
      if (!value) {
        return null
      }
    }

    return this.invalid()
  }
}

export const WS_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
}
