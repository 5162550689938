import { Component, Input, ViewChild } from '@angular/core'
import { Editor, MenuComponent, Toolbar, TOOLBAR_MINIMAL } from 'ngx-editor'

@Component({
  selector: 'ws-form-text-editor-menu',
  templateUrl: './custom-editor-menu.component.html',
  styleUrls: ['./custom-editor-menu.component.scss']
})
export class CustomEditorMenuComponent {
  @Input() showEmojiPicker = true
  @Input() showTextColorPicker = true
  @Input() editor: Editor | undefined
  @Input() toolbar: Toolbar = TOOLBAR_MINIMAL

  colorSelected = ''

  @ViewChild('editorMenu', { static: false }) editorMenu: MenuComponent | undefined

  applyTextColor() {
    this.editor?.commands.textColor(this.colorSelected).exec()
  }

  insertEmoji(emoji: string) {
    this.editor?.commands.insertText(emoji).exec()
  }
}
