import defaultStyles from './spacerStyles.json'
import { Brick } from './brick'
import { StyleAttributes, TestData } from './brick.types'

export class Spacer extends Brick {
  constructor(styles: StyleAttributes = {}) {
    super('Spacer', '', styles, 'mj-spacer')
    this.addDefaultStyles(defaultStyles)
  }

  override getStyles(testData: TestData = {}): any {
    return {
      div: {
        height: this.styles['height'],
        'line-height': this.styles['height']
      }
    }
  }
}
