<div>
  <form [formGroup]="modelForm" (ngSubmit)="onFormSubmit()">
    <div class="grid gap-4 grid-cols-12">
      <!--      <div-->
      <!--        class="col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12"-->
      <!--      ></div>-->
      @for (field of fields; track $index) {
        <div [class]="field.colClasses" [ngSwitch]="field.tag">
          @switch (field.tag) {
            @case ('input') {
              <ws-form-input
                [formControlName]="field.name"
                [type]="field.type"
                [min]="field.min"
                [max]="field.max"
                [step]="field.step"
                [hint]="field.hint"
                [icon]="field.icon"
                [options]="field.options"
                [label]="field.label"
              ></ws-form-input>
            }
            @case ('textarea') {
              <ws-form-textarea
                [formControlName]="field.name"
                [hint]="field.hint"
                [label]="field.label"
              ></ws-form-textarea>
            }
            @case ('select') {
              <ws-form-select
                [formControlName]="field.name"
                [label]="field.label"
                [options]="field.options"
                [filter]="field.filter"
                [hint]="field.hint"
              ></ws-form-select>
            }
            @case ('toggle') {
              <ws-form-toggle [formControlName]="field.name" [label]="field.label"></ws-form-toggle>
            }
            @case ('checkbox') {
              <ws-form-checkbox [formControlName]="field.name" [label]="field.label"></ws-form-checkbox>
            }
            @case ('slider') {
              <ws-form-slider
                [formControlName]="field.name"
                [label]="field.label"
                [min]="field.min"
                [max]="field.max"
                [step]="field.step"
              ></ws-form-slider>
            }
            @case ('buttons') {
              <ws-form-button-toggle
                [formControlName]="field.name"
                [label]="field.label"
                [options]="field.options"
              ></ws-form-button-toggle>
            }
            @case ('datepicker') {
              <ws-form-datepicker [formControlName]="field.name" [label]="field.label" [locale]="field.localization">
              </ws-form-datepicker>
            }
            @case ('colorpicker') {
              <ws-form-color-picker [formControlName]="field.name" [label]="field.label"> </ws-form-color-picker>
            }
            @case ('texteditor') {
              <ws-form-text-editor [formControlName]="field.name"></ws-form-text-editor>
            }
          }
        </div>
      }
    </div>

    <div class="w-full flex justify-between">
      @if (enableDelete) {
        <button
          color="warn"
          [disabled]="saving || deleting"
          mat-button
          class="uppercase"
          type="button"
          (click)="onFormDelete()"
        >
          {{ 'ws.forms.delete' | translate }}
        </button>
      }

      <div class="flex flex-col items-center lg:flex-row flex-wrap">
        <button color="primary" [disabled]="saving || deleting" mat-button type="button" (click)="onFormCancel()">
          {{ 'ws.forms.cancel' | translate }}
        </button>
        <button color="primary" [disabled]="saving || deleting" class="uppercase" mat-flat-button type="submit">
          {{ 'ws.forms.save' | translate }}
        </button>
      </div>
    </div>
  </form>
  <!--  <pre>{{ modelForm.value | json }}</pre>-->
</div>
