import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { WsFormField } from '../../../types/forms/ws-form-field'

@Component({
  selector: 'ws-form-basic-model',
  templateUrl: './basic-model.component.html',
  styleUrls: ['./basic-model.component.scss']
})
export class BasicModelComponent implements OnInit, OnChanges {
  @Input() model: any
  @Input() saving = false
  @Input() enableDelete = true
  @Input() deleting = false

  @Input() fields: WsFormField[] = []

  modelForm: FormGroup = new FormGroup({})

  @Output() save: EventEmitter<object> = new EventEmitter<object>()
  @Output() delete: EventEmitter<object> = new EventEmitter<object>()
  @Output() cancel: EventEmitter<any> = new EventEmitter()

  ngOnInit() {
    this.fields.forEach((field: WsFormField) => {
      this.modelForm.addControl(
        field.name,
        new FormControl(this.model[field.name] || field.defaultValue, field.validators)
      )
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.modelForm.patchValue(changes['model'].currentValue)
    }
  }

  onFormSubmit() {
    this.modelForm.markAllAsTouched()
    if (this.modelForm.invalid) return
    this.save.emit(this.modelForm.value)
  }
  onFormCancel() {
    this.cancel.emit()
  }
  onFormDelete() {
    this.delete.emit(this.modelForm.value)
  }
}
